import { DragEvent, FC, useState } from 'react';

import { PriceListFormQuestions } from 'pages/PriceLists/components/PriceListFormQuestions';
import { ReferenceCodeColumn } from 'pages/PriceLists/components/ReferenceCodeColumn';
import { DeleteControlColumn } from 'pages/PriceLists/components/DeleteControlColumn';
import { AvailableForColumn } from 'pages/PriceLists/components/AvailableForColumn';
import { ToggleArrowColumn } from 'pages/PriceLists/components/ToggleArrowColumn';
import { RetailValueColumn } from 'pages/PriceLists/components/RetailValueColumn';
import { NameColumn } from 'pages/PriceLists/components/NameColumn';
import {
	CreatePriceListFormQuestion,
	DeletePriceListFormQuestion,
	SetPriceListFormQuestionsUI,
	UpdatePriceListFormQuestion,
	ReorderPriceListFormQuestions,
} from 'pages/PriceLists/types/studio/priceListFormQuestionUI';
import {
	IAlaCarteUI,
	IDeleteAlaCarte,
	IUpdateAlaCarte,
	IClearAlaCarteErrors,
	IUploadAlaCarteImage,
} from 'pages/PriceLists/types/studio/priceListAlaCarteUI';

import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';
import { IChangeSequencePayload, useDragAndDrop } from 'hooks/useDragAndDrop';

import { AlaCarteContent } from './components/AlaCarteContent';

type DeleteAlaCarteHandler = (payload: IDeleteAlaCarte) => Promise<void>;
type ChangeAlaCarteSequence = (payload: IChangeSequencePayload) => void;

export type ClearAlaCarteErrorHandler = (payload: IClearAlaCarteErrors) => void;
export type UpdateAlaCarteHandler = (payload: IUpdateAlaCarte) => Promise<void>;
export type DeleteAlaCarteImage = (alaCarteKey?: number) => Promise<void>;
export type UploadAlaCarteImage = (
	payload: IUploadAlaCarteImage
) => Promise<void>;

interface IPriceListAlaCartesProps {
	alaCarteUI: IAlaCarteUI;
	previewImageFile: File | null;
	deleteAlaCarte: DeleteAlaCarteHandler;
	updateAlaCarte: UpdateAlaCarteHandler;
	deleteAlaCarteImage: DeleteAlaCarteImage;
	uploadAlaCarteImage: UploadAlaCarteImage;
	clearAlaCarteError: ClearAlaCarteErrorHandler;
	changeAlaCartesSequence: ChangeAlaCarteSequence;
	setFormQuestionsUI: SetPriceListFormQuestionsUI;
	createFormQuestion: CreatePriceListFormQuestion;
	updateFormQuestion: UpdatePriceListFormQuestion;
	deleteFormQuestion: DeletePriceListFormQuestion;
	reorderFormQuestions: ReorderPriceListFormQuestions;
}

export const PriceListAlaCarte: FC<IPriceListAlaCartesProps> = ({
	alaCarteUI,
	deleteAlaCarte,
	updateAlaCarte,
	previewImageFile,
	clearAlaCarteError,
	setFormQuestionsUI,
	createFormQuestion,
	updateFormQuestion,
	deleteFormQuestion,
	deleteAlaCarteImage,
	uploadAlaCarteImage,
	reorderFormQuestions,
	changeAlaCartesSequence,
}) => {
	const [showContent, setShowContent] = useState(false);

	const toggleShowContent = () => {
		setShowContent(!showContent);
	};

	const {
		name,
		errors,
		sequence,
		retailPrice,
		availableFor,
		referenceCode,
		priceListStudioFulfilledALaCarteFormQuestions,
		priceListStudioFulfilledALaCarteKey: alaCarteKey,
	} = alaCarteUI;

	const {
		name: nameError,
		retailPrice: retailPriceError,
		referenceCode: referenceCodeError,
	} = errors;

	const alaCarteKeyString = alaCarteKey?.toString() || '';

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleDragStart,
		handleMouseDown,
	} = useDragAndDrop([], changeAlaCartesSequence);

	const onDragStart = (e: DragEvent<HTMLTableSectionElement>) => {
		if (!alaCarteKeyString) return;

		handleDragStart(e, alaCarteKeyString);
	};

	const handleDelete = () => {
		void deleteAlaCarte({
			sequence,
			alaCarteKey,
		});
	};

	const updateName = (value: string, validationMessage: string) => {
		void updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			fieldKey: 'name',
			validationMessage,
		});
	};

	const clearNameError = () => {
		if (!nameError) return;

		clearAlaCarteError({
			sequence,
			fieldKey: 'name',
		});
	};

	const updatePriceValue = (value: number, validationMessage: string) => {
		void updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			validationMessage,
			fieldKey: 'retailPrice',
		});
	};

	const clearPriceValueError = () => {
		if (!retailPriceError) return;

		clearAlaCarteError({
			sequence,
			fieldKey: 'retailPrice',
		});
	};

	const updateReferenceCode = (value: string, validationMessage: string) => {
		void updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			validationMessage,
			fieldKey: 'referenceCode',
		});
	};

	const clearReferenceCodeError = () => {
		if (!referenceCodeError) return;

		clearAlaCarteError({
			sequence,
			fieldKey: 'referenceCode',
		});
	};

	const updateAvailableFor = async (value: PoseAvailableFor) => {
		await updateAlaCarte({
			value,
			sequence,
			alaCarteKey,
			fieldKey: 'availableFor',
		});
	};

	const unsavedAlaCarte = !alaCarteKey;
	const showContentBurger = showContent && !unsavedAlaCarte;
	const showBurgerBtn = !showContent && !unsavedAlaCarte;

	const uniqPreviewImageFile = unsavedAlaCarte ? previewImageFile : null;

	return (
		<tbody
			onDrop={handleDrop}
			id={alaCarteKeyString}
			draggable={draggable}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			className="price-option-tbody"
		>
			<tr>
				<ToggleArrowColumn
					showContent={showContent}
					showBurgerBtn={showBurgerBtn}
					handleMouseDown={handleMouseDown}
					handleToggleShowContent={toggleShowContent}
				/>
				<NameColumn
					name={name}
					nameError={nameError}
					updateName={updateName}
					clearError={clearNameError}
				/>
				<RetailValueColumn
					retailValue={retailPrice}
					clearError={clearPriceValueError}
					retailValueError={retailPriceError}
					updateRetailValue={updatePriceValue}
				/>
				<ReferenceCodeColumn
					referenceCode={referenceCode}
					clearError={clearReferenceCodeError}
					referenceCodeError={referenceCodeError}
					updateReferenceCode={updateReferenceCode}
				/>
				<AvailableForColumn
					availableFor={availableFor}
					updateAvailableFor={updateAvailableFor}
				/>
				<DeleteControlColumn showPriceDeleteBtn handleDelete={handleDelete} />
			</tr>
			{showContent && (
				<>
					<AlaCarteContent
						alaCarteUI={alaCarteUI}
						showBurger={showContentBurger}
						updateAlaCarte={updateAlaCarte}
						handleMouseDown={handleMouseDown}
						clearAlaCarteError={clearAlaCarteError}
						previewImageFile={uniqPreviewImageFile}
						uploadAlaCarteImage={uploadAlaCarteImage}
						deleteAlaCarteImage={deleteAlaCarteImage}
					/>
					<PriceListFormQuestions
						entityKey={alaCarteKey}
						deleteFormQuestion={deleteFormQuestion}
						updateFormQuestion={updateFormQuestion}
						setFormQuestionsUI={setFormQuestionsUI}
						createFormQuestion={createFormQuestion}
						reorderFormQuestions={reorderFormQuestions}
						formQuestionsUI={priceListStudioFulfilledALaCarteFormQuestions}
					/>
				</>
			)}
		</tbody>
	);
};
