import { useCallback, useState } from 'react';

import { IBaseSortReqParams } from 'api/models/requests/general/baseSortReqParams';
import { OrderDirection } from 'api/models/requests/general/orderDirection';

export interface SortParamsResult<T> extends IBaseSortReqParams<T> {
	setOrderBy?: (orderBy: T) => void;
	setSortParams: (sortLabel: T) => void;
	setOrderDirection?: (orderDirection: OrderDirection) => void;
}

interface ISortParamsProps<T extends string> {
	defaultOrderBy: T;
	sortOptions?: Record<T, OrderDirection>;
}

export const useSortParams = <T extends string>({
	sortOptions,
	defaultOrderBy,
}: ISortParamsProps<T>): SortParamsResult<T> => {
	const [orderDirection, setOrderDirection] = useState(OrderDirection.ASC);
	const [orderBy, setOrderBy] = useState<T>(defaultOrderBy);

	const setSortParams = useCallback(
		(sortLabel: T) => {
			const updatedOrderDirection =
				orderDirection === OrderDirection.ASC
					? OrderDirection.DESC
					: OrderDirection.ASC;

			if (sortOptions) {
				const defaultOrderDirection = sortOptions[sortLabel];

				if (sortLabel === orderBy) {
					return setOrderDirection(updatedOrderDirection);
				}

				setOrderBy(sortLabel);
				setOrderDirection(defaultOrderDirection);

				return;
			}

			if (sortLabel === orderBy) {
				return setOrderDirection(updatedOrderDirection);
			}

			setOrderBy(sortLabel);
			setOrderDirection(OrderDirection.ASC);
		},
		[orderBy, orderDirection]
	);

	return {
		orderBy,
		setOrderBy,
		setSortParams,
		orderDirection,
		setOrderDirection,
	};
};
