import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useEffect } from 'react';

import { BillingEntityTypes } from 'api/models/responses/studioBilling/billingEntityTypes';
import { IBillingInfoBody } from 'api/models/requests/studioBilling/billingInfo';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import StudioBillingService from 'api/services/StudioBillingService';

import { w9InfoValidationSchema } from 'components/W9InfoForm/validation';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { W9InfoForm } from 'components/W9InfoForm';
import { Modal } from 'components/Modal';
import {
	ITinField,
	IPartialSsnField,
	IPartialEinField,
	IPartialPhoneField,
	IBillingInfoFormValues,
} from 'components/W9InfoForm/types';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useModalControls } from 'hooks/useModalControls';

import { splitPhoneNumber } from 'utils/validations/general/splitPhoneNumber';
import {
	setStudioBilling,
	billingInfoSelector,
	getBillingInfoAsync,
} from 'store/studioBilling';

import { W9InfoList } from './components/W9InfoList';

export const WInformation = () => {
	const billingInfo = useAppSelector(billingInfoSelector);

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const {
		modalRef: updateWInfoRef,
		showModal: showWInfoModal,
		hideModal: hideWInfoModal,
	} = useModalControls();

	const billingPhoneInitial: IPartialPhoneField = {
		firstPartPhone: '',
		secondPartPhone: '',
		thirdPartPhone: '',
	};

	const ssnInitial: IPartialSsnField = {
		firstPartSsn: '',
		secondPartSsn: '',
		thirdPartSsn: '',
	};

	const einInitial: IPartialEinField = {
		firstPartEin: '',
		secondPartEin: '',
	};

	const tinInitial: ITinField = {
		ssn: ssnInitial,
		ein: einInitial,
	};

	const initialValues: IBillingInfoFormValues = {
		billingFirstName: billingInfo?.billingFirstName || '',
		billingLastName: billingInfo?.billingLastName || '',
		billingBusinessName: billingInfo?.billingBusinessName || '',
		billingEntityType:
			billingInfo?.billingEntityType ||
			BillingEntityTypes.IndividualSoleProprietor,
		billingW9Initials: '',
		billingPhone: billingInfo?.billingPhone
			? splitPhoneNumber(billingInfo.billingPhone)
			: billingPhoneInitial,
		billingPhoneExt: billingInfo?.billingPhoneExt || '',
		billingFax: billingInfo?.billingFax || '',
		billingStreet: billingInfo?.billingStreet || '',
		billingCity: billingInfo?.billingCity || '',
		billingState: billingInfo?.billingState || '',
		billingPostalCode: billingInfo?.billingPostalCode || '',
		billingEmail: billingInfo?.billingEmail || '',
		tin: tinInitial,
	};

	const onSubmit = async (
		values: IBillingInfoFormValues,
		{ setErrors }: FormikHelpers<IBillingInfoFormValues>
	) => {
		const { tin, billingPhone, ...w9BillingRest } = values;

		const { firstPartPhone, secondPartPhone, thirdPartPhone } = billingPhone;
		const { ssn, ein } = tin;

		const fullBillingPhone = `${firstPartPhone}${secondPartPhone}${thirdPartPhone}`;
		const fullSsn = `${ssn.firstPartSsn}${ssn.secondPartSsn}${ssn.thirdPartSsn}`;
		const fullEin = `${ein.firstPartEin}${ein.secondPartEin}`;

		const w9BillingBody: IBillingInfoBody = {
			...w9BillingRest,
			ssn: fullSsn,
			ein: fullEin,
			billingPhone: fullBillingPhone,
		};

		try {
			const data = await StudioBillingService.updateBillingInfo(w9BillingBody);

			dispatch(setStudioBilling(data));

			hideWInfoModal();
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedErrorMessages = Object.entries(errors).reduce<
				FormikErrors<IBillingInfoFormValues>
			>((acc, [key, messages]) => {
				const splittedKey = key.split('.');

				const parsedKey = splittedKey[splittedKey.length - 1];

				const parsedMessage = messages[0] || '';

				if (parsedKey === 'billingPhone') {
					acc.billingPhone = {};
					acc.billingPhone.firstPartPhone = parsedMessage;

					return acc;
				}

				if (parsedKey === 'ssn') {
					acc.tin = {};
					acc.tin.ssn = {};
					acc.tin.ssn.firstPartSsn = parsedMessage;

					return acc;
				}

				if (parsedKey === 'ein') {
					acc.tin = {};
					acc.tin.ssn = {};
					acc.tin.ssn.firstPartSsn = parsedMessage;

					return acc;
				}

				return {
					...acc,
					[parsedKey]: parsedMessage,
				};
			}, {});

			setErrors({
				...updatedErrorMessages,
			});
		}
	};

	useEffect(() => {
		void dispatch(getBillingInfoAsync());
	}, []);

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="W9 Information"
				handleToggle={handleToggle}
				className="accing-toggle-header"
				header={
					<Button
						value="Update W9 Info"
						handleClick={showWInfoModal}
						className="btn-secondary accing-btn"
					/>
				}
			>
				<div className="accing-wrapper">
					<div className="accing-list-wrapper">
						<W9InfoList />
					</div>
				</div>
			</ToggleSection>
			<Modal
				ref={updateWInfoRef}
				className="accing-w9-modal"
				title="Update Account W-9 Details"
			>
				<Formik
					enableReinitialize
					onSubmit={onSubmit}
					initialValues={initialValues}
					validationSchema={w9InfoValidationSchema}
				>
					{({ handleSubmit }) => (
						<W9InfoForm>
							<div className="accing-info-btns">
								<Button
									className="btn-primary"
									value="Update W9 On File"
									handleClick={handleSubmit}
								/>
								<Button
									value="Back"
									className="btn-secondary"
									handleClick={hideWInfoModal}
								/>
							</div>
						</W9InfoForm>
					)}
				</Formik>
			</Modal>
		</div>
	);
};
