import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ISendVerificationCodeProps, ISendVerificationCodeValues } from '..';

type SendVerificationCodeFormProps = Pick<
	ISendVerificationCodeProps,
	'hideSendVerificationCodeModal'
>;

export const SendVerificationCodeForm: FC<SendVerificationCodeFormProps> = ({
	hideSendVerificationCodeModal,
}) => {
	const { values, errors, touched, handleChange, handleSubmit } =
		useFormikContext<ISendVerificationCodeValues>();

	return (
		<div className="modal-body accing-modal">
			<div className="acc-email-modal">
				<InputGrid
					id="email"
					required={false}
					label="New Email"
					value={values.email}
					error={errors.email}
					touched={touched.email}
					placeholder="Email Address"
					handleChange={handleChange}
				/>
				<InputGrid
					required={false}
					id="confirmEmail"
					label="Confirm New Email"
					placeholder="Email Address"
					error={errors.confirmEmail}
					handleChange={handleChange}
					value={values.confirmEmail}
					touched={touched.confirmEmail}
				/>
			</div>
			<div className="btn-container-decrease">
				<Button
					className="btn-primary"
					handleClick={handleSubmit}
					value="Send Verification Code"
				/>
				<Button
					value="Back"
					className="btn-secondary"
					handleClick={hideSendVerificationCodeModal}
				/>
			</div>
		</div>
	);
};
