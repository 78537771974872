import { UserRoles } from 'api/models/requests/account/userRoles';

type UserRolesAccess = Record<UserRoles, UserRoles[]>;

const administratorAccess = [UserRoles.Administrator, UserRoles.Assistant];
const ownerAccess = [
	UserRoles.Owner,
	UserRoles.Administrator,
	UserRoles.Assistant,
];

export const userRolesAccess: UserRolesAccess = {
	[UserRoles.Assistant]: [],
	[UserRoles.Owner]: ownerAccess,
	[UserRoles.Administrator]: administratorAccess,
	[UserRoles.HHAdministrator]: administratorAccess,
};
