import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FC, RefObject, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, lazy } from 'yup';

import { salesNotAvailableParam } from 'constants/priceLists/selectOptions/salesNotAvailableSelectOption';
import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { projectJourneyInfo } from 'constants/projects/projectJourneyInfo';
import { ROUTES } from 'constants/ROUTES';

import { IPriceListSale } from 'api/models/responses/priceLists/priceListSale';
import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { ProjectTypes } from 'api/models/requests/projects/projectTypes';
import ProjectsService from 'api/services/ProjectsService';
import {
	IPriceListSaleParams,
	ICreateBlankProjectBody,
} from 'api/models/requests/projects/createBlankProject';

import { getProcessedErrors } from 'utils/errors/getProcessedErrors';
import { prepareDate } from 'utils/dates/setCurrentTimeToDate';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { useOrganizationKeyExist } from 'pages/Projects/hooks/useOrganizationKeyExist';
import {
	saleTypesSelector,
	setCreatedProject,
	priceListsSale1Selector,
	priceListsSale2Selector,
	priceListsSale3Selector,
	organizationKeySelector,
	subjectFormQuestionsParamsSelector,
	isValidSubjectFormQuestionsSelector,
} from 'store/projects';

import { BlankProjectStep1 } from './BlankProjectStep1';
import { BlankProjectStep2 } from './BlankProjectStep2';
import { Steps } from '../../types';
import {
	createBlankProjectStep1Scheme,
	getCreateBlankProjectStep2Scheme,
} from './validation';

export interface ICreateBlankProjectValues {
	projectUrl: string;
	projectName: string;
	projectGreeting: string;
	expireDate: Date | null;
	publishDate: Date | null;
	journey: ProjectJourneys;
	accessCodeMessage: string;
	smsSpecialMessage: string;
	emailSpecialMessage: string;
	isMarketingEnabled: boolean;
	lastPictureDate: Date | null;
	priceListSale1: string | null;
	priceListSale2: string | null;
	priceListSale3: string | null;
	firstPictureDate: Date | null;
	orderNotificationEmail: string;
	leadCampaignKey: number | null;
	buyerCampaignKey: number | null;
	orderConfirmationMessage: string;
	estimatedNumberOfSubjects: string;
	projectType: ProjectTypes | string;
	prospectCampaignKey: number | null;
}

export const initialValues: ICreateBlankProjectValues = {
	projectUrl: '',
	projectName: '',
	projectType: '',
	expireDate: null,
	publishDate: null,
	projectGreeting: '',
	priceListSale1: null,
	priceListSale2: null,
	priceListSale3: null,
	lastPictureDate: null,
	accessCodeMessage: '',
	smsSpecialMessage: '',
	leadCampaignKey: null,
	buyerCampaignKey: null,
	firstPictureDate: null,
	emailSpecialMessage: '',
	isMarketingEnabled: true,
	prospectCampaignKey: null,
	orderNotificationEmail: '',
	journey: ProjectJourneys.A,
	orderConfirmationMessage: '',
	estimatedNumberOfSubjects: '',
};

export type ScrollHelperRef = RefObject<HTMLDivElement>;

export const CreateBlankProject: FC = () => {
	const [step, setStep] = useState<Steps>(Steps.STEP1);

	const editorRef = useRef<CKEditor<Editor>>(null);

	const [saleType1, saleType2, saleType3] = useAppSelector(saleTypesSelector);
	const priceListSale1Options = useAppSelector(priceListsSale1Selector);
	const priceListSale2Options = useAppSelector(priceListsSale2Selector);
	const priceListSale3Options = useAppSelector(priceListsSale3Selector);
	const organizationKey = useAppSelector(organizationKeySelector);

	const isValidSubjectFormQuestions = useAppSelector(
		isValidSubjectFormQuestionsSelector
	);
	const subjectFormQuestionsParams = useAppSelector(
		subjectFormQuestionsParamsSelector
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useOrganizationKeyExist();

	const onSubmit = async (
		values: ICreateBlankProjectValues,
		{ setErrors }: FormikHelpers<ICreateBlankProjectValues>
	) => {
		if (!isValidSubjectFormQuestions) return;

		const {
			journey,
			projectUrl,
			expireDate,
			projectType,
			projectName,
			publishDate,
			priceListSale1,
			priceListSale2,
			priceListSale3,
			leadCampaignKey,
			lastPictureDate,
			buyerCampaignKey,
			firstPictureDate,
			smsSpecialMessage,
			accessCodeMessage,
			isMarketingEnabled,
			emailSpecialMessage,
			prospectCampaignKey,
			orderNotificationEmail,
			orderConfirmationMessage,
			estimatedNumberOfSubjects,
		} = values;

		const currentEditorValue = editorRef.current?.editor?.data.get();

		const findPriceListSaleOption = (
			priceListSale: string | null,
			priceListSaleOptions: IPriceListSale[]
		) =>
			priceListSaleOptions.find(({ priceListKey, fulfillmentType }) => {
				if (!priceListSale) return false;

				const [key, fulfillment] = priceListSale.split('-');

				return priceListKey === +key && fulfillment === fulfillmentType;
			});

		const priceListSale1Option = findPriceListSaleOption(
			priceListSale1,
			priceListSale1Options
		);

		const priceListSale2Option = findPriceListSaleOption(
			priceListSale2,
			priceListSale2Options
		);

		const priceListSale3Option = findPriceListSaleOption(
			priceListSale3,
			priceListSale3Options
		);

		const priceLists: IPriceListSaleParams[] = [];

		if (priceListSale1Option) {
			const { priceListName, ...rest } = priceListSale1Option;

			priceLists.push({
				...rest,
				saleType: saleType1,
			});
		} else {
			priceLists.push({
				...salesNotAvailableParam,
				saleType: saleType1,
			});
		}

		if (priceListSale2Option) {
			const { priceListName, ...rest } = priceListSale2Option;

			priceLists.push({
				...rest,
				saleType: saleType2,
			});
		} else {
			priceLists.push({
				...salesNotAvailableParam,
				saleType: saleType2,
			});
		}

		if (priceListSale3Option) {
			const { priceListName, ...rest } = priceListSale3Option;

			priceLists.push({
				...rest,
				saleType: saleType3,
			});
		} else {
			priceLists.push({
				...salesNotAvailableParam,
				saleType: saleType3,
			});
		}

		const expireDateParam = expireDate ? prepareDate(expireDate) : '';
		const publishDateParam = publishDate ? prepareDate(publishDate) : '';
		const lastPictureDateParam = lastPictureDate
			? prepareDate(lastPictureDate)
			: '';
		const firstPictureDateParam = firstPictureDate
			? prepareDate(firstPictureDate)
			: '';

		const createProjectBody: ICreateBlankProjectBody = {
			journey,
			priceLists,
			projectUrl,
			projectName,
			organizationKey,
			isMarketingEnabled,
			orderNotificationEmail,
			orderConfirmationMessage,
			expireDate: expireDateParam,
			publishDate: publishDateParam,
			lastPictureDate: lastPictureDateParam,
			firstPictureDate: firstPictureDateParam,
			projectType: projectType as ProjectTypes,
			projectGreeting: currentEditorValue || '',
			subjectFormQuestions: subjectFormQuestionsParams,
			estimatedNumberOfSubjects: +estimatedNumberOfSubjects,
			leadCampaignKey: isMarketingEnabled ? leadCampaignKey : null,
			buyerCampaignKey: isMarketingEnabled ? buyerCampaignKey : null,
			smsSpecialMessage: isMarketingEnabled ? smsSpecialMessage : null,
			accessCodeMessage: isMarketingEnabled ? accessCodeMessage : null,
			emailSpecialMessage: isMarketingEnabled ? emailSpecialMessage : null,
			prospectCampaignKey: isMarketingEnabled ? prospectCampaignKey : null,
		};

		try {
			const data = await ProjectsService.createBlankProject(createProjectBody);

			dispatch(setCreatedProject(data));
			navigate(`${ROUTES.PROJECTS.PROJECTS}/${data.projectKey}`);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const processedErrors = getProcessedErrors(values, errors);

			setErrors(processedErrors);
		}
	};

	const lazyValidation =
		step === Steps.STEP1
			? object(createBlankProjectStep1Scheme)
			: lazy((values: ICreateBlankProjectValues) => {
					const { journey, isMarketingEnabled } = values;

					const { journeyDate } = journeyTimelines[journey];

					const {
						validatePriceListSale1,
						validatePriceListSale2,
						validatePriceListSale3,
					} = projectJourneyInfo[journey];

					return object({
						...createBlankProjectStep1Scheme,
						...getCreateBlankProjectStep2Scheme({
							isMarketingEnabled,
							validatePriceListSale1,
							validatePriceListSale2,
							validatePriceListSale3,
							datesLabels: journeyDate.datesLabels,
							validateLastPictureDate:
								journeyDate.validateBlankProjectLastPictureDate,
							validateFirstPictureDate:
								journeyDate.validateBlankProjectFirstPictureDate,
						}),
					});
			  });

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={lazyValidation}
		>
			<>
				{step === Steps.STEP1 && <BlankProjectStep1 setStep={setStep} />}
				{step === Steps.STEP2 && (
					<BlankProjectStep2 setStep={setStep} editorRef={editorRef} />
				)}
			</>
		</Formik>
	);
};
