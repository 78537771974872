import { ImageSasUrlResponse } from 'api/models/responses/photos/imageSasUrl';
import { CloudPhotoTypes } from 'api/models/responses/photos/cloudPhotoTypes';

export const prepareSasUrl = (
	photoType: CloudPhotoTypes,
	sasUrl?: ImageSasUrlResponse
) => {
	const invalidType = typeof sasUrl === 'string';

	if (invalidType || !sasUrl) return '';

	return sasUrl.SaSUrls?.[photoType] ?? '';
};
