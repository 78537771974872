import { parseTwoDigitYear } from 'moment';
import { useEffect } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { updateCardValidationSchema } from 'pages/RegistrationSteps/CreateStudio/validations';
import { ICardInfoValues } from 'pages/RegistrationSteps/CreateStudio';

import { ICardInfoBody } from 'api/models/requests/studioBilling/cardInfoBody';
import StudioBillingService from 'api/services/StudioBillingService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useModalControls } from 'hooks/useModalControls';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { setCardInfo, getCardInfoAsync } from 'store/studioBilling';
import { Payments } from 'types/ui/payments';

import { BillingInfoList } from './components/BillingInfoList';
import { UpdateCardInfo } from './components/UpdateCardInfo';

export const BillingInformation = () => {
	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const {
		modalRef: updateCardModalRef,
		showModal: showUpdateCardModal,
		hideModal: hideUpdateCardModal,
	} = useModalControls();

	const initialValues: ICardInfoValues = {
		cardNumber: '',
		expiration: '',
		billingZip: '',
		verificationCode: '',
		cardType: Payments.VISA,
	};

	const onSubmit = async (
		values: ICardInfoValues,
		{ setErrors }: FormikHelpers<ICardInfoValues>
	) => {
		const { cardType, cardNumber, expiration, verificationCode, billingZip } =
			values;

		const [expireMonth, expireYear] = expiration.split('/');

		const fullYear = parseTwoDigitYear(expireYear);

		const billingCardInfo: ICardInfoBody = {
			cardType,
			cardNumber,
			billingZip,
			verificationCode,
			expireYear: fullYear,
			expireMonth: +expireMonth,
		};

		try {
			const data = await StudioBillingService.updateCardInfo(billingCardInfo);

			dispatch(setCardInfo(data));

			hideUpdateCardModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.ValidationError) {
				const updatedErrors = Object.entries(errors).reduce<
					FormikErrors<ICardInfoValues>
				>((acc, [key, messages]) => {
					const keyExist = key in values;

					const message = messages[0] ?? '';

					if (key === 'expireYear' || key === 'expireMonth') {
						return {
							...acc,
							expiration: message,
						};
					}

					if (!keyExist) return acc;

					return {
						...acc,
						[key]: message,
					};
				}, {});

				setErrors(updatedErrors);
			}
		}
	};

	useEffect(() => {
		void dispatch(getCardInfoAsync());
	}, []);

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="Billing Information"
				handleToggle={handleToggle}
				className="accing-toggle-header"
			>
				<div className="accing-wrapper">
					<span className="accing-title">
						Will use for any fees and balances for this studio.
					</span>
					<div className="accing-list-wrapper">
						<BillingInfoList />
						<Button
							value="Update Credit Card"
							handleClick={showUpdateCardModal}
							className="btn-secondary accing-btn"
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				ref={updateCardModalRef}
				title="Update Card Details"
				subtitle="Will use this card for any fees and balances for this studio."
			>
				<Formik
					onSubmit={onSubmit}
					initialValues={initialValues}
					validationSchema={updateCardValidationSchema}
				>
					<UpdateCardInfo hideModal={hideUpdateCardModal} />
				</Formik>
			</Modal>
		</div>
	);
};
