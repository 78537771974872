import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { billingInfoSelector } from 'store/studioBilling';

export const W9InfoList: FC = () => {
	const billingInfo = useAppSelector(billingInfoSelector);

	return (
		<ul className="accing-cols">
			<li className="accing-item">
				Name:
				<span>
					{billingInfo?.billingFirstName} {billingInfo?.billingLastName}
				</span>
			</li>
			<li className="accing-item">
				Business Name: <span>{billingInfo?.billingBusinessName}</span>
			</li>
			<li className="accing-item">
				Address:
				<span>
					{billingInfo?.billingStreet}, {billingInfo?.billingCity},
					{billingInfo?.billingState} {billingInfo?.billingPostalCode}
				</span>
			</li>
			<li className="accing-item">
				Phone: <span>{billingInfo?.billingPhone}</span>
			</li>
			<li className="accing-item">
				Email: <span>{billingInfo?.billingEmail}</span>
			</li>
			<li className="accing-item">
				Entity Type: <span>{billingInfo?.billingEntityType}</span>
			</li>
			<li className="accing-item">
				Tax ID Type: <span>SSN</span>
			</li>
			<li className="accing-item">
				Last 4 #s: <span>{billingInfo?.tinLastFour}</span>
			</li>
		</ul>
	);
};
