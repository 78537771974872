import cn from 'classnames';
import { FC } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { isOpenSelector, setIsOpen } from 'store/aside';

import { Navigation } from './components/Navigation';
import { FindOrder } from './components/FindOrder';
import { Accounts } from './components/Accounts';
import { SendLink } from './components/SendLink';

export const Aside: FC = () => {
	const isOpen = useAppSelector(isOpenSelector);

	const dispatch = useAppDispatch();

	const hideAside = () => dispatch(setIsOpen(false));

	return (
		<aside
			className={cn('aside', {
				'is-open': isOpen,
			})}
		>
			<button onClick={hideAside} className="asside-collapse">
				Collapse Menu
			</button>
			<Accounts />
			<FindOrder />
			<SendLink />
			<Navigation />
		</aside>
	);
};
