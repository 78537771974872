import moment, { utc } from 'moment';

import { DateRanges } from 'constants/dashboard/dateRange';
import { IDateRange } from 'store/dashboard/types';

const boundariesPastDates: Record<string, string> = {
	[DateRanges.WEEK]: moment()
		.set({ hours: 0, minutes: 0, seconds: 0 })
		.subtract(7, 'days')
		.format(),
	[DateRanges.MONTH]: moment()
		.set({ hours: 0, minutes: 0, seconds: 0 })
		.subtract(30, 'days')
		.format(),
	[DateRanges.YEAR]: moment()
		.set({ hours: 0, minutes: 0, seconds: 0 })
		.subtract(365, 'days')
		.format(),
};

type DatesIntervalBoundaries = Omit<IDateRange, 'term'>;

export const getIntervalBoundariesByTerm = (
	term: string
): DatesIntervalBoundaries => {
	const endDate = moment().set({ hours: 24, minutes: 0, seconds: 0 });

	const previousDayIndex = endDate.subtract(1, 'days').format('DD');
	const startDate = boundariesPastDates[term];

	const startMonthIndex = startDate.split('-')[1];
	const startDayIndex = startDate.split('-')[2].slice(0, 2);

	const startMonthName = moment(startDate)
		.month(+startMonthIndex - 1)
		.format('MMM');

	const endMonthName = endDate.format('MMM');

	return {
		endMonthName,
		startDayIndex,
		startMonthName,
		endDayIndex: previousDayIndex,
		dateTo: utc(endDate).format(),
		dateFrom: utc(startDate).format(),
	};
};
