import { LogLevel } from '@azure/msal-browser';

const CLIENT_ID = process.env.REACT_APP_AZURE_B2C_CLIENT_ID || '';
const INSTANCE = process.env.REACT_APP_AZURE_B2C_INSTANCE || '';
const DOMAIN = process.env.REACT_APP_AZURE_B2C_DOMAIN || '';
const POLICY_ID = process.env.REACT_APP_AZURE_B2C_POLICY_ID || '';
const LOGIN_SCOPE = process.env.REACT_APP_AZURE_B2C_LOGIN_SCOPE || '';

export const b2cPolicies = {
	names: {
		signUpSignIn: POLICY_ID,
	},
	authorities: {
		signUpSignIn: {
			authority: `https://${INSTANCE}/${DOMAIN}/${POLICY_ID}`,
		},
	},
	authorityDomain: INSTANCE,
};

const TEMP_HIDE_LOGS = true;

export const authConfig = {
	auth: {
		clientId: CLIENT_ID,
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: '/',
		postLogoutRedirectUri: '/',
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (
				level: number,
				message: string,
				containsPii: boolean
			) => {
				if (TEMP_HIDE_LOGS) return;

				if (containsPii) {
					return;
				}

				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const loginRequest = {
	scopes: [LOGIN_SCOPE],
};
