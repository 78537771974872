import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Table } from 'components/Table';
import { Card } from 'components/Card';

import { getOrdersWaitingAsync, ordersWaitingSelector } from 'store/dashboard';
import { IOrderWaiting } from 'api/models/responses/orders/orderWaiting';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { ROUTES } from 'constants/ROUTES';

export const OrderWaiting: FC = () => {
	const ordersWaiting = useAppSelector(ordersWaitingSelector);

	const dispatch = useAppDispatch();

	useEffect(() => {
		void dispatch(getOrdersWaitingAsync());
	}, []);

	const columns: Column<IOrderWaiting>[] = [
		{
			id: 'projectName',
			Header: 'Project',
			Cell: ({ row }: CellProps<IOrderWaiting>) => {
				const { projectKey, projectName } = row.original;

				const path = `${ROUTES.PROJECTS.PROJECTS}/${projectKey}`;

				return (
					<Link to={path} title={projectName} className="card-table-link">
						{projectName}
					</Link>
				);
			},
		},
		{
			accessor: 'amountOfOrders',
			Header: '# of Orders',
			Cell: ({
				value,
			}: CellProps<IOrderWaiting, IOrderWaiting['amountOfOrders']>) => (
				<>{value}</>
			),
		},
		{
			accessor: 'dateWaitingSince',
			Header: 'Waiting Since',
			Cell: ({
				value,
			}: CellProps<IOrderWaiting, IOrderWaiting['dateWaitingSince']>) => (
				<>{normalizeDate(value)}</>
			),
		},
	];

	return (
		<Card
			bodyClassName="card-table"
			title="Orders Waiting For Studio Action"
			subtitle="(‘received’ status > than 14 days)"
		>
			<Table
				columns={columns}
				className="org-table"
				data={ordersWaiting.results}
			/>
		</Card>
	);
};
