import { createSelector } from 'reselect';

import { RootState } from 'store/store';

export const accountsStateSelector = (state: RootState) => state.accounts;

export const usersSelector = createSelector(
	accountsStateSelector,
	(accounts) => accounts.users
);

export const currentUserSelector = createSelector(
	accountsStateSelector,
	(accounts) => accounts.currentUser
);

export const isUsersPendingSelector = createSelector(
	accountsStateSelector,
	(accounts) => accounts.isUsersPending
);
