import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ICloseAccValues } from 'pages/Accounting';

interface ICloseModal {
	closeModal: () => void;
}

export const CloseAccount: FC<ICloseModal> = ({ closeModal }) => {
	const { values, errors, touched, handleChange, handleSubmit } =
		useFormikContext<ICloseAccValues>();

	return (
		<div className="modal-body accing-modal">
			<span className="accing-modal-title">
				Confirm by typing “DELETE” below
			</span>
			<InputGrid
				name="confirmationText"
				placeholder="DELETE"
				required={false}
				className="accing-modal"
				value={values.confirmationText}
				handleChange={handleChange}
				touched={touched.confirmationText}
				error={errors.confirmationText}
			/>
			<div className="input-checkbox-container">
				<input
					id="check"
					type="checkbox"
					name="confirmationCheckbox"
					className="input-checkbox"
					checked={values.confirmationCheckbox}
					onChange={handleChange}
				/>
				<label htmlFor="check" className="input-checkbox-label">
					<span>
						Yes, I want to delete all information and close my imago account.
					</span>
					{touched.confirmationCheckbox && errors.confirmationCheckbox && (
						<span className="input-error">{errors.confirmationCheckbox}</span>
					)}
				</label>
			</div>
			<div className="accing-modal-btns">
				<Button
					className="btn-primary"
					value="Keep Account Open"
					handleClick={closeModal}
				/>
				<Button
					value="Close Account"
					className="btn-secondary"
					handleClick={handleSubmit}
				/>
			</div>
		</div>
	);
};
