import { IBaseSortReqParams } from 'api/models/requests/general/baseSortReqParams';
import { OrderDirection } from 'api/models/requests/general/orderDirection';

import { ColumnLabel } from './ColumnLabel';

interface IColumnLabelArrowContainerLabelProps<SortLabels>
	extends IBaseSortReqParams<SortLabels> {
	label: string;
	sortLabel: SortLabels;
	setSortParams: (sortLabel: SortLabels) => void;
}

export const ColumnLabelContainer = <SortLabels,>({
	label,
	orderBy,
	sortLabel,
	setSortParams,
	orderDirection,
}: IColumnLabelArrowContainerLabelProps<SortLabels>) => {
	const showArrowIcon = sortLabel === orderBy;
	const isActive = orderDirection === OrderDirection.ASC;

	const handleSort = () => {
		setSortParams(sortLabel);
	};

	return (
		<ColumnLabel
			label={label}
			isActive={isActive}
			handleSort={handleSort}
			showArrowIcon={showArrowIcon}
		/>
	);
};
