import { Dispatch } from '@reduxjs/toolkit';

import StudioPhotoSettingsService from 'api/services/StudioPhotoSettingsService';
import StudioMarketingService from 'api/services/StudioMarketingService';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import StudioService from 'api/services/StudioService';

import { RootState } from 'store/store';

import {
	setStudio,
	setStudios,
	setStudioMarketing,
	setStudioContactInfo,
	setStudioPhotoSettings,
} from './studioSlice';

export const getStudiosAsync = () => async (dispatch: Dispatch) => {
	try {
		const studios = await StudioService.getStudios();

		dispatch(setStudios(studios));
	} catch (error) {
		console.log(error);
	}
};

export const switchStudioAsync =
	(studioKey: number) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { studio } = getState();

		if (studio.studio?.studioKey === studioKey) return;

		try {
			const data = await StudioService.switchStudio(studioKey);

			dispatch(setStudio(data));
		} catch (error) {
			console.log(error);
		}
	};

export const getStudioMarketingAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioMarketingService.getStudioMarketing();

		dispatch(setStudioMarketing(data));
	} catch (error) {
		console.log(error);
	}
};

export const getStudioPhotoSettingsAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioPhotoSettingsService.getStudioPhotoSettings();

		dispatch(setStudioPhotoSettings(data));
	} catch (error) {
		console.log(error);
	}
};

export const getStudioContactInfoAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioService.getStudioContactInfo();

		dispatch(setStudioContactInfo(data));
	} catch (error) {
		console.log(error);
	}
};

export const updateStudioMarketingAsync =
	(body: IPatchBody[]) => async (dispatch: Dispatch) => {
		try {
			const data = await StudioMarketingService.updateStudioMarketing(body);

			dispatch(setStudioMarketing(data));
		} catch (error) {
			console.log(error);
		}
	};

export const createPhoneNumberAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await StudioMarketingService.createPhoneNumber();

		dispatch(setStudioMarketing(data));
	} catch (error) {
		console.log(error);
	}
};
