import { CKEditor } from '@ckeditor/ckeditor5-react';
import { FC, SyntheticEvent, useRef } from 'react';
import Editor from 'ckeditor5-custom-build';

import { saleConditionSelectOptions } from 'constants/projects/selectOptions/saleConditionSelectOptions';
import { ISaleUI } from 'constants/projects/salesUI';
import { HIDDEN } from 'constants/general/hidden';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Checkbox } from 'components/FormControls/Checkbox';
import { TextArea } from 'components/FormControls/TextArea';
import { SaleTitle } from 'components/Sales/SaleTitle';
import { TextEditor } from 'components/TextEditor';

import { getPriceListSelectOptionKey } from 'utils/priceLists/getPriceListSelectOptionKey';
import { SaleCondition } from 'api/models/requests/presets/createPresets';
import { ISelectOption } from 'types/ui/select';

import { validateOrderConfirmationMessage } from '../../../helpers/validateOrderConfirmationMessage';
import {
	IPresetSaleUI,
	IUpdatePresetSale,
	ClearPresetSaleError,
} from '../../../types';
import { validateEmail } from 'utils/validations/general/validateEmail';

interface IJourneyPresetSaleProps {
	saleUI: ISaleUI;
	presetSaleUI: IPresetSaleUI;
	disabledSaleExpiresInDays?: boolean;
	clearPresetSaleError: ClearPresetSaleError;
	priceListsSelectOptions: ISelectOption<string>[];
	updateSale: (payload: IUpdatePresetSale) => Promise<void>;
}

export const JourneyPresetSale: FC<IJourneyPresetSaleProps> = ({
	saleUI,
	updateSale,
	presetSaleUI,
	clearPresetSaleError,
	priceListsSelectOptions,
	disabledSaleExpiresInDays,
}) => {
	const textEditorRef = useRef<CKEditor<Editor>>(null);

	const {
		errors,
		saleType,
		priceList,
		condition,
		presetSaleKey,
		projectGreeting,
		saleExpiresInDays,
		isKioskModeEnabled,
		orderNotificationEmail,
		orderConfirmationMessage,
	} = presetSaleUI;

	const { icon, title, saleExpiresLabel } = saleUI;

	const priceListSelectLabel = `${title} Price List`;
	const initialPriceListKey =
		priceList &&
		getPriceListSelectOptionKey(
			priceList.priceListKey,
			priceList.fulfillmentType
		);

	const updatePriceListKey = async (value: string) => {
		const [key, fulfillmentType] = value.split('-');

		await updateSale({
			value: +key,
			key: 'priceListKey',
			saleKey: presetSaleKey,
		});

		await updateSale({
			value: fulfillmentType,
			key: 'fulfillmentType',
			saleKey: presetSaleKey,
		});
	};

	const priceListControls = useAsyncOptimizedSelect({
		initialValue: initialPriceListKey,
		updateSelectValue: updatePriceListKey,
	});

	const updateCondition = async (value: SaleCondition) => {
		await updateSale({
			value,
			key: 'condition',
			saleKey: presetSaleKey,
		});
	};

	const conditionControls = useAsyncOptimizedSelect({
		initialValue: condition,
		updateSelectValue: updateCondition,
	});

	const updateIsKioskModeEnabled = async (value: boolean) => {
		await updateSale({
			value,
			saleKey: presetSaleKey,
			key: 'isKioskModeEnabled',
		});
	};

	const isKioskModeEnabledControls = useAsyncOptimizedCheckbox(
		isKioskModeEnabled,
		updateIsKioskModeEnabled
	);

	const updateSaleExpiresInDays = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		void updateSale({
			value: +value,
			saleKey: presetSaleKey,
			key: 'saleExpiresInDays',
		});
	};

	const clearSaleExpiresInDaysError = () => {
		if (!errors.saleExpiresInDays) return;

		clearPresetSaleError({ saleKey: presetSaleKey, key: 'saleExpiresInDays' });
	};

	const updateOrderNotificationEmail = (
		e: SyntheticEvent<HTMLInputElement>
	) => {
		const { value } = e.currentTarget;

		const emailValidationMessage = validateEmail(value);

		void updateSale({
			value,
			saleKey: presetSaleKey,
			key: 'orderNotificationEmail',
			validationMessage: emailValidationMessage,
		});
	};

	const clearOrderNotificationEmailError = () => {
		if (!errors.orderNotificationEmail) return;

		clearPresetSaleError({
			saleKey: presetSaleKey,
			key: 'orderNotificationEmail',
		});
	};

	const updateOrderConfirmationMessage = (
		e: SyntheticEvent<HTMLTextAreaElement>
	) => {
		const { value } = e.currentTarget;

		const validationMessage = validateOrderConfirmationMessage(value);

		void updateSale({
			value,
			validationMessage,
			saleKey: presetSaleKey,
			key: 'orderConfirmationMessage',
		});
	};

	const clearOrderConfirmationMessageError = () => {
		if (!errors.orderConfirmationMessage) return;

		clearPresetSaleError({
			saleKey: presetSaleKey,
			key: 'orderConfirmationMessage',
		});
	};

	const updateProjectGreeting = () => {
		const projectGreetingData = textEditorRef.current?.editor?.data.get() || '';

		void updateSale({
			saleKey: presetSaleKey,
			key: 'projectGreeting',
			value: projectGreetingData,
		});
	};

	const clearProjectGreetingError = () => {
		if (!errors.projectGreeting) return;

		clearPresetSaleError({
			saleKey: presetSaleKey,
			key: 'projectGreeting',
		});
	};

	return (
		<>
			<SaleTitle icon={icon} title={title} />
			<div className="preset-form-layout">
				<div className="preset-grid preset-header-border">
					<div className="preset-section-wrapper">
						<SelectComponent
							label={priceListSelectLabel}
							value={
								priceListControls.value ?? priceListsSelectOptions[0].value
							}
							selectPlaceholder="Select Price List"
							disabled={priceListControls.isPending}
							selectOptions={priceListsSelectOptions}
							onChange={priceListControls.handleChange}
						/>
					</div>
				</div>
				<div className="preset-grid">
					<InputGrid
						isLazy
						touched
						placeholder="Days"
						label="Sale Expires"
						error={errors.saleExpiresInDays}
						className="autorow preset-expires"
						disabled={disabledSaleExpiresInDays}
						clearError={clearSaleExpiresInDaysError}
						handleLazyChange={updateSaleExpiresInDays}
						defaultValue={saleExpiresInDays.toString()}
					/>
					<span className="preset-days">
						Days
						<SelectComponent
							value={conditionControls.value}
							disabled={conditionControls.isPending}
							onChange={conditionControls.handleChange}
							selectOptions={saleConditionSelectOptions}
						/>
						{saleExpiresLabel}
					</span>
					{!HIDDEN && (
						<Checkbox
							label="Enable Kiosk Mode"
							id={`${saleType}-isKioskModeEnabled`}
							checked={isKioskModeEnabledControls.checked}
							disabled={isKioskModeEnabledControls.isPending}
							handleChange={isKioskModeEnabledControls.handleChange}
						/>
					)}
				</div>
				<div className="preset-message preset-header-border">
					<InputGrid
						isLazy
						touched
						required={false}
						className="autorow"
						value={orderNotificationEmail}
						label="Order Notification Email"
						error={errors.orderNotificationEmail}
						placeholder="Order Notification Email"
						clearError={clearOrderNotificationEmailError}
						handleLazyChange={updateOrderNotificationEmail}
					/>
					<TextArea
						isLazy
						touched
						className="textarea-preset"
						value={orderConfirmationMessage}
						label="Order Confirmation Message"
						error={errors.orderConfirmationMessage}
						placeholder="Order Confirmation Message"
						clearError={clearOrderConfirmationMessageError}
						handleLazyChange={updateOrderConfirmationMessage}
					/>
				</div>
				<div className="project-section preset-header-border">
					<h2 className="project-section-title">Project Greeting</h2>
					<TextEditor
						editorRef={textEditorRef}
						initialContent={projectGreeting}
						handleSave={updateProjectGreeting}
						errorMessage={errors.projectGreeting}
						clearError={clearProjectGreetingError}
					/>
				</div>
			</div>
		</>
	);
};
