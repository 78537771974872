import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { FC } from 'react';

import { IInviteUserBody } from 'api/models/requests/account/inviteUserBody';
import { UserRoles } from 'api/models/requests/account/userRoles';
import AccountService from 'api/services/AccountService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { useToastify } from 'hooks/useToastify';
import { addUser } from 'store/accounts';

import { inviteUserValidationSchema } from './validation';
import { CreateNewUserForm } from './CreateNewUserForm';

interface ICreateNewUserProps {
	hideCreateNewUserModal: () => void;
	showRejectCreateUserModal: () => void;
	setInviteUserError: (error: string) => void;
}

export const CreateNewUser: FC<ICreateNewUserProps> = ({
	setInviteUserError,
	hideCreateNewUserModal,
	showRejectCreateUserModal,
}) => {
	const { showSuccessToastify } = useToastify();
	const dispatch = useDispatch();

	const initialValues: IInviteUserBody = {
		email: '',
		lastName: '',
		firstName: '',
		role: UserRoles.Assistant,
	};

	const onSubmit = async (values: IInviteUserBody) => {
		try {
			const data = await AccountService.inviteUser(values);

			dispatch(addUser(data));

			showSuccessToastify({ title: 'New User Created' });

			hideCreateNewUserModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const additionalUsersError = errors.additionalUsers?.[0];
				const maxPriceListCountError = errors.maxPriceListCount?.[0];

				if (additionalUsersError) {
					setInviteUserError(additionalUsersError);
				}

				if (maxPriceListCountError) {
					setInviteUserError(maxPriceListCountError);
				}

				showRejectCreateUserModal();
				hideCreateNewUserModal();
			}
		}
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={inviteUserValidationSchema}
		>
			<CreateNewUserForm />
		</Formik>
	);
};
