import { ChangeEvent, FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { ImagePreviewLoader } from 'pages/PriceLists/components/ImagePreviewLoader';
import { ImageUploadArea } from 'pages/PriceLists/components/ImageUploadArea';

import { PriceDeleteBtn } from 'components/Controls/PriceDeleteBtn';
import { getImageBlobUrl } from 'utils/images/getImageBlobUrl';

interface IPreviewImageColumnProps {
	error?: string;
	colSpan?: number;
	dimensions?: string;
	isImagePending: boolean;
	handleDeleteImage: () => void;
	previewImageFile: File | null;
	previewImageUrl: string | null;
	handleUploadImage: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const PreviewImageColumn: FC<IPreviewImageColumnProps> = ({
	error,
	colSpan,
	dimensions,
	isImagePending,
	previewImageUrl,
	previewImageFile,
	handleUploadImage,
	handleDeleteImage,
}) => {
	const [preloadImageUrl, setPreloadImageUrl] = useState<string | null>(null);

	useEffect(() => {
		if (!previewImageFile && preloadImageUrl) {
			return setPreloadImageUrl(null);
		}

		if (!previewImageFile) return;

		const reader = new FileReader();

		reader.onload = () => {
			setPreloadImageUrl(reader.result as string);
		};

		reader.onerror = () => {
			console.log('There was an error loading the image.');
		};

		reader.readAsDataURL(previewImageFile);
	}, [previewImageFile]);

	const imageBlobUrl = previewImageUrl && getImageBlobUrl(previewImageUrl);

	const url = imageBlobUrl ?? preloadImageUrl;

	const showHoverArea = url && !isImagePending;

	return (
		<td
			colSpan={colSpan}
			className={cn('price-option-td', {
				'price-td-preview': showHoverArea,
			})}
		>
			{showHoverArea && <PriceDeleteBtn handleClick={handleDeleteImage} />}
			{isImagePending ? (
				<ImagePreviewLoader />
			) : (
				<div
					className={cn('', {
						'price-preview': !dimensions,
						'price-size-preview': dimensions,
					})}
				>
					{dimensions && (
						<span className="price-preview-size">
							Preview <i>({dimensions})</i>
						</span>
					)}
					{url ? (
						<img
							src={url}
							alt="background preview"
							className="price-preview-img"
						/>
					) : (
						<ImageUploadArea handleUploadImage={handleUploadImage} />
					)}
					{error && <span className="input-error">{error}</span>}
				</div>
			)}
		</td>
	);
};
