import { useDropzone } from 'react-dropzone';
import { FC, useState } from 'react';

import { validateFloatingImageDimensions } from 'utils/images/validateFloatingImageDimensions';
import { getImageDimensions } from 'utils/images/getImageDimensions';
import { getImageBlobUrl } from 'utils/images/getImageBlobUrl';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import {
	minUserPhotoWidth,
	minUserPhotoHeight,
} from 'constants/account/validation/userPhotoDimensions';
import {
	imageUploadTypes,
	imageUploadTypesErrorMessage,
} from 'constants/images/validation/imageUploadTypes';

import { IUser } from 'api/models/responses/account/user';
import AccountService from 'api/services/AccountService';

import { currentUserSelector, setCurrentUser } from 'store/accounts';
import { OrgDeleteBtn } from 'components/Controls/OrgDeleteBtn';

export const UserPhoto: FC = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [isPending, setIsPending] = useState(false);

	const currentUser = useAppSelector(currentUserSelector);

	const dispatch = useAppDispatch();

	const uploadPhoto = async (acceptedFiles: File[]) => {
		if (!currentUser) return;

		if (!acceptedFiles.length) {
			return setErrorMessage(imageUploadTypesErrorMessage);
		}

		const [file] = acceptedFiles;

		setIsPending(true);

		try {
			const { width, height } = await getImageDimensions(file);

			const imageDimensionsErrorMessage = validateFloatingImageDimensions({
				width,
				height,
				minWidth: minUserPhotoWidth,
				minHeight: minUserPhotoHeight,
			});

			if (imageDimensionsErrorMessage) {
				return setErrorMessage(imageDimensionsErrorMessage);
			}

			const data = await AccountService.uploadUserPhoto(file);

			const updatedCurrentUser: IUser = {
				...currentUser,
				photoUrl: data,
			};

			dispatch(setCurrentUser(updatedCurrentUser));
		} catch (error) {
			if (error instanceof Error) {
				return setErrorMessage(error.message);
			}
		} finally {
			setIsPending(false);
		}
	};

	const deletePhoto = async () => {
		if (!currentUser) return;

		setIsPending(true);

		try {
			await AccountService.deleteUserPhoto();

			const updatedCurrentUser: IUser = {
				...currentUser,
				photoUrl: '',
			};

			dispatch(setCurrentUser(updatedCurrentUser));
		} catch (error) {
			console.log(error);
		} finally {
			setIsPending(false);
		}
	};

	const handleClickDeleteBtn = () => {
		void deletePhoto();
	};

	const onDrop = (acceptedFiles: File[]) => {
		setErrorMessage('');

		void uploadPhoto(acceptedFiles);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		accept: imageUploadTypes,
	});

	const photoUrl = currentUser?.photoUrl;

	if (isPending) {
		return (
			<div className="loader-container">
				<div className="loader" />
			</div>
		);
	}

	if (!photoUrl) {
		return (
			<>
				<span className="org-cover-mock" {...getRootProps()}>
					<input {...getInputProps()} />
				</span>
				{errorMessage && (
					<span className="org-cover-error">{errorMessage}</span>
				)}
			</>
		);
	}

	const url = getImageBlobUrl(photoUrl);

	return (
		<div className="org-cover-img-container">
			<img src={url} alt="user-img" />
			<OrgDeleteBtn handleClick={handleClickDeleteBtn} />
		</div>
	);
};
