export const priceListNameRegEx =
	/^(?!.*\s$)[a-zA-Z0-9][a-zA-Z0-9\s.\-()/+'""‘’`&]*$/;

export const priceListNameMinLength = 3;
export const priceListNameMaxLength = 100;

export const priceListNameErrorMessage = 'Invalid name.';

export const priceListNameMinLengthError = `Price list name must be at least ${priceListNameMinLength} characters.`;
export const priceListNameMaxLengthError = `Price list name must be at most ${priceListNameMaxLength} characters.`;
