import { FC, ReactNode } from 'react';

interface IUserIconProps {
	children?: ReactNode;
}

export const UserIcon: FC<IUserIconProps> = ({ children }) => (
	<div className="acc-add-user">
		<i className="acc-user-icon icon-user" />
		{children}
	</div>
);
