import { invalidNumberChars } from 'constants/general/validation/invalidNumberChars';

export const validateStringNumber = (value: string, validLength?: number) => {
	const isNumber = /^\d+$/.test(value);

	const isContainInvalidChar = invalidNumberChars.some((char) =>
		value.includes(char)
	);

	if (!value) return true;

	const isValidLength = validLength ? value.length <= validLength : true;

	return isNumber && !isContainInvalidChar && isValidLength;
};
