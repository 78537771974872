import { FC, SyntheticEvent, useState } from 'react';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { maxEmailLength } from 'constants/general/validation/email';

import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';

import { validateEmail } from 'utils/validations/general/validateEmail';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import {
	studioMarketingSelector,
	updateStudioMarketingAsync,
} from 'store/studio';

export const EmailSettings: FC = () => {
	const [senderNameError, setSenderNameError] = useState('');
	const [replyToAddressError, setReplyToAddressError] = useState('');

	const studioMarketing = useAppSelector(studioMarketingSelector);

	const { isOpen, handleToggle } = useToggleSection(true);

	const dispatch = useAppDispatch();

	const handleChangeSenderName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		if (!value) {
			return setSenderNameError(requiredFieldMessage);
		}

		if (value.length > maxEmailLength) {
			return setSenderNameError('Sender name must be max 100 characters.');
		}

		const updatedField: IPatchBody = {
			value,
			op: 'replace',
			path: 'emailSenderName',
		};

		const body: IPatchBody[] = [updatedField];

		void dispatch(updateStudioMarketingAsync(body));
	};

	const clearSenderNameError = () => {
		if (!senderNameError) return;

		setSenderNameError('');
	};

	const handleChangeReplyToAddress = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const emailValidationMessage = validateEmail(value);

		if (emailValidationMessage) {
			return setReplyToAddressError(emailValidationMessage);
		}

		const updatedField: IPatchBody = {
			value,
			op: 'replace',
			path: 'replyToEmailAddress',
		};

		const body: IPatchBody[] = [updatedField];

		void dispatch(updateStudioMarketingAsync(body));
	};

	const clearReplyToAddressError = () => {
		if (!replyToAddressError) return;

		setReplyToAddressError('');
	};

	const initialSenderName = studioMarketing?.emailSenderName ?? '';
	const initialReplyToAddress = studioMarketing?.replyToEmailAddress ?? '';

	return (
		<ToggleSection
			isOpen={isOpen}
			title="Email Settings"
			handleToggle={handleToggle}
			className="marketing-header"
		>
			<div className="marketing-wrapper">
				<p className="marketing-text">
					All marketing broadcasts will be sent from noreply@accessmyevent.com.
					The name displayed in your consumer’s inbox will be the sender name
					below. Any consumer reply, will be sent to the “reply to” address
					listed below. This should be an inbox you monitor regularly.
				</p>
				<div className="marketing-form">
					<InputGrid
						isLazy
						touched
						label="Sender Name:"
						error={senderNameError}
						placeholder="Sender Name"
						defaultValue={initialSenderName}
						clearError={clearSenderNameError}
						handleLazyChange={handleChangeSenderName}
					/>
					<InputGrid
						isLazy
						touched
						error={replyToAddressError}
						label="“Reply to” Address:"
						placeholder="Email Address"
						defaultValue={initialReplyToAddress}
						clearError={clearReplyToAddressError}
						handleLazyChange={handleChangeReplyToAddress}
					/>
				</div>
			</div>
		</ToggleSection>
	);
};
