import { FC } from 'react';

import { subscriptionRowLabels } from 'constants/subscriptions/subscriptionRowLabels';

export const TableRowNames: FC = () => {
	const TableRowNamesList = subscriptionRowLabels.map(
		({ label, isBold, sublabel }) => {
			if (isBold) {
				return (
					<li key={label}>
						<b>{label}</b> {sublabel && <span>{sublabel}</span>}
					</li>
				);
			}

			return (
				<li key={label}>
					{label} {sublabel && <span>{sublabel}</span>}
				</li>
			);
		}
	);

	return <ul className="panel-names">{TableRowNamesList}</ul>;
};
