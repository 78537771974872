import { FC, ChangeEvent } from 'react';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';

import { bankACHValidationSchema } from 'pages/RegistrationSteps/SetupAccount/validations';
import { IBankAccountValues } from 'pages/RegistrationSteps/SetupAccount/types';

import StudioBillingService from 'api/services/StudioBillingService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { validateStringNumber } from 'utils/validations/general/validateStringNumber';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { ButtonTypes } from 'types/ui/buttonTypes';
import { setBankACH } from 'store/studioBilling';

interface IUpdateBankAccountProps {
	hideUpdateBankAccModal: () => void;
}

interface IField {
	label: string;
	placeholder: string;
	id: keyof IBankAccountValues;
}

const fields: IField[] = [
	{
		id: 'bankRoutingNumber',
		label: 'Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankRoutingNumber',
		label: 'Confirm Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'bankAccountNumber',
		label: 'Account #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankAccountNumber',
		label: 'Confirm Account #',
		placeholder: 'Placeholder',
	},
];

export const UpdateBankAccount: FC<IUpdateBankAccountProps> = ({
	hideUpdateBankAccModal,
}) => {
	const initialValues: IBankAccountValues = {
		bankAccountName: '',
		bankRoutingNumber: '',
		bankAccountNumber: '',
		confirmBankRoutingNumber: '',
		confirmBankAccountNumber: '',
	};

	const dispatch = useAppDispatch();

	const onSubmit = async (
		values: IBankAccountValues,
		{ setErrors }: FormikHelpers<IBankAccountValues>
	) => {
		const { confirmBankAccountNumber, confirmBankRoutingNumber, ...body } =
			values;

		try {
			const data = await StudioBillingService.updateBankACHInformation(body);

			dispatch(setBankACH(data));

			hideUpdateBankAccModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.ValidationError) {
				const updatedErrors = Object.entries(errors).reduce<
					FormikErrors<IBankAccountValues>
				>(
					(acc, [key, messages]) => ({
						...acc,
						[key]: messages[0],
					}),
					{}
				);

				setErrors(updatedErrors);
			}
		}
	};

	const handleValidateNumber = (
		e: ChangeEvent<HTMLInputElement>,
		formikHandleChange: (e: ChangeEvent<HTMLInputElement>) => void
	) => {
		const isValidNumber = validateStringNumber(e.target.value);

		if (!isValidNumber) return;

		formikHandleChange(e);
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={bankACHValidationSchema}
		>
			{({ handleChange, values, errors, touched }) => (
				<Form className="modal-body accing-modal">
					<div className="accing-modal-form">
						<div className="accing-modal-input">
							<InputGrid
								id="bankAccountName"
								placeholder="Placeholder"
								handleChange={handleChange}
								label="Account Holder’s Name"
								error={errors.bankAccountName}
								value={values.bankAccountName}
								touched={touched.bankAccountName}
							/>
						</div>
						{fields.map(({ id, label, placeholder }) => (
							<InputGrid
								id={id}
								key={id}
								label={label}
								error={errors[id]}
								value={values[id]}
								touched={touched[id]}
								placeholder={placeholder}
								handleChange={(e) => handleValidateNumber(e, handleChange)}
							/>
						))}
					</div>
					<div className="accing-modal-btns">
						<Button
							className="btn-primary"
							type={ButtonTypes.SUBMIT}
							value="Update Account On File"
						/>
						<Button
							value="Back"
							className="btn-secondary"
							handleClick={hideUpdateBankAccModal}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};
