export const subscriptionRowLabels = [
	{ label: 'Monthly price', isBold: true },
	{ label: 'Studio fulfill service fee*', isBold: true },
	{ label: 'Auto fulfill service fee*', isBold: true },
	{ label: 'Image Storage', isBold: true, sublabel: '(# of images)' },
	{ label: 'Exceeded Max Storage', sublabel: '(per extra image)' },
	{ label: 'Additional Users' },
	{ label: 'Free email & text (sms) marketing' },
	{ label: 'Price lists' },
	{ label: 'Automated marketing funnel' },
	{ label: 'Custom campaigns' },
	{ label: 'Image download delivery' },
	{ label: 'Upload via web, H&H Color Lab, hhschools' },
	{ label: 'H&H Color Lab integration' },
];
