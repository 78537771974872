import { FC } from 'react';

import { useAccUserBreadcrumbs } from './hooks/useAccUserBreadcrumbs';
import { AccountSettings } from './components/AccountSettings';
import { UserSettings } from './components/UserSettings';
import { ManageTeam } from './components/ManageTeam';

export const AccountUser: FC = () => {
	useAccUserBreadcrumbs();

	return (
		<div className="acc-user-container">
			<UserSettings />
			<AccountSettings />
			<ManageTeam />
		</div>
	);
};
