import { CellProps, Column } from 'react-table';
import {
	FC,
	useRef,
	useMemo,
	useState,
	useEffect,
	useCallback,
	ChangeEvent,
} from 'react';

import { uploadInputAccept } from 'constants/images/validation/imageUploadTypes';
import { AlbumsSortLabels } from 'constants/albums/albumsSortLabels';
import { AlbumsActionMenu } from 'constants/albums/albumsActionMenu';
import { SCV_FORMAT } from 'constants/general/csvFormat';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { Agreement } from 'components/Modal/components/Agreement';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { NoItemsFound } from 'components/NoItemsFound';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';
import { Table } from 'components/Table';

import { IAlbum } from 'api/models/responses/albums/album';
import AlbumService from 'api/services/AlbumService';
import PhotoService from 'api/services/PhotoService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { pngType } from 'utils/validations/general/validateImageFile';
import { useActionMenu } from 'hooks/useActionMenu';

import { UploadImagesProgress } from './components/UploadImagesProgress';
import { IAlbumUI, IAlbumsProps, IUpdateAlbumValues } from './types';
import { AlbumControls } from './components/AlbumControls';
import { AlbumPreviews } from './components/AlbumPreviews';
import { useSortAlbums } from '../../hooks/useSortAlbums';
import { UpdateAlbum } from './components/UpdateAlbum';

export const Albums: FC<IAlbumsProps> = ({
	albumsUI,
	isPending,
	projectKey,
	setAlbumsUI,
	isDeletedPhotos,
	setUpdatePhotos,
	setUpdateAlbums,
	isPngUploadAllowed,
	resetSearchPhotosParams,
}) => {
	const [albumId, setAlbumId] = useState<string | null>(null);
	const [updateAlbumInitial, setUpdateAlbumInitial] =
		useState<IUpdateAlbumValues | null>(null);
	const [albumName, setAlbumName] = useState<string | null>(null);

	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [uploadedImages, setUploadedImages] = useState<File[]>([]);
	const [uploadAccessCodesError, setUploadAccessCodesError] = useState('');

	const confirmAddingAccessCodesModalRef = useRef<ModalRef>(null);
	const rejectUploadAccessCodesModalRef = useRef<ModalRef>(null);
	const uploadImagesProgressModalRef = useRef<ModalRef>(null);
	const uploadImagesInputRef = useRef<HTMLInputElement>(null);
	const pngUploadRejectModalRef = useRef<ModalRef>(null);
	const confirmDeleteModalRef = useRef<ModalRef>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const fileErrorModalRef = useRef<ModalRef>(null);
	const updateAlbumRef = useRef<ModalRef>(null);

	const { orderBy, sortedAlbums, setSortParams, orderDirection } =
		useSortAlbums(albumsUI);

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const showUpdateAlbumModal = () => {
		updateAlbumRef?.current?.open();
	};

	const hideUpdateAlbumModal = () => {
		updateAlbumRef?.current?.close();
	};

	const hideFileErrorModal = () => {
		fileErrorModalRef?.current?.close();
	};

	const showUploadImagesProgressModal = () => {
		uploadImagesProgressModalRef?.current?.open();
	};

	const hideUploadImagesProgressModal = () => {
		uploadImagesProgressModalRef?.current?.close();
	};

	const showConfirmDeleteModal = () => {
		confirmDeleteModalRef?.current?.open();
	};

	const hideConfirmDeleteModal = () => {
		confirmDeleteModalRef?.current?.close();
	};

	const showConfirmAddingAccessCodesModal = () => {
		confirmAddingAccessCodesModalRef?.current?.open();
	};

	const hideConfirmAddingAccessCodesModal = () => {
		confirmAddingAccessCodesModalRef?.current?.close();
	};

	const showRejectUploadAccessCodesModal = () => {
		rejectUploadAccessCodesModalRef?.current?.open();
	};

	const hideRejectUploadAccessCodesModal = () => {
		rejectUploadAccessCodesModalRef?.current?.close();
	};

	const showPngUploadRejectModal = () => {
		pngUploadRejectModalRef?.current?.open();
	};

	const hidePngUploadRejectModal = () => {
		pngUploadRejectModalRef?.current?.close();
	};

	const handleCreateAlbum = () => {
		setAlbumId(null);
		setUpdateAlbumInitial({ name: '', password: '' });
		showUpdateAlbumModal();
	};

	const uploadPassword = useCallback(async () => {
		try {
			if (!projectKey || !uploadedFile) return;

			await PhotoService.uploadPasswords(projectKey, uploadedFile);

			showConfirmAddingAccessCodesModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const { file: fileError } = errors;

				const parsedError =
					typeof fileError === 'string' ? fileError : fileError[0];

				showRejectUploadAccessCodesModal();
				setUploadAccessCodesError(parsedError);
			}
		}
	}, [uploadedFile]);

	const handleDelete = async () => {
		if (!albumId) return;

		const updatedAlbums = albumsUI.filter((album) => album.id !== albumId);

		setAlbumsUI(updatedAlbums);
		hideConfirmDeleteModal();

		try {
			await AlbumService.deleteAlbum(albumId, projectKey);
		} catch (error) {
			console.log(error);
		}

		setAlbumId(null);
		setAlbumName(null);
	};

	const handleActionMenuClick = (
		{ id, name, password }: IAlbum,
		menuItem: AlbumsActionMenu
	) => {
		setAlbumId(id);

		switch (menuItem) {
			case AlbumsActionMenu.UploadImages:
				uploadImagesInputRef?.current?.click();
				break;

			case AlbumsActionMenu.EditDetails:
				setUpdateAlbumInitial({ name, password: password || '' });
				showUpdateAlbumModal();
				break;

			case AlbumsActionMenu.DeletePermanently: {
				setAlbumName(name);
				showConfirmDeleteModal();
				break;
			}

			default:
				break;
		}
	};

	const handleUploadImages = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		const uploadImagesInput = uploadImagesInputRef.current;

		if (!files || !uploadImagesInput) return;

		const filesArray = Array.from(files);

		const isPngExists = filesArray.some((file) => file.type === pngType);

		if (!isPngUploadAllowed && isPngExists) {
			showPngUploadRejectModal();
			return;
		}

		setUploadedImages(Array.from(files));

		showUploadImagesProgressModal();

		uploadImagesInput.value = '';
	};

	const handleUploadImagesAccessCodeScv = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const file = e.target.files?.[0];
		const fileInput = fileInputRef.current;

		if (!file || !fileInput) return;

		if (file.type !== SCV_FORMAT) {
			fileErrorModalRef?.current?.open();
			return;
		}

		setUploadedFile(file);

		fileInput.value = '';
	};

	const columns: Column<IAlbumUI>[] = useMemo(
		() => [
			{
				id: 'album',
				Cell: ({ row }: CellProps<IAlbumUI>) => {
					const {
						firstSmallThumbnailUrl,
						secondSmallThumbnailUrl,
						thirdSmallThumbnailUrl,
					} = row.original;

					return (
						<AlbumPreviews
							firstSmallThumbnailUrl={firstSmallThumbnailUrl}
							secondSmallThumbnailUrl={secondSmallThumbnailUrl}
							thirdSmallThumbnailUrl={thirdSmallThumbnailUrl}
						/>
					);
				},
			},
			{
				accessor: 'name',
				Header: (
					<div className="tr-center">
						<ColumnLabelContainer
							label="Group"
							orderBy={orderBy}
							setSortParams={setSortParams}
							orderDirection={orderDirection}
							sortLabel={AlbumsSortLabels.Group}
						/>
					</div>
				),
			},
			{
				accessor: 'password',
				Header: (
					<div className="tr-center">
						<ColumnLabelContainer
							orderBy={orderBy}
							label="Access Code"
							setSortParams={setSortParams}
							orderDirection={orderDirection}
							sortLabel={AlbumsSortLabels.AccessCode}
						/>
					</div>
				),
			},
			{
				Header: (
					<div className="tr-center">
						<ColumnLabelContainer
							label="Images"
							orderBy={orderBy}
							setSortParams={setSortParams}
							orderDirection={orderDirection}
							sortLabel={AlbumsSortLabels.Images}
						/>
					</div>
				),
				accessor: 'imagesCount',
			},
			{
				accessor: 'id',
				Cell: ({ row }: CellProps<IAlbumUI>) => {
					const { original } = row;

					return (
						<CellActionMenu
							handleMenuItemClick={(value) => {
								handleActionMenuClick(original, value);
							}}
							menuList={Object.values(AlbumsActionMenu)}
							showActionMenu={actionMenuId === original.id}
							setActiveActionMenu={() => setActionMenuId(original.id)}
						/>
					);
				},
			},
		],
		[sortedAlbums, actionMenuId]
	);

	useEffect(() => {
		void uploadPassword();
	}, [uploadPassword]);

	useEffect(() => {
		if (!isDeletedPhotos) return;

		setUpdateAlbums(true);
	}, [isDeletedPhotos]);

	if (isPending) {
		return <Loader />;
	}

	return (
		<>
			{albumsUI.length ? (
				<div className="org-project-search-container">
					<Table
						isEqual
						columns={columns}
						data={sortedAlbums}
						className="org-table project-album-table table-action-menu"
					/>
				</div>
			) : (
				<NoItemsFound title="groups" />
			)}
			<AlbumControls
				fileInputRef={fileInputRef}
				handleCreateAlbum={handleCreateAlbum}
				handleUpload={handleUploadImagesAccessCodeScv}
			/>
			<input
				multiple
				type="file"
				accept={uploadInputAccept}
				ref={uploadImagesInputRef}
				style={{ display: 'none' }}
				onChange={handleUploadImages}
			/>
			<Modal
				ref={updateAlbumRef}
				title={albumId ? 'Edit Group Details' : 'Create a New Group'}
				subtitle={albumId ? 'Update the details for this group.' : ''}
			>
				<UpdateAlbum
					albumKey={albumId}
					albumsUI={albumsUI}
					projectKey={projectKey}
					setAlbumsUI={setAlbumsUI}
					hideUpdateAlbumModal={hideUpdateAlbumModal}
					initialName={updateAlbumInitial?.name || ''}
					initialPassword={updateAlbumInitial?.password || ''}
				/>
			</Modal>
			<Modal
				ref={fileErrorModalRef}
				title="Wrong Type of File"
				subtitle="Please select a valid .csv file to upload Image Access Code."
			>
				<Agreement handleAgreement={hideFileErrorModal} />
			</Modal>
			<Modal
				title="Wrong CSV file format"
				subtitle={uploadAccessCodesError}
				ref={rejectUploadAccessCodesModalRef}
			>
				<Agreement handleAgreement={hideRejectUploadAccessCodesModal} />
			</Modal>
			<Modal
				title="Uploading your Images"
				ref={uploadImagesProgressModalRef}
				subtitle="Please keep this window open while we upload your images. Once upload is complete, click OK to proceed."
			>
				<UploadImagesProgress
					albumGuid={albumId}
					images={uploadedImages}
					projectKey={projectKey}
					setUpdateAlbums={setUpdateAlbums}
					setUpdatePhotos={setUpdatePhotos}
					resetSearchPhotosParams={resetSearchPhotosParams}
					hideUploadImagesProgressModal={hideUploadImagesProgressModal}
				/>
			</Modal>
			<Modal
				title="Delete Group"
				ref={confirmDeleteModalRef}
				subtitle={`Are you sure you would like to delete ${albumName || ''}?`}
			>
				<ConfirmDelete
					handlePermanentlyDelete={handleDelete}
					hideConfirmDeleteModal={hideConfirmDeleteModal}
				/>
			</Modal>
			<Modal
				title="Adding your Access Codes"
				ref={confirmAddingAccessCodesModalRef}
				subtitle="This list of access codes is being added to images in this project."
			>
				<Agreement handleAgreement={hideConfirmAddingAccessCodesModal} />
			</Modal>
			<Modal
				ref={pngUploadRejectModalRef}
				title="Upload .png images isn't allowed."
			>
				<Agreement handleAgreement={hidePngUploadRejectModal} />
			</Modal>
		</>
	);
};
