import { object, Schema, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	emailRegEx,
	maxEmailLength,
	invalidEmailMessage,
	maxEmailLengthMessage,
} from 'constants/general/validation/email';

import { IChangeEmailBody } from 'api/models/requests/account/changeEmailBody';

export const changeEmailValidationSchema = object().shape<
	Partial<Record<keyof IChangeEmailBody, Schema>>
>({
	email: string()
		.required(requiredFieldMessage)
		.matches(emailRegEx, invalidEmailMessage)
		.max(maxEmailLength, maxEmailLengthMessage),

	verificationCode: string().required(requiredFieldMessage),
});
