import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseTwoDigitYear } from 'moment';

import { IStudioContactInfoBody } from 'api/models/requests/studio/studioContactInfoBody';
import { ICardInfoBody } from 'api/models/requests/studioBilling/cardInfoBody';
import { ICreateStudioBody } from 'api/models/requests/studio/createStudio';
import StudioService from 'api/services/StudioService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { useRegistrationStepsStatus } from 'hooks/auth/useRegistrationStepsStatus';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { Button } from 'components/FormControls/Button';
import { Loader } from 'components/Loader';

import { Payments } from 'types/ui/payments';
import { States } from 'types/ui/states';

import { splitCamelCaseString } from 'utils/general/splitCamelCaseString';
import { ROUTES } from 'constants/ROUTES';
import { setStudio } from 'store/studio';

import { BillingInformation } from './components/BillingInformation';
import { StudioInformation } from './components/StudioInformation';
import { createStudioValidationSchema } from './validations';
import { SideWrapper } from '../components/SideWrapper';
import { StepHeader } from '../components/StepHeader';

export interface ICardInfoValues
	extends Omit<ICardInfoBody, 'expireMonth' | 'expireYear'> {
	expiration: string;
}

export interface ICreateStudioValues extends ICardInfoValues {
	zip: string;
	city: string;
	phone: string;
	street: string;
	studioName: string;
	state: States | null;
	networkDomain: string;
}

export const CreateStudio: FC = () => {
	const [loading, setLoading] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { isStudioCreated } = useRegistrationStepsStatus();
	const { showErrorsToastify } = useToastify();

	const initialValues: ICreateStudioValues = {
		studioName: '',
		phone: '',
		street: '',
		city: '',
		zip: '',
		cardNumber: '',
		expiration: '',
		verificationCode: '',
		billingZip: '',
		cardType: Payments.VISA,
		state: null,
		networkDomain: '',
	};

	const onSubmit = async (
		values: ICreateStudioValues,
		{ setErrors }: FormikHelpers<ICreateStudioValues>
	) => {
		setLoading(true);

		try {
			const {
				zip,
				city,
				state,
				phone,
				street,
				cardType,
				studioName,
				billingZip,
				expiration,
				cardNumber,
				networkDomain,
				verificationCode,
			} = values;

			if (!state) return;

			const studioContactInfo: IStudioContactInfoBody = {
				zip,
				city,
				state,
				phone,
				street,
				website: networkDomain,
			};

			const [expireMonth, expireYear] = expiration.split('/');

			const fullYear = parseTwoDigitYear(expireYear);

			const billingCardInfo: ICardInfoBody = {
				cardType,
				cardNumber,
				billingZip,
				verificationCode,
				expireYear: fullYear,
				expireMonth: +expireMonth,
			};

			const createStudioBody: ICreateStudioBody = {
				studioName,
				networkDomain,
				billingCardInfo,
				studioContactInfo,
			};

			const studio = await StudioService.createStudio(createStudioBody);

			dispatch(setStudio(studio));
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type !== ErrorTypes.ValidationError) {
				const updatedToastifyError = {
					type: splitCamelCaseString(type),
					errors,
				};

				showErrorsToastify(updatedToastifyError);
			}

			const updatedErrorMessages = Object.entries(errors).reduce<
				FormikErrors<ICreateStudioValues>
			>((acc, [key, messages]) => {
				const keyExist = key in values;

				const message = messages[0] ?? '';

				if (key === 'expireYear' || key === 'expireMonth') {
					return {
						...acc,
						expiration: message,
					};
				}

				if (!keyExist) return acc;

				return {
					...acc,
					[key]: message,
				};
			}, {});

			setErrors(updatedErrorMessages);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!isStudioCreated) return;

		navigate(ROUTES.STUDIO_STEPS.SELECT_PLAN);
	}, [isStudioCreated]);

	return (
		<>
			{/* TODO: add absolute styles for loader or create new loader component that will use absolute positioning */}
			{loading && <Loader isFullHeight={true} />}
			<div className="studio-container">
				<Formik
					onSubmit={onSubmit}
					initialValues={initialValues}
					validationSchema={createStudioValidationSchema}
				>
					{({ handleSubmit }) => (
						<Form className="panel">
							<StepHeader title="Create a New Studio" stepCount="Step 1 of 3" />
							<div className="form-wrapper">
								<h2 className="studio-title">Studio Information</h2>
								<StudioInformation />
								<h2 className="studio-title">Billing Information</h2>
								<BillingInformation />
							</div>
							<Button
								value="Create Studio"
								handleClick={handleSubmit}
								className="studio-btn btn-primary"
							/>
						</Form>
					)}
				</Formik>
				<SideWrapper />
			</div>
		</>
	);
};
