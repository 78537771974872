import { string, number, date } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { IDatesLabels } from 'constants/projects/timeline/journeyTimelines';
import {
	projectUrlValidationRegEx,
	projectUrlValidationMessage,
	maxAccessCodeMessageLength,
	accessCodeMessageErrorMessage,
	maxSmsSpecialMessageLength,
	smsSpecialMessageErrorMessage,
	maxEmailSpecialMessageLength,
	emailSpecialMessageErrorMessage,
	estimatedNumberOfSubjectsUpLimit,
	maxOrderConfirmationMessageLength,
	orderConfirmationMessageErrorMessage,
	estimatedNumberOfSubjectsBottomLimit,
	estimatedNumberOfSubjectsValidationMessage,
} from 'constants/projects/validation/generalProjectFields';
import {
	emailRegEx,
	maxEmailLength,
	invalidEmailMessage,
	maxEmailLengthMessage,
} from 'constants/general/validation/email';
import {
	nameValidationMessage,
	organizationNameRegEx,
} from 'constants/organizations/validation';

import { getDateErrorMessage } from '../../validation';
import { TestContextExtended } from '../../types';
import { ICreateBlankProjectValues } from '.';

export interface IValidationOptions {
	datesLabels: IDatesLabels;
	isMarketingEnabled: boolean;
	validatePriceListSale1: boolean;
	validatePriceListSale2: boolean;
	validatePriceListSale3: boolean;
	validateLastPictureDate: boolean;
	validateFirstPictureDate: boolean;
}

export const createBlankProjectStep1Scheme = {
	journey: string().required(requiredFieldMessage),
	projectName: string()
		.required(requiredFieldMessage)
		.matches(organizationNameRegEx, nameValidationMessage),
	projectUrl: string()
		.required(requiredFieldMessage)
		.matches(projectUrlValidationRegEx, projectUrlValidationMessage),
	projectType: string().required(requiredFieldMessage),
	estimatedNumberOfSubjects: number()
		.typeError(estimatedNumberOfSubjectsValidationMessage)
		.required(requiredFieldMessage)
		.min(
			estimatedNumberOfSubjectsBottomLimit,
			estimatedNumberOfSubjectsValidationMessage
		)
		.max(
			estimatedNumberOfSubjectsUpLimit,
			estimatedNumberOfSubjectsValidationMessage
		),
};

const getPriceListValidation = (validate: boolean) =>
	validate ? string().required(requiredFieldMessage) : string().nullable();

const getMarketingValidation = (validate: boolean) =>
	validate ? number().required(requiredFieldMessage) : number().nullable();

const notRequiredFieldValidation = string().notRequired();

export const getCreateBlankProjectStep2Scheme = ({
	datesLabels,
	isMarketingEnabled,
	validatePriceListSale1,
	validatePriceListSale2,
	validatePriceListSale3,
	validateLastPictureDate,
	validateFirstPictureDate,
}: IValidationOptions) => {
	const marketingValidation = getMarketingValidation(isMarketingEnabled);

	const {
		expireDateLabel,
		publishDateLabel,
		lastPictureDateLabel,
		firstPictureDateLabel,
	} = datesLabels;

	return {
		publishDate: date()
			.default(null)
			.required(requiredFieldMessage)
			.test('', '', (publishDate, context) => {
				const { parent } = context as TestContextExtended;
				const { expireDate, lastPictureDate, firstPictureDate } =
					parent as ICreateBlankProjectValues;

				if (!publishDate) return true;

				if (validateFirstPictureDate && firstPictureDate) {
					return publishDate >= firstPictureDate
						? context.createError(
								getDateErrorMessage(firstPictureDateLabel, false)
						  )
						: true;
				}

				if (
					validateLastPictureDate &&
					lastPictureDate &&
					lastPictureDateLabel
				) {
					return publishDate >= lastPictureDate
						? context.createError(
								getDateErrorMessage(lastPictureDateLabel, false)
						  )
						: true;
				}

				if (expireDate && publishDate >= expireDate) {
					return context.createError(
						getDateErrorMessage(expireDateLabel, false)
					);
				}

				return true;
			}),
		firstPictureDate: date()
			.default(null)
			.required(requiredFieldMessage)
			.test('', '', (firstPictureDate, context) => {
				const { parent } = context as TestContextExtended;
				const { publishDate, lastPictureDate, expireDate } =
					parent as ICreateBlankProjectValues;

				if (!firstPictureDate) return true;

				if (
					validateFirstPictureDate &&
					publishDate &&
					firstPictureDate <= publishDate
				) {
					return context.createError(getDateErrorMessage(publishDateLabel));
				}

				if (
					validateLastPictureDate &&
					lastPictureDate &&
					lastPictureDateLabel
				) {
					return firstPictureDate >= lastPictureDate
						? context.createError(
								getDateErrorMessage(lastPictureDateLabel, false)
						  )
						: true;
				}

				if (expireDate && firstPictureDate >= expireDate) {
					return context.createError(
						getDateErrorMessage(expireDateLabel, false)
					);
				}

				return true;
			}),
		lastPictureDate: validateLastPictureDate
			? date()
					.default(null)
					.required(requiredFieldMessage)
					.test('', '', (lastPictureDate, context) => {
						const { parent } = context as TestContextExtended;
						const { expireDate, publishDate, firstPictureDate } =
							parent as ICreateBlankProjectValues;

						if (!lastPictureDate) return true;

						if (
							(publishDate && lastPictureDate <= publishDate) ||
							(firstPictureDate && lastPictureDate <= firstPictureDate)
						) {
							return context.createError(
								getDateErrorMessage(
									`${publishDateLabel} and ${firstPictureDateLabel}`
								)
							);
						}

						if (expireDate && lastPictureDate >= expireDate) {
							return context.createError(
								getDateErrorMessage(expireDateLabel, false)
							);
						}

						return true;
					})
			: date().nullable(),
		expireDate: date()
			.default(null)
			.required(requiredFieldMessage)
			.test('', '', (expireDate, context) => {
				const { parent } = context as TestContextExtended;
				const { publishDate, firstPictureDate, lastPictureDate } =
					parent as ICreateBlankProjectValues;

				if (!publishDate || !expireDate) return true;

				if (
					expireDate <= publishDate ||
					(firstPictureDate && expireDate <= firstPictureDate)
				) {
					return context.createError(
						getDateErrorMessage(
							`${publishDateLabel} and ${firstPictureDateLabel}`
						)
					);
				}

				if (
					validateLastPictureDate &&
					lastPictureDate &&
					lastPictureDateLabel
				) {
					return expireDate <= lastPictureDate
						? context.createError(getDateErrorMessage(lastPictureDateLabel))
						: true;
				}

				return true;
			}),
		priceListSale1: getPriceListValidation(validatePriceListSale1),
		priceListSale2: getPriceListValidation(validatePriceListSale2),
		priceListSale3: getPriceListValidation(validatePriceListSale3),
		prospectCampaignKey: marketingValidation,
		leadCampaignKey: marketingValidation,
		buyerCampaignKey: marketingValidation,
		orderNotificationEmail: string()
			.notRequired()
			.max(maxEmailLength, maxEmailLengthMessage)
			.matches(emailRegEx, invalidEmailMessage),

		orderConfirmationMessage: string()
			.notRequired()
			.max(
				maxOrderConfirmationMessageLength,
				orderConfirmationMessageErrorMessage
			),

		accessCodeMessage: isMarketingEnabled
			? string()
					.notRequired()
					.max(maxAccessCodeMessageLength, accessCodeMessageErrorMessage)
			: notRequiredFieldValidation,

		smsSpecialMessage: isMarketingEnabled
			? string()
					.notRequired()
					.max(maxSmsSpecialMessageLength, smsSpecialMessageErrorMessage)
			: notRequiredFieldValidation,

		emailSpecialMessage: isMarketingEnabled
			? string()
					.notRequired()
					.max(maxEmailSpecialMessageLength, emailSpecialMessageErrorMessage)
			: notRequiredFieldValidation,
	};
};
