export const FIELDS_LENGTH = {
	XX_SMALL_LENGTH: 10,
	X_SMALL_LENGTH: 20,
	SMALL_LENGTH: 30,
	MEDIUM_LENGTH: 50,
	LARGE_LENGTH: 100,
	X_LARGE_LENGTH: 200,
	XX_LARGE_LENGTH: 500,
	XXX_LARGE_LENGTH: 1000,
};
