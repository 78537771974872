import { IPartialPhoneField } from 'components/W9InfoForm/types';

export const splitPhoneNumber = (phone: string): IPartialPhoneField => {
	if (phone.length !== 10) {
		return { firstPartPhone: '', secondPartPhone: '', thirdPartPhone: '' };
	}

	return {
		firstPartPhone: phone.slice(0, 3),
		secondPartPhone: phone.slice(3, 6),
		thirdPartPhone: phone.slice(6),
	};
};
