export enum CloudPhotoTypes {
	Main = 'Main',
	Service = 'Service',
	LargeThumbnail = 'LargeThumbnail',
	SmallThumbnail = 'SmallThumbnail',
	MediumThumbnail = 'MediumThumbnail',
	LargeThumbnailWithWatermark = 'LargeThumbnailWithWatermark',
	SmallThumbnailWithWatermark = 'SmallThumbnailWithWatermark',
	MediumThumbnailWithWatermark = 'MediumThumbnailWithWatermark',
}
