import { FC, MouseEvent } from 'react';

interface IOrgDeleteBtnProps {
	handleClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const OrgDeleteBtn: FC<IOrgDeleteBtnProps> = ({ handleClick }) => (
	<button className="org-cover-btn" onClick={handleClick}>
		<i className="org-cover-icon icon-delete" />
	</button>
);
