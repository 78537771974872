import { FC } from 'react';

import { DeleteControlColumn } from 'pages/PriceLists/components/DeleteControlColumn';

import { SaleTaxColumn } from './components/SaleTaxColumn';
import { AmountColumn } from './components/AmountColumn';
import { OptionColumn } from './components/OptionColumn';
import { IAdditionalChargeOptionsProps } from '..';

type SaleTaxChargeOptionProps = Pick<
	IAdditionalChargeOptionsProps,
	'isChargeSalesTax' | 'salesTaxPercent' | 'updatePriceListStudio'
>;

export const SaleTaxChargeOption: FC<SaleTaxChargeOptionProps> = ({
	salesTaxPercent,
	isChargeSalesTax,
	updatePriceListStudio,
}) => (
	<tbody className="price-option-tbody">
		<tr>
			<SaleTaxColumn
				isChargeSalesTax={isChargeSalesTax}
				updatePriceListStudio={updatePriceListStudio}
			/>
			<AmountColumn
				salesTaxPercent={salesTaxPercent}
				updatePriceListStudio={updatePriceListStudio}
			/>
			<OptionColumn />
			<DeleteControlColumn showPriceDeleteBtn={false} />
		</tr>
	</tbody>
);
