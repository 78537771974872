interface IImageDimensions {
	width: number;
	height: number;
}

export const getImageDimensions = (file: File): Promise<IImageDimensions> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			const img = new Image();

			img.onload = () => {
				const { width, height } = img;

				resolve({ width, height });
			};

			img.onerror = () => {
				reject(new Error('Error loading image'));
			};

			img.src = e.target?.result as string;
		};

		reader.onerror = () => {
			reject(new Error('Error loading image'));
		};

		reader.readAsDataURL(file);
	});
};
