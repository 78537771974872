import { IPaginationResponse } from 'api/models/responses/general/paginationResponse';
import { ISearchPhotosRequest } from 'api/models/requests/photos/searchPhotos';
import { ImageSasUrlResponse } from 'api/models/responses/photos/imageSasUrl';
import { CloudPhotoTypes } from 'api/models/responses/photos/cloudPhotoTypes';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IPhoto } from 'api/models/responses/photos/photo';

import { multipartHeaders } from 'constants/images/multipartHeaders';
import { photosUrls } from 'constants/apiUrls/photosUrls';

import { api } from 'config/api';

class PhotoService {
	async searchPhoto({
		skip,
		take,
		orderBy,
		projectKey,
		searchValue,
		orderDirection,
		searchParameter,
		searchByAlbumIds,
	}: ISearchPhotosRequest): Promise<IPaginationResponse<IPhoto>> {
		const { data } = await api.get<IPaginationResponse<IPhoto>>(
			photosUrls.searchPhoto,
			{
				params: {
					Skip: skip,
					Take: take,
					OrderBy: orderBy,
					ProjectKey: projectKey,
					SearchValue: searchValue,
					OrderDirection: orderDirection,
					SearchParameter: searchParameter,
					SearchByAlbumIds: searchByAlbumIds,
				},
			}
		);

		return data;
	}

	async getImageSasUrl(
		cloudImageId: string,
		photoType: CloudPhotoTypes
	): Promise<ImageSasUrlResponse> {
		const formData = new FormData();

		formData.set('ImageId', cloudImageId);
		formData.set('ImageVariantId', photoType);

		const { data } = await api.post<ImageSasUrlResponse>(
			photosUrls.getImageSasUrl,
			formData,
			{
				...multipartHeaders,
			}
		);

		return data;
	}

	async partialPhotoUpdate(
		photoKey: string,
		projectKey: number,
		body: IPatchBody[]
	): Promise<IPhoto> {
		const { data } = await api.patch<IPhoto>(
			photosUrls.getGeneralPhotoUrl(projectKey, photoKey),
			body
		);

		return data;
	}

	async deletePhoto(projectKey: number, photoKey: string): Promise<void> {
		await api.delete<boolean>(
			photosUrls.getGeneralPhotoUrl(projectKey, photoKey)
		);
	}

	async uploadPasswords(projectKey: number, file: File): Promise<void> {
		const formData = new FormData();

		formData.set('file', file);

		await api.post<string>(photosUrls.uploadPasswords(projectKey), formData, {
			...multipartHeaders,
		});
	}
}

export default new PhotoService();
