import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISubscription } from 'api/models/responses/subscriptions/subscription';

import { ISubscriptionsState } from './types';

const initialState: ISubscriptionsState = {
	subscriptions: [],
	subscription: null,
	activeSubscriptionKey: 0,
	initiallyShowUpgradePlanModal: false,
};

const subscriptionsSlice = createSlice({
	name: 'subscriptions',
	initialState,
	reducers: {
		setSubscription: (
			state,
			{ payload }: PayloadAction<ISubscription | null>
		) => {
			state.subscription = payload;
		},

		setSubscriptions: (state, { payload }: PayloadAction<ISubscription[]>) => {
			state.subscriptions = payload;
		},

		setActiveSubscriptionKey: (state, { payload }: PayloadAction<number>) => {
			state.activeSubscriptionKey = payload;
		},

		setInitiallyShowUpgradePlanModal: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.initiallyShowUpgradePlanModal = payload;
		},
	},
});

export const subscriptionsReducer = subscriptionsSlice.reducer;
export const {
	setSubscription,
	setSubscriptions,
	setActiveSubscriptionKey,
	setInitiallyShowUpgradePlanModal,
} = subscriptionsSlice.actions;
