import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SubscriptionNames } from 'api/models/responses/subscriptions/subscription';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import SubscriptionsService from 'api/services/SubscriptionsService';
import StudioService from 'api/services/StudioService';

import { useRegistrationStepsStatus } from 'hooks/auth/useRegistrationStepsStatus';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { Button } from 'components/FormControls/Button';
import { Loader } from 'components/Loader';

import { setStudio } from 'store/studio';
import {
	getSubscriptionsAsync,
	subscriptionsSelector,
	setActiveSubscriptionKey,
	activeSubscriptionKeySelector,
} from 'store/subscriptions';

import { ROUTES } from 'constants/ROUTES';

import { TableRowNames } from './components/TableRowLabels';
import { SideWrapper } from '../components/SideWrapper';
import { TableColumn } from './components/TableColumn';
import { StepHeader } from '../components/StepHeader';

export const SelectPlan: FC = () => {
	const subscriptions = useAppSelector(subscriptionsSelector);
	const activeSubscriptionLevelKey = useAppSelector(
		activeSubscriptionKeySelector
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { isSubscriptionSelected } = useRegistrationStepsStatus();
	const { showErrorsToastify } = useToastify();

	const [loading, setLoading] = useState(false);

	const selectSubscription = (subscriptionLevelKey: number) => {
		dispatch(setActiveSubscriptionKey(subscriptionLevelKey));
	};

	const handleChoosePlan = async () => {
		setLoading(true);

		try {
			await SubscriptionsService.setStudioSubscription(
				activeSubscriptionLevelKey
			);

			const data = await StudioService.getStudio();

			dispatch(setStudio(data));
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			const toastifyError = {
				type,
				errors,
			};

			showErrorsToastify(toastifyError);
		} finally {
			setLoading(false);
		}
	};

	const TableColumnsList = subscriptions.map((subscription) => (
		<TableColumn
			{...subscription}
			key={subscription.subscriptionLevelKey}
			selectSubscription={selectSubscription}
		/>
	));

	useEffect(() => {
		void dispatch(getSubscriptionsAsync());
	}, []);

	useEffect(() => {
		const standardSubscription = subscriptions.find(
			(subscription) => subscription.name === SubscriptionNames.Standard
		);

		if (!standardSubscription || activeSubscriptionLevelKey) return;

		dispatch(
			setActiveSubscriptionKey(standardSubscription.subscriptionLevelKey)
		);
	}, [subscriptions, activeSubscriptionLevelKey]);

	useEffect(() => {
		if (!isSubscriptionSelected) return;

		navigate(ROUTES.STUDIO_STEPS.GET_PAID);
	}, [isSubscriptionSelected]);

	if (loading) return <Loader isFullHeight={true} />;

	return (
		<div className="plan-container">
			<div className="plan-panel">
				<StepHeader title="Choose a Plan" stepCount="Step 2 of 3" />
				<h2 className="studio-title">
					Choose a plan that best suits your business needs
				</h2>
				<div className="plans">
					<div className="panel-plans">
						<TableRowNames />
						{TableColumnsList}
					</div>
				</div>
				<span className="plans-note">
					* Of transaction amount. Does not include 3% credit card processing
					fee.
				</span>
				<div className="plan-controls">
					<Button
						value="Choose Plan"
						handleClick={handleChoosePlan}
						className="plan-btn btn-primary"
					/>
				</div>
			</div>
			<SideWrapper />
		</div>
	);
};
