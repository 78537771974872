export const accountUrls = {
	getUsers: 'api/account/users',
	inviteUser: 'api/account/invite',
	changeEmail: 'api/account/change-email',
	getCurrentUser: 'api/account/current-user',
	uploadUserPhoto: 'api/account/upload-user-photo',
	deleteUserPhoto: 'api/account/delete-user-photo',
	accUserUrl: (userKey: string) => `api/account/${userKey}`,
	sendVerificationCode: 'api/account/send-verification-code',
};
