import cn from 'classnames';
import { FC } from 'react';

import { Input } from 'components/FormControls/Input';
import { IInputProps } from 'types/ui/inputProps';

interface IInputFiledProps extends IInputProps {
	error?: string;
	touched?: boolean;
	wrapperClassName?: string;
}

export const InputField: FC<IInputFiledProps> = ({
	id,
	type,
	label,
	value,
	error,
	touched,
	required,
	disabled,
	handleBlur,
	placeholder,
	handleChange,
	handleKeyDown,
	wrapperClassName,
}) => {
	const showError = error && touched;

	return (
		<div
			className={cn(wrapperClassName, {
				'wrapper-error': showError,
			})}
		>
			<Input
				id={id}
				type={type}
				label={label}
				value={value}
				disabled={disabled}
				required={required}
				handleBlur={handleBlur}
				placeholder={placeholder}
				handleChange={handleChange}
				handleKeyDown={handleKeyDown}
			/>
			{showError && <span className="input-error">{error}</span>}
		</div>
	);
};
