import { GeneralTabs } from 'types/generalTabs';
import { ITab } from 'types/tab';

export const priceListsTabsList: ITab<GeneralTabs>[] = [
	{ title: GeneralTabs.Active, id: GeneralTabs.Active },
	{
		title: `${GeneralTabs.Archived} (>60 Days)`,
		id: GeneralTabs.Archived,
	},
];
