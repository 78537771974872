import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const subscriptionsStateSelector = (state: RootState) => state.subscriptions;

export const subscriptionSelector = createSelector(
	subscriptionsStateSelector,
	({ subscription }) => subscription
);

export const subscriptionsSelector = createSelector(
	subscriptionsStateSelector,
	({ subscriptions }) => subscriptions
);

export const activeSubscriptionKeySelector = createSelector(
	subscriptionsStateSelector,
	({ activeSubscriptionKey }) => activeSubscriptionKey
);

export const initiallyShowUpgradePlanModalSelector = createSelector(
	subscriptionsStateSelector,
	({ initiallyShowUpgradePlanModal }) => initiallyShowUpgradePlanModal
);
