import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useModalControls } from 'hooks/useModalControls';

import { ToggleSection } from 'components/ToggleSection';
import { Modal } from 'components/Modal';

import { UserIcon } from 'pages/AccountUser/components/UserIcon';
import { getStudioContactInfoAsync } from 'store/studio';

import { StudioContactInfo } from './components/StudioContactInfo';
import { UpdateStudio } from './components/UpdateStudio';
import { StudioInfo } from './components/StudioInfo';

export const AccountSettings = () => {
	const { isOpen, handleToggle } = useToggleSection(true);

	const dispatch = useAppDispatch();

	const {
		modalRef: updateStudioModalRef,
		showModal: showUpdateStudioModal,
		hideModal: hideUpdateStudioModal,
	} = useModalControls();

	useEffect(() => {
		void dispatch(getStudioContactInfoAsync());
	}, []);

	return (
		<ToggleSection
			isOpen={isOpen}
			header={<UserIcon />}
			title="Account Settings"
			handleToggle={handleToggle}
			className="acc-user-header"
		>
			<div className="acc-settings">
				<StudioInfo showUpdateStudioModal={showUpdateStudioModal} />
				<StudioContactInfo />
			</div>
			<Modal
				ref={updateStudioModalRef}
				title="Update Studio"
				subtitle="Updating the domain will break any links sent in previous marketing broadcasts or QR codes. After updating, you will need to send new links and QR codes to your customers. This action cannot be undone."
			>
				<UpdateStudio hideUpdateStudioModal={hideUpdateStudioModal} />
			</Modal>
		</ToggleSection>
	);
};
