import { FC, useEffect } from 'react';

import { studioStatisticPriceKeys } from 'constants/dashboard/studioStatisticPriceKeys';
import { studioStatisticKeys } from 'constants/dashboard/studioStatisticKeys';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { setSelectedDateRange, studioStatisticSelector } from 'store/dashboard';

import { useDashboardBreadcrumbs } from './hooks/useDashboardBreadcrumbs';
import { DateRangeSelect } from './components/DateRangeSelect';
import { LatestProjects } from './components/LatestProjects';
import { OrderWaiting } from './components/OrderWaiting';
import { PanelData } from '../../components/PanelData';
import { ComingSoon } from './components/ComingSoon';
import { DidYouKnow } from './components/DidYouKnow';
import { NewOrders } from './components/NewOrders';
import { CreateNew } from './components/CreateNew';
import { Schedule } from './components/Schedule';

export const Dashboard: FC = () => {
	const studioStatistic = useAppSelector(studioStatisticSelector);

	useDashboardBreadcrumbs();

	const { isMobile } = useDefineDevice();
	const dispatch = useAppDispatch();

	useEffect(
		() => () => {
			dispatch(setSelectedDateRange(null));
		},
		[]
	);

	return (
		<div className="dashboard">
			<PanelData
				statistic={studioStatistic}
				statisticKeys={studioStatisticKeys}
				statisticPriceKeys={studioStatisticPriceKeys}
			>
				<DateRangeSelect />
			</PanelData>
			<div className="dashboard-thumb">
				{!isMobile && <Schedule />}
				<ComingSoon />
				<OrderWaiting />
				<NewOrders />
				<LatestProjects />
				<CreateNew />
				<DidYouKnow />
			</div>
		</div>
	);
};
