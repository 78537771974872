import {
	FC,
	useRef,
	useEffect,
	FocusEvent,
	KeyboardEvent,
	SyntheticEvent,
} from 'react';

import { IInputProps } from 'types/ui/inputProps';
import {
	EnterCode,
	regularKeyboardCodes,
} from 'constants/general/keyboardCodes';

interface ILazyTextAreaProps
	extends Omit<IInputProps, 'handleChange' | 'value' | 'type'> {
	cols?: number;
	rows?: number;
	defaultValue?: string;
	clearError?: (id: string) => void;
	handleFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
	handleChange?: (e: SyntheticEvent<HTMLTextAreaElement>) => void;
}

export const LazyTextArea: FC<ILazyTextAreaProps> = ({
	id,
	cols,
	rows,
	disabled,
	className,
	clearError,
	handleFocus,
	placeholder,
	defaultValue,
	handleChange,
}) => {
	// TODO: update clear error
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const checkChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
		const currentValue = e.currentTarget.value;

		const isEqualValue = defaultValue === currentValue;

		if (isEqualValue || !handleChange) return;

		handleChange(e);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
		const textArea = textAreaRef.current;

		if (!textArea || !id) return;

		const { code } = e;

		if (regularKeyboardCodes.includes(code) && clearError) {
			clearError(id);
		}

		if (code !== EnterCode) return;

		if (!e.shiftKey) {
			e.preventDefault();
		}

		checkChange(e);
	};

	useEffect(() => {
		const textArea = textAreaRef.current;

		if (!textArea || !defaultValue) return;

		textArea.value = defaultValue;
	}, [textAreaRef, defaultValue]);

	return (
		<textarea
			id={id}
			cols={cols}
			rows={rows}
			ref={textAreaRef}
			disabled={disabled}
			onBlur={checkChange}
			className={className}
			onFocus={handleFocus}
			placeholder={placeholder}
			onKeyDown={handleKeyDown}
			defaultValue={defaultValue}
		/>
	);
};
