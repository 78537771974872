import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { subscriptionSelector } from 'store/subscriptions';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

interface ISubscriptionInfoItem {
	label: string;
	isBold?: boolean;
	value?: string | null;
}

export const PlanInfoList: FC = () => {
	const subscription = useAppSelector(subscriptionSelector);

	const monthlyPayment =
		subscription &&
		normalizeDigit({
			isPrice: true,
			minimumFractionDigits: 2,
			value: subscription.monthlyPayment,
		});

	const maxImagesCount =
		subscription &&
		normalizeDigit({
			value: subscription.maxImageCount,
		});

	const studioFee = subscription?.studioFulfillServiceFeePercent ?? '';
	const autoFee = subscription?.autoFulfillServiceFeePercent ?? '';

	const subscriptionInfoItems: ISubscriptionInfoItem[] = [
		{ label: 'Plan:', value: subscription?.name, isBold: true },
		{ label: 'Subscription:', value: monthlyPayment },
		{
			label: 'Studio Fee:',
			value: `${studioFee}%`,
		},
		{
			label: 'Auto Fee:',
			value: `${autoFee}%`,
		},
		{ label: 'Images:', value: maxImagesCount },
	];

	const SubscriptionInfoItemsList = subscriptionInfoItems.map(
		({ label, value, isBold }) => (
			<li key={label} className="accing-item">
				{label}
				<span>{isBold ? <b>{value}</b> : value}</span>
			</li>
		)
	);

	return <ul className="accing-list">{SubscriptionInfoItemsList}</ul>;
};
