import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';
import { setBreadcrumbs } from 'store/breadcrumbs';
import { DASHBOARD } from 'constants/navigation';

export const useDashboardBreadcrumbs = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const dashboardNavigation =
			NavigationService.getParentNavigation(DASHBOARD);

		if (!dashboardNavigation) return;

		const dashboardBreadcrumbs = {
			isActive: true,
			path: dashboardNavigation.path,
			title: dashboardNavigation.title,
		};

		const breadcrumbs = [dashboardBreadcrumbs];

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [dispatch]);
};
