import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { loginRequest } from 'config/authConfig';
import { api } from 'config/api';

import { uploadPhotosBlobUrl } from 'constants/images/blobUrls';
import { useToastify } from 'hooks/useToastify';

interface IProps {
	children: ReactNode;
}

export const AxiosInterceptors: FC<IProps> = ({ children }) => {
	const { instance, accounts } = useMsal();

	const { showErrorToastify } = useToastify();

	const reqInterceptorFulfilled = useCallback(
		async (request: InternalAxiosRequestConfig) => {
			const { headers, url } = request;

			if (!headers || !url) return request;

			if (url.includes(uploadPhotosBlobUrl)) {
				return request;
			}

			const accessTokenRequest = {
				scopes: loginRequest.scopes,
				account: accounts[0],
			};

			const accessTokenResponse = await instance.acquireTokenSilent(
				accessTokenRequest
			);

			const accessToken = accessTokenResponse.accessToken;

			headers.Authorization = `Bearer ${accessToken}`;

			return request;
		},
		[instance, accounts]
	);

	const resInterceptorFulfilled = useCallback(
		(response: AxiosResponse) => response,
		[]
	);

	const resInterceptorEject = useCallback(async (error: AxiosError) => {
		if (error instanceof InteractionRequiredAuthError) {
			await instance.acquireTokenRedirect(loginRequest);
		}

		const statusCode = error.response?.status;

		switch (statusCode) {
			case 401: {
				await instance.loginRedirect(loginRequest);
				break;
			}

			case 413: {
				showErrorToastify({
					title: 'File is too large.',
					message: 'Please upload a smaller file.',
				});
				break;
			}

			default:
				break;
		}

		return Promise.reject(error.response?.data);
	}, []);

	useEffect(() => {
		const reqInterceptor = api.interceptors.request.use(
			reqInterceptorFulfilled
		);

		const resInterceptor = api.interceptors.response.use(
			resInterceptorFulfilled,
			resInterceptorEject
		);

		return () => {
			api.interceptors.request.eject(reqInterceptor);
			api.interceptors.response.eject(resInterceptor);
		};
	}, [reqInterceptorFulfilled, resInterceptorFulfilled, resInterceptorEject]);

	return <>{children}</>;
};
