import { IBaseGetDataReqParams } from '../general/baseGetDataReqParams';

export enum SortPromotionsLabels {
	Name = 'Name',
	Type = 'Type',
	Value = 'Value',
	NumberOfUses = 'NumberOfUses',
	ExpirationDate = 'ExpirationDate',
}

export interface IPaginatePromotionsPayload
	extends IBaseGetDataReqParams<SortPromotionsLabels> {
	isActive: boolean;
}
