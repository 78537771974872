import { SaleCondition } from 'api/models/requests/presets/createPresets';

import { CampaignsTarget } from '../campaigns/campaign';
import { TimeZones } from '../studio/timeZones';

export enum ProjectDateTypes {
	LoginDate = 'LoginDate',
	Christmas = 'Christmas',
	MothersDay = 'MothersDay',
	FathersDay = 'FathersDay',
	SpecialDate = 'SpecialDate',
	PublishDate = 'PublishDate',
	PurchaseDate = 'PurchaseDate',
	ValentinesDay = 'ValentinesDay',
	ExpirationDate = 'ExpirationDate',
	FirstPictureDay = 'FirstPictureDay',
	ThanksGivingDay = 'ThanksGivingDay',
	DatePersonAdded = 'DatePersonAdded',
}

export interface IBroadcast {
	days: number;
	time: string;
	replyTo: string;
	emailFrom: string;
	emailBody: string;
	smsMessage: string;
	senderName: string;
	timeZone: TimeZones;
	phoneNumber: string;
	campaignKey: number;
	emailSubject: string;
	broadcastKey: number;
	broadcastName: string;
	condition: SaleCondition;
	specialDate: string | null;
	campaignTarget: CampaignsTarget;
	projectDateType: ProjectDateTypes;
}
