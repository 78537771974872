import { object, Schema, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	emailRegEx,
	maxEmailLength,
	invalidEmailMessage,
	maxEmailLengthMessage,
} from 'constants/general/validation/email';
import {
	userLastNameMaxLength,
	userFirstNameMaxLength,
	userFirstNameMaxLengthMessage,
	userLastNameMaxLengthMessage,
} from 'constants/account/validation/userFullName';

import { IInviteUserBody } from 'api/models/requests/account/inviteUserBody';

export const inviteUserValidationSchema = object().shape<
	Partial<Record<keyof IInviteUserBody, Schema>>
>({
	firstName: string().max(
		userFirstNameMaxLength,
		userFirstNameMaxLengthMessage
	),

	lastName: string().max(userLastNameMaxLength, userLastNameMaxLengthMessage),

	email: string()
		.required(requiredFieldMessage)
		.matches(emailRegEx, invalidEmailMessage)
		.max(maxEmailLength, maxEmailLengthMessage),

	role: string().required(requiredFieldMessage),
});
