import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { FC, useEffect, useMemo } from 'react';
import moment from 'moment';

import { IMarketingStatistic } from 'api/models/responses/dashboard/marketingStatistic';

import { chartList, lineColors } from 'constants/dashboard/chart';
import { dateFormat } from 'constants/general/dates/dateFormat';
import { DateRanges } from 'constants/dashboard/dateRange';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Chart } from 'components/Chart';
import { Card } from 'components/Card';

import { normalizeDigit } from 'utils/ui/normalizeDigit';
import {
	selectedDateRangeSelector,
	getMarketingStatisticAsync,
	marketingStatisticSelector,
	isMarketingStatisticPendingSelector,
} from 'store/dashboard';

import { ChartList } from './ChartList';

interface IGroupMarketingStatistic {
	sends: number[];
	opens: number[];
	clicks: number[];
	logins: number[];
	orders: number[];
}

const initialGroupAcc: IGroupMarketingStatistic = {
	sends: [],
	opens: [],
	clicks: [],
	logins: [],
	orders: [],
};

export const Schedule: FC = () => {
	const marketingStatistic = useAppSelector(marketingStatisticSelector);
	const selectedDateRange = useAppSelector(selectedDateRangeSelector);
	const isMarketingStatisticPending = useAppSelector(
		isMarketingStatisticPendingSelector
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!selectedDateRange) return;

		void dispatch(getMarketingStatisticAsync(selectedDateRange));
	}, [selectedDateRange]);

	const sumGroupedMarketingStatisticFields = (
		groupedMarketingStatistic: IMarketingStatistic[][]
	) =>
		groupedMarketingStatistic.map((arr) => {
			return arr.reduce((acc, elem) => {
				if (!Object.keys(acc).length) return elem;

				return {
					date: elem.date,
					sends: acc.sends + elem.sends,
					opens: acc.opens + elem.opens,
					clicks: acc.clicks + elem.clicks,
					logins: acc.logins + elem.logins,
					orders: acc.orders + elem.orders,
					salesTotal: acc.salesTotal + elem.salesTotal,
				};
			}, {} as IMarketingStatistic);
		});

	const groupedMarketingStatisticByTerm = useMemo(() => {
		if (!selectedDateRange) return [];

		const { term } = selectedDateRange;

		switch (term) {
			case DateRanges.WEEK: {
				return marketingStatistic;
			}

			case DateRanges.MONTH: {
				const groupedMarketingStatisticByWeeks = marketingStatistic.reduce<
					IMarketingStatistic[][]
				>((acc, elem) => {
					const dayName = moment(elem.date).format('dddd');

					if (!acc.length) {
						return [[elem]];
					}

					if (dayName === 'Sunday') {
						acc[acc.length] = [elem];

						return acc;
					}

					acc[acc.length - 1].push(elem);

					return acc;
				}, []);

				return sumGroupedMarketingStatisticFields(
					groupedMarketingStatisticByWeeks
				);
			}

			case DateRanges.YEAR: {
				const groupedValuesByMonthObject = marketingStatistic.reduce<
					Record<string, IMarketingStatistic[]>
				>((acc, elem) => {
					const date = moment(elem.date).format(dateFormat);

					const monthIndex = date.split('-')[1];
					const yearIndex = date.split('-')[0];

					const key = `${yearIndex}/${monthIndex}`;

					const isExist = acc[key];

					if (!isExist) {
						return {
							...acc,
							[key]: [elem],
						};
					}

					return {
						...acc,
						[key]: [...acc[key], elem],
					};
				}, {});

				return sumGroupedMarketingStatisticFields(
					Object.values(groupedValuesByMonthObject)
				);
			}

			default:
				return [];
		}
	}, [marketingStatistic, selectedDateRange]);

	const salesTotals = groupedMarketingStatisticByTerm.map(
		({ salesTotal }) => salesTotal
	);

	const groupedMarketingStatistic = groupedMarketingStatisticByTerm.reduce(
		(acc, { sends, opens, clicks, logins, orders }) => ({
			sends: [...acc.sends, sends],
			opens: [...acc.opens, opens],
			clicks: [...acc.clicks, clicks],
			logins: [...acc.logins, logins],
			orders: [...acc.orders, orders],
		}),
		initialGroupAcc
	);

	const marketingStatisticEntries = Object.entries(groupedMarketingStatistic);
	const marketingStatisticValues = Object.values(groupedMarketingStatistic);

	const unitedMarketingStatisticValues = (
		marketingStatisticValues as number[][]
	).reduce<number[]>((acc, item) => [...acc, ...item], []);

	const maxSalesTotal = Math.max(...salesTotals);
	const minSalesTotal = Math.min(...[0, ...salesTotals]);

	const maxUnited = Math.max(...unitedMarketingStatisticValues);
	const minUnited = Math.min(...unitedMarketingStatisticValues);

	const lines = marketingStatisticEntries.map(([title, values]) => ({
		type: 'line' as const,
		label: title,
		borderColor: lineColors[title],
		borderWidth: 2,
		fill: false,
		data: values as number[],
		yAxisID: 'y',
	}));

	const bar = {
		type: 'bar' as const,
		label: 'Sales total',
		backgroundColor: '#76AD5A',
		borderRadius: 10,
		data: salesTotals,
		yAxisID: 'y1',
		barThickness: 50,
	};

	const chartData: ChartData<ChartType> = {
		labels: salesTotals.map(() => ''),
		datasets: [...lines, bar],
	};

	const ticks = {
		color: '#9e9faf',
		font: {
			family: 'avenir',
			size: 9,
		},
	};

	const chartOptions: ChartOptions<ChartType> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const label = context.dataset.label || '';
						const parsedY = context.parsed.y;

						if (context.dataset.yAxisID === 'y1') {
							return `${label}: $${parsedY}`;
						}

						return `${label}: ${parsedY}`;
					},
				},
			},
		},
		scales: {
			y: {
				type: 'linear',
				position: 'left',
				max: maxUnited,
				min: minUnited,
				ticks,
			},
			y1: {
				type: 'linear',
				position: 'right',
				max: maxSalesTotal,
				min: minSalesTotal,
				ticks: {
					...ticks,
					callback: (value: number | string) =>
						normalizeDigit({ value: +value, isPrice: true }),
				},
				grid: {
					display: false,
				},
			},
			x: {
				display: false,
			},
		},
	};

	const startMonthName = selectedDateRange?.startMonthName || '';
	const endMonthName = selectedDateRange?.endMonthName || '';
	const startDayIndex = selectedDateRange?.startDayIndex || '';
	const endDayIndex = selectedDateRange?.endDayIndex || '';

	const startMonthInfo = `${startMonthName} ${startDayIndex}`;
	const endMonthInfo = `${endMonthName} ${endDayIndex}`;

	const subtitle = `(${startMonthInfo} - ${endMonthInfo})`;

	const ChartsList = chartList.map((title) => (
		<li key={title} className={`chart-item ${title}`}>
			{title}
		</li>
	));

	return (
		<Card
			subtitle={subtitle}
			bodyClassName="chart"
			title="Sales + Marketing"
			columnFit="col-2 card-chart"
		>
			<div className="chart-container">
				{!isMarketingStatisticPending && (
					<Chart type="bar" data={chartData} options={chartOptions} />
				)}
			</div>
			<ChartList startMonth={startMonthInfo} endMonth={endMonthInfo}>
				{ChartsList}
			</ChartList>
		</Card>
	);
};
