import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';

import { validateStringNumber } from 'utils/validations/general/validateStringNumber';
import { InputField } from 'components/InputField';

import { IGetPaidFormValues } from '../../types';
import { Field } from './Field';

interface IFields {
	id: string;
	label: string;
	placeholder: string;
}

const fields: IFields[] = [
	{
		id: 'bankRoutingNumber',
		label: 'Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankRoutingNumber',
		label: 'Confirm Routing #',
		placeholder: 'Placeholder',
	},
	{
		id: 'bankAccountNumber',
		label: 'Account #',
		placeholder: 'Placeholder',
	},
	{
		id: 'confirmBankAccountNumber',
		label: 'Confirm Account #',
		placeholder: 'Placeholder',
	},
];

export const BankACHInformation: FC = () => {
	const { errors, values, touched, handleChange } =
		useFormikContext<IGetPaidFormValues>();

	const handleValidateNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidNumber = validateStringNumber(e.target.value);

		if (!isValidNumber) return;

		handleChange(e);
	};

	const FieldsList = fields.map(({ id, label, placeholder }) => (
		<Field
			id={id}
			key={id}
			label={label}
			error={errors[id]}
			touched={!!touched[id]}
			placeholder={placeholder}
			value={values[id] as string}
			handleValidateNumber={handleValidateNumber}
		/>
	));

	return (
		<div className="studio-form">
			<h4 className="studio-subtitle">
				This is the account we will payout your profits to.
			</h4>
			<InputField
				id="bankAccountName"
				placeholder="Placeholder"
				handleChange={handleChange}
				label="Account Holder’s Name"
				error={errors.bankAccountName}
				value={values.bankAccountName}
				touched={touched.bankAccountName}
				wrapperClassName="acc-holder-field"
			/>
			<span className="acc-holder-info">
				As it appears on your bank statement
			</span>
			<div className="studio-col-container">{FieldsList}</div>
		</div>
	);
};
