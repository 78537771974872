import { UserRoles } from 'api/models/requests/account/userRoles';

import { userRolesAccess } from '../userRolesAccess';
import { userRolesUI } from '../userRolesUI';

export const getUserRolesSelectOptions = (
	role: UserRoles,
	includeDescription?: boolean
) => {
	const userRoleAccess = userRolesAccess[role];

	return userRoleAccess.map((value) => ({
		value,
		label: includeDescription ? `${value} ${userRolesUI[value]}` : value,
	}));
};
