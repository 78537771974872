import { object, Schema, string, ref } from 'yup';

import { requiredStringSchema } from 'constants/general/validation/schemes/string';
import { emailSchema } from 'constants/general/validation/schemes/email';

import { ISendVerificationCodeValues } from '.';

export const sendVerificationCodeSchema = object().shape<
	Partial<Record<keyof ISendVerificationCodeValues, Schema>>
>({
	email: requiredStringSchema.concat(emailSchema),
	confirmEmail: string().oneOf([ref('email')], 'Emails must match'),
});
