import { OrderDirection } from 'api/models/requests/general/orderDirection';
import { PriceListsSortLabels } from './priceListsSortLabels';

export const priceListsSortOptions: Record<
	PriceListsSortLabels,
	OrderDirection
> = {
	[PriceListsSortLabels.Name]: OrderDirection.ASC,
	[PriceListsSortLabels.Fulfillment]: OrderDirection.ASC,
	[PriceListsSortLabels.ProductsCount]: OrderDirection.DESC,
	[PriceListsSortLabels.LastModifyDate]: OrderDirection.DESC,
	[PriceListsSortLabels.ActiveProjectsCount]: OrderDirection.DESC,
};
