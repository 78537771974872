import { Dispatch } from '@reduxjs/toolkit';

import DashboardService from 'api/services/DashboardService';
import OrdersService from 'api/services/OrdersService';

import { IDateRange } from './types';
import {
	setOrdersWaiting,
	setStudioStatistic,
	setMarketingStatistic,
	setIsMarketingStatisticPending,
} from './dashboardSlice';

export const getMarketingStatisticAsync =
	(selectedDateRange: IDateRange) => async (dispatch: Dispatch) => {
		dispatch(setIsMarketingStatisticPending(true));

		try {
			const data = await DashboardService.getMarketingStatistic(
				selectedDateRange.dateFrom,
				selectedDateRange.dateTo
			);

			dispatch(setMarketingStatistic(data));
		} catch (error) {
			console.log(error);
		} finally {
			dispatch(setIsMarketingStatisticPending(false));
		}
	};

export const getStudioStatisticAsync =
	(selectedDateRange: IDateRange) => async (dispatch: Dispatch) => {
		try {
			const data = await DashboardService.getStudioStatistic(
				selectedDateRange.dateFrom,
				selectedDateRange.dateTo
			);

			dispatch(setStudioStatistic(data));
		} catch (error) {
			console.log(error);
		}
	};

export const getOrdersWaitingAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await OrdersService.getOrdersWaiting({});

		dispatch(setOrdersWaiting(data));
	} catch (error) {
		console.log(error);
	}
};
