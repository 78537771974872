import { baseTextRegex } from 'constants/general/validation/generalRegEx';

interface IValidateDescriptionParams {
	value: string;
	minLength?: number;
	maxLength?: number;
	isRequired?: boolean;
}

export const validateDescription = ({
	value,
	minLength,
	isRequired,
	maxLength = 500,
}: IValidateDescriptionParams): string => {
	if (isRequired && !value) {
		return 'Description is required.';
	}

	if (value && !baseTextRegex.test(value)) {
		return 'There are some invalid characters in description.';
	}

	if (minLength && value.length < minLength) {
		return `Description must be at least ${minLength} characters.`;
	}

	if (value.length > maxLength) {
		return `Description must be less than or equal to ${maxLength} characters.`;
	}

	return '';
};
