import { object, Schema, string } from 'yup';

import { nameValidationMessage } from 'constants/organizations/validation';
import {
	requiredFieldMessage,
	invalidCharactersMessage,
} from 'constants/general/validation/generalMessages';
import {
	alphaNumericWithDashRegex,
	baseNameWithSpecialSymbolsRegex,
} from 'constants/general/validation/generalRegEx';
import {
	maxNetworkDomainLength,
	networkDomainLengthError,
} from 'constants/studio/validation/networkDomain';
import {
	maxStudioNameLength,
	studioNameLengthError,
} from 'constants/studio/validation/studioName';

import { UpdateStudioValues } from '.';

export const updateStudioValidationSchema = object().shape<
	Partial<Record<keyof UpdateStudioValues, Schema>>
>({
	studioName: string()
		.required(requiredFieldMessage)
		.max(maxStudioNameLength, studioNameLengthError)
		.matches(baseNameWithSpecialSymbolsRegex, nameValidationMessage),

	networkDomain: string()
		.required(requiredFieldMessage)
		.max(maxNetworkDomainLength, networkDomainLengthError)
		.matches(alphaNumericWithDashRegex, invalidCharactersMessage),
});
