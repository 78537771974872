export const dollarDecimalRegex = /^\$\d+\.?\d{0,2}$/;

export const decimalDigitsRegex = /^\d+\.?\d{0,2}$/;

export const alphaNumericRegex = /^[A-Za-z0-9 ]+$/;

export const alphaNumericWithoutSpacesRegex = /^[A-Za-z0-9]+$/;

export const alphaNumericWithDashRegex = /^[a-zA-Z0-9-]+$/;

export const baseNameRegex = /^(?![ '‘’`.&-])(?!.* $)[a-zA-Z0-9 '‘’`.&-]+$/;

export const baseNameWithSpecialSymbolsRegex =
	/^[a-zA-Z0-9][a-zA-Z0-9\s'‘’`.&-]*[a-zA-Z0-9'‘’`.&-]$/;

export const baseTextRegex =
	/^(?![\s'"‘’`.,?!&()/+-])(?!.* $)[a-zA-Z0-9\s'"‘’`.,?!&()/+-]+$/;

// range from 0 to 100 exclude 0
export const numberInIntegerRangeRegex = /^(100|[1-9]\d?)$/;
export const numberInDecimalRangeRegex = /^(100|[1-9]\d?(\.\d{1,2})?)$/;
