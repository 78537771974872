import { INavigation } from 'types/navigation';

import { ROUTES } from './ROUTES';

export const DASHBOARD = 'Dashboard';
export const ACTIVE_ORGANIZATIONS = 'Active Organizations';
export const ARCHIVED_ORGANIZATIONS = 'Archived Organizations';
export const PROJECTS = 'Projects';
export const MARKET_SELL = 'Market + Sell';
export const SETTINGS = 'Settings';

export const navigation: INavigation[] = [
	{
		title: DASHBOARD,
		subNavigationItems: [],
		path: ROUTES.DASHBOARD,
	},
	{
		title: PROJECTS,
		subNavigationItems: [
			{
				title: 'Active Organizations',
				path: ROUTES.ORGANIZATIONS.ACTIVE_ORGANIZATIONS,
			},
			{
				title: 'Archived Organizations',
				path: ROUTES.ORGANIZATIONS.ARCHIVED_ORGANIZATIONS,
			},
		],
		path: '',
	},
	{
		title: 'Orders',
		subNavigationItems: [],
		path: '',
	},
	{
		title: MARKET_SELL,
		subNavigationItems: [
			{
				title: 'Presets',
				path: ROUTES.PRESETS.PRESETS,
			},
			{
				title: 'Price Lists',
				path: ROUTES.PRICE_LISTS.PRICE_LISTS,
			},
			{
				title: 'Campaigns',
				path: ROUTES.CAMPAIGNS.CAMPAIGNS,
			},
			{
				title: 'Promotions',
				path: ROUTES.PROMOTIONS.PROMOTIONS,
			},
		],
		path: '',
	},
	{
		title: 'Reports',
		subNavigationItems: [],
		path: '',
	},
	{
		title: SETTINGS,
		subNavigationItems: [
			{
				title: 'Page + Branding',
				path: '',
			},
			{
				title: 'Accounting',
				path: ROUTES.ACCOUNTING,
			},
			{
				title: 'Account + User',
				path: ROUTES.ACC_USER,
			},
			{
				title: 'Marketing',
				path: ROUTES.MARKETING,
			},
			{
				title: 'Watermark + Media',
				path: ROUTES.WATERMARK_MEDIA.WATERMARK_MEDIA,
			},
		],
		path: '',
	},
];
