import { FC } from 'react';

interface ISaleTitleProps {
	id?: string;
	icon: string;
	title: string;
}

export const SaleTitle: FC<ISaleTitleProps> = ({ id, icon, title }) => (
	<div id={id} className="project-journey-item preset-journey-symbol">
		<i className={`project-journey-symbol ${icon}`} />
		<b>{title}</b>
	</div>
);
