import cn from 'classnames';
import { FC } from 'react';

import { ISubscription } from 'api/models/responses/subscriptions/subscription';

import { splitDecimalPricePart } from 'utils/general/splitDecimalPricePart';
import { normalizeDigit } from 'utils/ui/normalizeDigit';
import { normalizePrice } from 'utils/ui/normalizePrice';
import {
	activeSubscriptionKeySelector,
	subscriptionSelector,
} from 'store/subscriptions';
import { useAppSelector } from 'hooks/redux/useAppSelector';

interface TableColumnProps extends ISubscription {
	selectSubscription: (subscriptionLevelKey: number) => void;
}

export const TableColumn: FC<TableColumnProps> = ({
	name,
	maxImageCount,
	monthlyPayment,
	customCampaigns,
	additionalUsers,
	freeSmsMarketing,
	maxPriceListCount,
	selectSubscription,
	subscriptionLevelKey,
	exceededMaxStorageFee,
	autoFulfillServiceFeePercent,
	studioFulfillServiceFeePercent,
}) => {
	const activeSubscriptionLevelKey = useAppSelector(
		activeSubscriptionKeySelector
	);

	const currentSubscription = useAppSelector(subscriptionSelector);

	const isActive = activeSubscriptionLevelKey === subscriptionLevelKey;
	const isCurrentSubscription =
		currentSubscription?.subscriptionLevelKey === subscriptionLevelKey;

	const normalizedPrice = normalizePrice(monthlyPayment.toString(), 2);
	const { intString, decimalString } = splitDecimalPricePart(normalizedPrice);

	const handleClick = () => selectSubscription(subscriptionLevelKey);

	return (
		<ul
			onClick={handleClick}
			className={cn('panel-plan', {
				'is-active': isActive,
			})}
		>
			{isCurrentSubscription && (
				<div className="plan-current">Current Plan</div>
			)}
			<li className="panel-plan-name">{name}</li>
			<li className="panel-plan-price">
				<sup>$</sup>
				{intString}
				<sup>{decimalString}</sup>
			</li>
			<li>
				<b>{studioFulfillServiceFeePercent}%</b>
			</li>
			<li>
				<b>{autoFulfillServiceFeePercent}%</b>
			</li>
			<li>
				<b>
					{normalizeDigit({ value: maxImageCount })}
					<span> images</span>
				</b>
			</li>
			<li>
				{exceededMaxStorageFee}¢ <span>/ extra image</span>
			</li>
			<li>{additionalUsers}</li>
			<li>{!freeSmsMarketing ? 'Email only' : '✓'}</li>
			<li>{maxPriceListCount}</li>
			<li>✓</li>
			<li>{!customCampaigns ? '-' : '✓'}</li>
			<li>✓</li>
			<li>✓</li>
			<li>✓</li>
		</ul>
	);
};
