import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { IBreadcrumbsState } from './breadcrumbs/types';
import { IStudioState } from './studio/types';

import { priceListPackageOptionsReducer as studioPriceListPackageOptionsReducer } from './priceLists/studio/priceListPackageOptions';
import { priceListBaseOptionsReducer as studioPriceListBaseOptionsReducer } from './priceLists/studio/priceListBaseOptions';
import { priceListPoseOptionsReducer as studioPriceListPoseOptionsReducer } from './priceLists/studio/priceListPoseOptions';
import { priceListPoseOptionsReducer as imagoPriceListPoseOptionsReducer } from './priceLists/imago/priceListPoseOptions';
import { priceListBackgroundReducer } from './priceLists/priceListBackgrounds';
import { journeyPresetsReducer } from './journeyPresets';
import { studioBillingReducer } from './studioBilling';
import { subscriptionsReducer } from './subscriptions';
import { organizationsReducer } from './organizations';
import { imageGalleryReducer } from './imageGallery';
import { breadcrumbsReducer } from './breadcrumbs';
import { promotionsReducer } from './promotions';
import { broadcastsReducer } from './broadcasts';
import { priceListsReducer } from './priceLists';
import { campaignsReducer } from './campaigns';
import { dashboardReducer } from './dashboard';
import { accountsReducer } from './accounts';
import { projectsReducer } from './projects';
import { sidebarReducer } from './sidebar';
import { studioReducer } from './studio';
import { asideReducer } from './aside';

const rootPersistConfig = {
	key: 'root',
	storage,
	whitelist: [],
};

const studioConfig = {
	key: 'studio',
	storage,
	whitelist: ['studio'],
};

const breadcrumbsConfig = {
	key: 'breadcrumbs',
	storage,
};

const breadcrumbsPersistReducer = persistReducer<IBreadcrumbsState>(
	breadcrumbsConfig,
	breadcrumbsReducer
);

const studioPersistReducer = persistReducer<IStudioState>(
	studioConfig,
	studioReducer
);

const rootReducer = combineReducers({
	aside: asideReducer,
	sidebar: sidebarReducer,
	projects: projectsReducer,
	accounts: accountsReducer,
	campaigns: campaignsReducer,
	dashboard: dashboardReducer,
	studio: studioPersistReducer,
	priceLists: priceListsReducer,
	broadcasts: broadcastsReducer,
	promotions: promotionsReducer,
	imageGallery: imageGalleryReducer,
	organizations: organizationsReducer,
	subscriptions: subscriptionsReducer,
	studioBilling: studioBillingReducer,
	journeyPresets: journeyPresetsReducer,
	breadcrumbs: breadcrumbsPersistReducer,
	priceListBackgrounds: priceListBackgroundReducer,
	imagoPriceListPoseOptions: imagoPriceListPoseOptionsReducer,
	studioPriceListPoseOptions: studioPriceListPoseOptionsReducer,
	studioPriceListBaseOptions: studioPriceListBaseOptionsReducer,
	studioPriceListPackageOptions: studioPriceListPackageOptionsReducer,
});

export const persistedRootReducer = persistReducer(
	rootPersistConfig,
	rootReducer
);
