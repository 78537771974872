import { TimeZonesUTC } from 'constants/studio/timeZonesUTC';
import { TimeZonesUI } from 'constants/studio/timeZonesUI';

import { TimeZones } from 'api/models/responses/studio/timeZones';
import { ISelectOption } from 'types/ui/select';

export const timeZonesSelectOptions: ISelectOption<TimeZones>[] = Object.values(
	TimeZones
).map((timeZone) => ({
	value: timeZone,
	label: `${TimeZonesUI[timeZone]} ${TimeZonesUTC[timeZone]}`,
}));
