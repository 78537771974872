import {
	emailRegEx,
	maxEmailLength,
	invalidEmailMessage,
	requiredEmailMessage,
	maxEmailLengthMessage,
} from 'constants/general/validation/email';

export const validateEmail = (email: string, isRequired = true): string => {
	if (!email && isRequired) {
		return requiredEmailMessage;
	}

	if (!emailRegEx.test(email)) {
		return invalidEmailMessage;
	}

	if (email.length > maxEmailLength) {
		return maxEmailLengthMessage;
	}

	return '';
};
