import { Link } from 'react-router-dom';
import { FC } from 'react';

import { useLogout } from 'hooks/auth/useLogout';
import { ROUTES } from 'constants/ROUTES';

export const Navigation: FC = () => {
	const logout = useLogout();

	const handleLogout = () => {
		void logout();
	};

	return (
		<div className="aside-section">
			<div className="aside-content">
				<ul className="aside-menu">
					<li className="aside-menu-item">
						<Link to={ROUTES.ACC_USER} className="aside-menu-link">
							My Profile
						</Link>
					</li>
					<li className="aside-menu-item">
						<a
							target="_blank"
							rel="noreferrer"
							className="aside-menu-link"
							href="https://support.hhcolorlab.com/hc/en-us/categories/26158397472532-Imago"
						>
							Help
						</a>
					</li>
					<li className="aside-menu-item">
						<button className="aside-menu-link" onClick={handleLogout}>
							Log Out
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
};
