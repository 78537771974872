import { Route, Routes, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { FC, useCallback, useEffect } from 'react';

import { useRedirectToRegistrationSteps } from 'hooks/auth/useRedirectToRegistrationSteps';
import { useRegistrationStepsStatus } from 'hooks/auth/useRegistrationStepsStatus';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { isStudioBillingFulfilledSelector } from 'store/studioBilling';
import { setStudio } from 'store/studio';

import StudioService from 'api/services/StudioService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { ErrorPage } from 'pages/ErrorPage';
import { ROUTES } from 'constants/ROUTES';
import { Layout } from 'layout';

import { registrationStepsRouterList } from './routes/registrationStepsRouterList';
import { mainRouterList } from './routes';

export const Router: FC = () => {
	const iStudioBillingFulfilled = useAppSelector(
		isStudioBillingFulfilledSelector
	);

	useRedirectToRegistrationSteps();

	const { isSetupAccountCompleted } = useRegistrationStepsStatus();
	const isAuthenticated = useIsAuthenticated();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const getStudio = useCallback(async () => {
		if (!isAuthenticated) return;

		try {
			const studio = await StudioService.getStudio();

			dispatch(setStudio(studio));
		} catch (error) {
			const { type } = error as IErrorResponse;

			if (type === ErrorTypes.NotFound) {
				navigate(ROUTES.STUDIO_STEPS.CREATE_STUDIO);
			}
		}
	}, [isAuthenticated, iStudioBillingFulfilled]);

	useEffect(() => {
		void getStudio();
	}, [getStudio]);

	const MainRouterList = mainRouterList.map(({ path, element }) => (
		<Route key={path} path={path} element={element} />
	));

	const RegistrationStepsRouterList = registrationStepsRouterList.map(
		({ path, element }) => <Route key={path} path={path} element={element} />
	);

	const showMainRouterList = isAuthenticated && isSetupAccountCompleted;
	const showRegistrationStepsRouterList =
		isAuthenticated && !isSetupAccountCompleted;

	return (
		<Routes>
			{showRegistrationStepsRouterList && RegistrationStepsRouterList}
			{showMainRouterList && (
				<>
					<Route path={ROUTES.DASHBOARD} element={<Layout />}>
						{MainRouterList}
					</Route>
					<Route path="*" element={<ErrorPage />} />
				</>
			)}
			<Route path="*" element={<div>...loading</div>} />
		</Routes>
	);
};
