export enum SubscriptionNames {
	Basic = 'Basic',
	Standard = 'Standard',
	Premium = 'Premium',
}

export interface ISubscription {
	maxImageCount: number;
	monthlyPayment: number;
	additionalUsers: number;
	name: SubscriptionNames;
	customCampaigns: boolean;
	freeSmsMarketing: boolean;
	maxPriceListCount: number;
	freeEmailMarketing: boolean;
	subscriptionLevelKey: number;
	exceededMaxStorageFee: number;
	autoFulfillServiceFeePercent: number;
	studioFulfillServiceFeePercent: number;
}
