import { useCallback, useEffect } from 'react';

import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { JourneyPresetsSortLabels } from 'constants/journeyPresets/journeyPresetsSortLabels';
import { SKIP } from 'constants/general/pagination/generalGetDataParams';

import { SortParamsResult, useSortParams } from 'hooks/useSortParams';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { IPaginatedPresetsParams } from 'api/models/requests/presets/paginatedPresetsParams';
import {
	getJourneyPresetsAsync,
	journeyPresetsSelector,
	filterByJourneySelector,
	filterByProjectTypeSelector,
	isPaginatedJourneyPresetsPendingSelector,
} from 'store/journeyPresets';
import { PRESETS_TAKE } from 'constants/journeyPresets/pagination';

interface IPaginatedJourneyPresetsResult
	extends SortParamsResult<JourneyPresetsSortLabels> {
	getPaginatedJourneyPresets: () => void;
}

export const usePaginatedJourneyPresets =
	(): IPaginatedJourneyPresetsResult => {
		const filterByProjectType = useAppSelector(filterByProjectTypeSelector);
		const filterByJourney = useAppSelector(filterByJourneySelector);
		const journeyPresets = useAppSelector(journeyPresetsSelector);
		const isPaginatedPresetsPending = useAppSelector(
			isPaginatedJourneyPresetsPendingSelector
		);

		const dispatch = useAppDispatch();

		const { orderBy, orderDirection, setSortParams } =
			useSortParams<JourneyPresetsSortLabels>({
				defaultOrderBy: JourneyPresetsSortLabels.Name,
			});

		const journeyParam =
			filterByJourney === GENERAL_SELECT_OPTION ? null : filterByJourney;

		const projectTypeParam =
			filterByProjectType === GENERAL_SELECT_OPTION
				? null
				: filterByProjectType;

		const getJourneyPresets = useCallback(() => {
			const params: IPaginatedPresetsParams = {
				orderBy,
				skip: SKIP,
				orderDirection,
				take: PRESETS_TAKE,
				journey: journeyParam,
				projectType: projectTypeParam,
			};

			void dispatch(getJourneyPresetsAsync(params));
		}, [orderBy, orderDirection, journeyParam, projectTypeParam]);

		const getPaginatedJourneyPresets = useCallback(() => {
			const isPaginationLimit =
				(journeyPresets.totalCount === journeyPresets.results.length &&
					journeyPresets.totalCount) ||
				journeyPresets.totalCount < PRESETS_TAKE;

			if (isPaginationLimit || isPaginatedPresetsPending) return;

			const params: IPaginatedPresetsParams = {
				orderBy,
				orderDirection,
				take: PRESETS_TAKE,
				journey: journeyParam,
				projectType: projectTypeParam,
				skip: journeyPresets.results.length,
			};

			void dispatch(getJourneyPresetsAsync(params));
		}, [
			orderBy,
			journeyParam,
			journeyPresets,
			orderDirection,
			projectTypeParam,
			isPaginatedPresetsPending,
		]);

		useEffect(() => {
			getJourneyPresets();
		}, [getJourneyPresets]);

		return {
			orderBy,
			setSortParams,
			orderDirection,
			getPaginatedJourneyPresets,
		};
	};
