import { IStudioContactInfo } from 'api/models/responses/studio/studioContactInfo';
import { ICreateStudioBody } from 'api/models/requests/studio/createStudio';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IStudio } from 'api/models/responses/studio/studio';

import { studioUrls } from 'constants/apiUrls/studioUrls';
import { api } from 'config/api';

class StudioService {
	async getStudio(): Promise<IStudio> {
		const { data } = await api.get<IStudio>(studioUrls.studioUrl);

		return data;
	}

	async getStudios(): Promise<IStudio[]> {
		const { data } = await api.get<IStudio[]>(studioUrls.allStudiosForUser);

		return data;
	}

	async createStudio(createStudioBody: ICreateStudioBody): Promise<IStudio> {
		const { data } = await api.post<IStudio>(
			studioUrls.studioUrl,
			createStudioBody
		);

		return data;
	}

	async updateStudio(body: IPatchBody[]): Promise<IStudio> {
		const { data } = await api.patch<IStudio>(studioUrls.studioUrl, body);

		return data;
	}

	async getStudioContactInfo(): Promise<IStudioContactInfo> {
		const { data } = await api.get<IStudioContactInfo>(
			studioUrls.studioContactInfoUrl
		);

		return data;
	}

	async updateStudioContactInfo(
		body: IPatchBody[]
	): Promise<IStudioContactInfo> {
		const { data } = await api.patch<IStudioContactInfo>(
			studioUrls.studioContactInfoUrl,
			body
		);

		return data;
	}

	async closeStudio(): Promise<IStudio> {
		const { data } = await api.put<IStudio>(studioUrls.studioClose);

		return data;
	}

	async switchStudio(studioKey: number): Promise<IStudio> {
		const { data } = await api.put<IStudio>(studioUrls.switchStudio(studioKey));

		return data;
	}
}

export default new StudioService();
