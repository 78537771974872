import { useEffect } from 'react';

import { SETTINGS } from 'constants/navigation';
import { ROUTES } from 'constants/ROUTES';

import { IBreadcrumbs, setBreadcrumbs } from 'store/breadcrumbs';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';

export const useAccUserBreadcrumbs = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const settingsNavigation = NavigationService.getParentNavigation(SETTINGS);

		const accUserNavigation = NavigationService.getSubNavigation(
			SETTINGS,
			ROUTES.ACC_USER
		);

		if (!settingsNavigation || !accUserNavigation) return;

		const settingsBreadcrumbs: IBreadcrumbs = {
			isActive: false,
			path: settingsNavigation.path,
			title: settingsNavigation.title,
		};

		const accUserBreadcrumbs: IBreadcrumbs = {
			isActive: true,
			path: accUserNavigation.path,
			title: accUserNavigation.title,
		};

		const breadcrumbs: IBreadcrumbs[] = [
			settingsBreadcrumbs,
			accUserBreadcrumbs,
		];

		dispatch(setBreadcrumbs(breadcrumbs));
	}, []);
};
