import React, { forwardRef } from 'react';
import { ButtonTypes } from 'types/ui/buttonTypes';

interface ButtonProps {
	value: string;
	className: string;
	disabled?: boolean;
	type?: ButtonTypes;
	handleClick?: () => void | Promise<void>;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ type, value, disabled, className, handleClick }, buttonRef) => (
		<button
			ref={buttonRef}
			type={type}
			className={className}
			onClick={handleClick}
			disabled={disabled}
		>
			{value}
		</button>
	)
);

Button.displayName = 'Button';

Button.defaultProps = {
	type: ButtonTypes.BUTTON,
	disabled: false,
};
