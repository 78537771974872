import { FC, useCallback, useEffect, useState } from 'react';

import { ICreatePoseOption } from 'api/models/requests/priceLists/studio/priceListOptions/createPoseOption';
import { StudioPoseOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/poseOption';
import PoseOptionsService from 'api/services/PriceListsService/studio/PoseOptionsService';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { initialPoseOptionErrors } from 'constants/priceLists/studio/initialPoseOptionErrors';
import { studioPoseOptionTypesUI } from 'constants/priceLists/studio/poseOptionTypesUI';
import { CommonOptionActionMenu } from 'constants/priceLists/commonOptionActionMenu';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';
import { makeEntityAsDefault } from 'utils/dragAndDrop/makeEntityAsDefault';

import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import {
	IPoseOptionUI,
	IUpdatePoseOption,
	IClearPoseOptionError,
	IPoseActionMenuParams,
	IUploadPoseOptionImage,
} from 'pages/PriceLists/types/studio/priceListOptions/poseOptions';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useActionMenu } from 'hooks/useActionMenu';

import { PriceDeleteBtn } from 'components/Controls/PriceDeleteBtn';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';

import { setGroupedPoseOptions } from 'store/priceLists/studio/priceListPoseOptions';

import { PoseOption } from './PoseOption';

interface IPoseOptionsProps {
	priceListKey?: number;
	headers: IHeaderConfig[];
	poseOptionsUI: IPoseOptionUI[];
	poseOptionType: StudioPoseOptionTypes;
	deletePoseOptionsSection: (
		poseOptionType: StudioPoseOptionTypes
	) => Promise<void>;
}

export const PoseOptions: FC<IPoseOptionsProps> = ({
	headers,
	priceListKey,
	poseOptionsUI,
	poseOptionType,
	deletePoseOptionsSection,
}) => {
	const [previewImageFile, setPreviewImageFile] = useState<File | null>(null);
	const [isReorder, setIsReorder] = useState(false);

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const dispatch = useAppDispatch();

	const lastPoseOptionUI = poseOptionsUI[poseOptionsUI.length - 1];

	const updatePoseOption = async ({
		value,
		fieldKey,
		sequence,
		poseOptionKey,
		validationMessage,
	}: IUpdatePoseOption) => {
		if (!poseOptionKey || validationMessage) {
			const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
				poseOptionUI.sequence === sequence
					? {
							...poseOptionUI,
							[fieldKey]: value,
							errors: {
								...poseOptionUI.errors,
								[fieldKey]: validationMessage,
							},
					  }
					: poseOptionUI
			);

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: updatedPoseOptionsUI,
				})
			);
			return;
		}

		if (!poseOptionKey) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data = await PoseOptionsService.updatePoseOption(
				poseOptionKey,
				body
			);

			const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
				poseOptionUI.priceListStudioFulfilledPoseOptionKey === poseOptionKey
					? { ...poseOptionUI, ...data }
					: poseOptionUI
			);

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: updatedPoseOptionsUI,
				})
			);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
				poseOptionUI.priceListStudioFulfilledPoseOptionKey === poseOptionKey
					? {
							...poseOptionUI,
							errors: {
								...poseOptionUI.errors,
								[fieldKey]: errors[fieldKey],
							},
					  }
					: poseOptionUI
			);

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: updatedPoseOptionsUI,
				})
			);
		}
	};

	const handleAddPoseOption = () => {
		if (!priceListKey) return;

		const initialSequence =
			poseOptionsUI.length && lastPoseOptionUI.sequence + 1;

		const newPoseOptionUI: IPoseOptionUI = {
			name: '',
			retailPrice: 0,
			poseOptionType,
			referenceCode: '',
			previewImageUrl: null,
			sequence: initialSequence,
			errors: initialPoseOptionErrors,
			priceListStudioFulfilledKey: priceListKey,
		};

		dispatch(
			setGroupedPoseOptions({
				poseOptionType,
				poseOptions: [...poseOptionsUI, newPoseOptionUI],
			})
		);
	};

	const handleSavePoseOption = async () => {
		if (!priceListKey) return;

		const { name, sequence, retailPrice, referenceCode } = lastPoseOptionUI;

		const slicedPoseOptionsUI = poseOptionsUI.slice(0, -1);

		const nameValidationMessage = validateOptionName(name);
		const retailPriceValidationMessage = validateRetailValue(retailPrice);
		const referenceCodeValidationMessage = validateReferenceCode(referenceCode);

		const isValid =
			!nameValidationMessage &&
			!retailPriceValidationMessage &&
			!referenceCodeValidationMessage;

		if (!isValid) {
			const updatedLastPoseOptionUI: IPoseOptionUI = {
				...lastPoseOptionUI,
				errors: {
					previewImageUrl: '',
					name: nameValidationMessage,
					retailPrice: retailPriceValidationMessage,
					referenceCode: referenceCodeValidationMessage,
				},
			};

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: [...slicedPoseOptionsUI, updatedLastPoseOptionUI],
				})
			);
			return;
		}

		try {
			const body: ICreatePoseOption = {
				name,
				sequence,
				retailPrice,
				referenceCode,
				poseOptionType,
				previewImageFile,
			};

			const data = await PoseOptionsService.createPoseOption(
				priceListKey,
				body
			);

			const updatedPoseOptionUI: IPoseOptionUI = {
				...lastPoseOptionUI,
				...data,
			};

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: [...slicedPoseOptionsUI, updatedPoseOptionUI],
				})
			);
			setPreviewImageFile(null);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
				poseOptionUI.sequence === sequence
					? {
							...poseOptionUI,
							errors: {
								...poseOptionUI.errors,
								...errors,
							},
					  }
					: poseOptionUI
			);

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: updatedPoseOptionsUI,
				})
			);
		}
	};

	const deletePoseOption = async (sequence: number, poseOptionKey?: number) => {
		const updatedPoseOptionsUI = poseOptionsUI.filter(
			(poseOptionUI) => poseOptionUI.sequence !== sequence
		);

		dispatch(
			setGroupedPoseOptions({
				poseOptionType,
				poseOptions: updatedPoseOptionsUI,
			})
		);

		setPreviewImageFile(null);

		if (poseOptionKey && priceListKey) {
			try {
				await PoseOptionsService.deletePoseOption(priceListKey, poseOptionKey);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const changePoseOptionsUISequence = ({
		toBottom,
		dragElemId,
		dropElemId,
	}: IChangeSequencePayload) => {
		const reorderedPoseOptionsUI = changeEntitiesSequence<IPoseOptionUI>({
			toBottom,
			dragElemId,
			dropElemId,
			entities: poseOptionsUI,
			fieldKey: 'priceListStudioFulfilledPoseOptionKey',
		});

		dispatch(
			setGroupedPoseOptions({
				poseOptionType,
				poseOptions: reorderedPoseOptionsUI,
			})
		);
		setIsReorder(true);
	};

	const makePoseOptionDefault = (poseOptionKey?: number) => {
		if (!poseOptionKey) return;

		const reorderedPoseOptionsUI = makeEntityAsDefault<IPoseOptionUI>({
			entities: poseOptionsUI,
			entityKey: poseOptionKey,
			fieldKey: 'priceListStudioFulfilledPoseOptionKey',
		});

		dispatch(
			setGroupedPoseOptions({
				poseOptionType,
				poseOptions: reorderedPoseOptionsUI,
			})
		);
		setIsReorder(true);
	};

	const reorderPoseOptions = useCallback(async () => {
		if (!isReorder || !priceListKey) return;

		const body = poseOptionsUI.reduce<Record<number, number>>(
			(acc, { sequence, priceListStudioFulfilledPoseOptionKey }) =>
				priceListStudioFulfilledPoseOptionKey
					? { ...acc, [priceListStudioFulfilledPoseOptionKey]: sequence }
					: acc,
			{}
		);

		try {
			await PoseOptionsService.reorderPoseOptions(priceListKey, body);
		} catch (error) {
			console.log(error);
		}
	}, [isReorder]);

	const handleActionMenuItemClick = ({
		sequence,
		menuItem,
		poseOptionKey,
	}: IPoseActionMenuParams) => {
		switch (menuItem) {
			case CommonOptionActionMenu.Delete:
				void deletePoseOption(sequence, poseOptionKey);
				break;

			case CommonOptionActionMenu.MakeAsDefault:
				makePoseOptionDefault(poseOptionKey);
				break;

			default:
				break;
		}
	};

	const handleDeletePoseOptionsSection = () => {
		void deletePoseOptionsSection(poseOptionType);
	};

	const uploadImagePreview = async ({
		sequence,
		imageFile,
		poseOptionKey,
		validationMessage,
	}: IUploadPoseOptionImage) => {
		if (validationMessage) {
			const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
				poseOptionUI.sequence === sequence
					? {
							...poseOptionUI,
							errors: {
								...poseOptionUI.errors,
								previewImageUrl: validationMessage,
							},
					  }
					: poseOptionUI
			);

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: updatedPoseOptionsUI,
				})
			);
			return;
		}

		if (!poseOptionKey) {
			setPreviewImageFile(imageFile);
			return;
		}

		try {
			const data = await PoseOptionsService.uploadPoseOptionPreviewImage(
				poseOptionKey,
				imageFile
			);

			const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
				poseOptionUI.priceListStudioFulfilledPoseOptionKey === poseOptionKey
					? {
							...poseOptionUI,
							previewImageUrl: data,
							errors: {
								...poseOptionUI.errors,
								previewImageUrl: '',
							},
					  }
					: poseOptionUI
			);

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: updatedPoseOptionsUI,
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteImagePreview = async (
		sequence: number,
		poseOptionKey?: number
	) => {
		if (!poseOptionKey) {
			setPreviewImageFile(null);
			return;
		}

		try {
			await PoseOptionsService.deletePoseOptionPreviewImage(poseOptionKey);

			const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
				poseOptionUI.sequence === sequence
					? {
							...poseOptionUI,
							previewImageUrl: null,
					  }
					: poseOptionUI
			);

			dispatch(
				setGroupedPoseOptions({
					poseOptionType,
					poseOptions: updatedPoseOptionsUI,
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	const clearPoseOptionError = ({
		sequence,
		fieldKey,
	}: IClearPoseOptionError) => {
		const updatedPoseOptionsUI = poseOptionsUI.map((poseOptionUI) =>
			poseOptionUI.sequence === sequence
				? {
						...poseOptionUI,
						errors: {
							...poseOptionUI.errors,
							[fieldKey]: '',
						},
				  }
				: poseOptionUI
		);

		dispatch(
			setGroupedPoseOptions({
				poseOptionType,
				poseOptions: updatedPoseOptionsUI,
			})
		);
	};

	useEffect(() => {
		void reorderPoseOptions();
	}, [reorderPoseOptions]);

	const PoseOptionsList = poseOptionsUI.map((poseOptionUI) => (
		<PoseOption
			actionMenuId={actionMenuId}
			poseOptionUI={poseOptionUI}
			clearError={clearPoseOptionError}
			setActionMenuId={setActionMenuId}
			updatePoseOption={updatePoseOption}
			previewImageFile={previewImageFile}
			uploadImagePreview={uploadImagePreview}
			deleteImagePreview={deleteImagePreview}
			handleActionMenuItemClick={handleActionMenuItemClick}
			changePoseOptionsSequence={changePoseOptionsUISequence}
			key={
				poseOptionUI.priceListStudioFulfilledPoseOptionKey ||
				poseOptionUI.sequence
			}
		/>
	));

	const { singleLabel, multiLabel } = studioPoseOptionTypesUI[poseOptionType];

	const showSaveBtn =
		!!poseOptionsUI.length &&
		!lastPoseOptionUI.priceListStudioFulfilledPoseOptionKey;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: singleLabel,
			handleAdd: handleAddPoseOption,
			handleSave: handleSavePoseOption,
		});

	return (
		<>
			<div className="price-options">
				<h4 className="price-options-title">{multiLabel}</h4>
				<PriceDeleteBtn handleClick={handleDeletePoseOptionsSection} />
			</div>
			{poseOptionsUI.length ? (
				<PriceTable headers={headers} className="price-table-backgrounds">
					{PoseOptionsList}
				</PriceTable>
			) : (
				<NoItemsFound title={`${multiLabel} options`} />
			)}
			<Button
				value={btnValue}
				handleClick={btnClickHandler}
				className={`price-add-back ${btnClassName}`}
			/>
		</>
	);
};
