import { FC } from 'react';

import { PriceDeleteBtn } from 'components/Controls/PriceDeleteBtn';
import { Checkbox } from 'components/FormControls/Checkbox';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';

interface IResponseOptionsColumnProps {
	sequence: number;
	handleDelete: () => void;
	isAnswerRequired: boolean;
	updateIsAnswerRequired: (value: boolean) => Promise<void>;
}

export const ResponseOptionsColumn: FC<IResponseOptionsColumnProps> = ({
	sequence,
	handleDelete,
	isAnswerRequired,
	updateIsAnswerRequired,
}) => {
	const { checked, isPending, handleChange } = useAsyncOptimizedCheckbox(
		isAnswerRequired,
		updateIsAnswerRequired
	);

	return (
		<td className="price-option-td">
			<div className="price-response-options">
				<Checkbox
					checked={checked}
					disabled={isPending}
					label="Response Required"
					handleChange={handleChange}
					id={`answer-required-${sequence}`}
				/>
				<PriceDeleteBtn handleClick={handleDelete} />
			</div>
		</td>
	);
};
