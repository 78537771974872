import { useFormikContext } from 'formik';
import { FC } from 'react';

import { BillingEntityTypes } from 'api/models/responses/studioBilling/billingEntityTypes';
import { IBillingInfoFormValues } from 'components/W9InfoForm/types';

interface IRadioField {
	label: string;
	id: BillingEntityTypes;
}

const radioFields: IRadioField[][] = [
	[
		{
			id: BillingEntityTypes.IndividualSoleProprietor,
			label: 'Individual/Sole Proprietor',
		},
		{
			id: BillingEntityTypes.Partenership,
			label: 'Partenership',
		},
		{
			id: BillingEntityTypes.LLCPartnership,
			label: 'Limited Liability Company (Partnership)',
		},
	],
	[
		{
			id: BillingEntityTypes.CCorporation,
			label: 'C Corporation',
		},
		{
			id: BillingEntityTypes.LLCSingleMember,
			label: 'Limited Liability Company (Single member LLC)',
		},
		{
			id: BillingEntityTypes.LLCSCorporation,
			label: 'Limited Liability Company (S-Corporation)',
		},
	],
	[
		{
			id: BillingEntityTypes.SCorporation,
			label: 'S Corporation',
		},
		{
			id: BillingEntityTypes.LLCCCorporation,
			label: 'Limited Liability Company (C-Corporation)',
		},
		{
			id: BillingEntityTypes.Other,
			label: 'Other (see instructions)',
		},
	],
];

export const W9EntityType: FC = () => {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<IBillingInfoFormValues>();

	const CheckBoxFieldsList = radioFields.map((radioFieldsParam, index) => (
		<td
			colSpan={2}
			id="billingEntityType"
			className="acc-td-clear"
			key={`${radioFieldsParam[index].id}-${radioFieldsParam[index].label}`}
		>
			{radioFieldsParam.map(({ id, label }) => (
				<label key={id} htmlFor={id} className="acc-check-label">
					<input
						value={id}
						type="radio"
						className="acc-check"
						name="billingEntityType"
						checked={values.billingEntityType === id}
						onChange={() => {
							void setFieldValue('billingEntityType', id);
						}}
					/>
					{label}
				</label>
			))}
		</td>
	));

	const entityTypeError = errors.billingEntityType;
	const entityTypeTouched = touched.billingEntityType;

	const showEntityTypeError = entityTypeError && entityTypeTouched;

	return (
		<table className="acc-table" cellSpacing={0} cellPadding={2} border={0}>
			<tbody className="acc-tbody">
				<tr className="acc-tr">
					<th className="acc-th" colSpan={6}>
						<span className="acc-title-type">
							Vendor Entity Type. Check ONE of the appropriate circles:
						</span>
					</th>
				</tr>
				<tr className="acc-tr">{CheckBoxFieldsList}</tr>
				{showEntityTypeError && (
					<tr className="acc-tr acc-tr-radio">
						<span className="acc-error-message">{entityTypeError}</span>
					</tr>
				)}
			</tbody>
		</table>
	);
};
