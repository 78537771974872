import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { ROUTES } from 'constants/ROUTES';

import { useRegistrationStepsStatus } from './useRegistrationStepsStatus';

export const useRedirectToRegistrationSteps = () => {
	const navigate = useNavigate();

	const { isStudioCreated, isSubscriptionSelected } =
		useRegistrationStepsStatus();

	useEffect(() => {
		if (isSubscriptionSelected) {
			return navigate(ROUTES.STUDIO_STEPS.GET_PAID);
		}

		if (isStudioCreated) {
			return navigate(ROUTES.STUDIO_STEPS.SELECT_PLAN);
		}
	}, [isStudioCreated, isSubscriptionSelected]);
};
