import { RegistrationSteps } from 'api/models/responses/studio/registrationSteps';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { studioSelector } from 'store/studio';

interface IRegistrationStepsStatus {
	isStudioCreated: boolean;
	isSubscriptionSelected: boolean;
	isSetupAccountCompleted: boolean;
}

export const useRegistrationStepsStatus = (): IRegistrationStepsStatus => {
	const studio = useAppSelector(studioSelector);

	const registrationStep = studio?.registrationStep;

	const isStudioCreated =
		registrationStep === RegistrationSteps.ChooseSubscriptionPlan;

	const isSubscriptionSelected =
		registrationStep === RegistrationSteps.FulfillmentW9Info;

	const isSetupAccountCompleted =
		registrationStep === RegistrationSteps.Completed;

	return {
		isStudioCreated,
		isSubscriptionSelected,
		isSetupAccountCompleted,
	};
};
