import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const dashboardSelector = (state: RootState) => state.dashboard;

export const marketingStatisticSelector = createSelector(
	dashboardSelector,
	({ marketingStatistic }) => marketingStatistic
);

export const isMarketingStatisticPendingSelector = createSelector(
	dashboardSelector,
	({ isMarketingStatisticPending }) => isMarketingStatisticPending
);

export const studioStatisticSelector = createSelector(
	dashboardSelector,
	({ studioStatistic }) => studioStatistic
);

export const latestOrdersSelector = createSelector(
	dashboardSelector,
	({ latestOrders }) => latestOrders
);

export const comingSoonSelector = createSelector(
	dashboardSelector,
	({ comingSoon }) => comingSoon
);

export const latestProjectsSelector = createSelector(
	dashboardSelector,
	({ latestProjects }) => latestProjects
);

export const selectedDateRangeSelector = createSelector(
	dashboardSelector,
	({ selectedDateRange }) => selectedDateRange
);

export const ordersWaitingSelector = createSelector(
	dashboardSelector,
	({ ordersWaiting }) => ordersWaiting
);
