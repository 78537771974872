import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser } from 'api/models/responses/account/user';

import { IAccountsState } from './types';

const initialState: IAccountsState = {
	users: [],
	currentUser: null,
	isUsersPending: false,
};

const accountsSlice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		addUser(state, { payload }: PayloadAction<IUser>) {
			state.users.push(payload);
		},

		setUsers(state, { payload }: PayloadAction<IUser[]>) {
			state.users = payload;
		},

		setIsUsersPending(state, { payload }: PayloadAction<boolean>) {
			state.isUsersPending = payload;
		},

		updateUser(state, { payload }: PayloadAction<IUser>) {
			state.users = state.users.map((user) =>
				user.id === payload.id ? payload : user
			);
		},

		setCurrentUser(state, { payload }: PayloadAction<IUser>) {
			state.currentUser = payload;
		},

		deleteUser(state, { payload }: PayloadAction<string>) {
			state.users = state.users.filter((user) => user.id !== payload);
		},
	},
});

export const accountsReducer = accountsSlice.reducer;
export const {
	addUser,
	setUsers,
	deleteUser,
	updateUser,
	setCurrentUser,
	setIsUsersPending,
} = accountsSlice.actions;
