import { FC } from 'react';

import { useMarketingBreadcrumbs } from './hooks/useMarketingBreadcrumbs';
import { TextMessageSettings } from './components/TextMessageSettings';
import { EmailSettings } from './components/EmailSettings';

export const MarketingPage: FC = () => {
	useMarketingBreadcrumbs();

	return (
		<div className="marketing-container">
			<EmailSettings />
			<TextMessageSettings />
		</div>
	);
};
