import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IStudio } from 'api/models/responses/studio/studio';
import StudioService from 'api/services/StudioService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToastify } from 'hooks/useToastify';

import { setStudio, studioSelector } from 'store/studio';

import { updateStudioValidationSchema } from './validation';
import { UpdateStudioForm } from './UpdateStudioForm';

export interface IUpdateStudioProps {
	hideUpdateStudioModal: () => void;
}

export type UpdateStudioValues = Pick<IStudio, 'studioName' | 'networkDomain'>;

export const UpdateStudio: FC<IUpdateStudioProps> = ({
	hideUpdateStudioModal,
}) => {
	const studio = useAppSelector(studioSelector);

	const dispatch = useAppDispatch();

	const { showSuccessToastify } = useToastify();

	const initialValues: UpdateStudioValues = {
		studioName: studio?.studioName ?? '',
		networkDomain: studio?.networkDomain ?? '',
	};

	const onSubmit = async (
		values: UpdateStudioValues,
		{ setErrors }: FormikHelpers<UpdateStudioValues>
	) => {
		try {
			const updatedStudioName: IPatchBody = {
				op: 'replace',
				path: 'studioName',
				value: values.studioName,
			};

			const updatedNetworkDomain: IPatchBody = {
				op: 'replace',
				path: 'networkDomain',
				value: values.networkDomain,
			};

			const body: IPatchBody[] = [updatedStudioName, updatedNetworkDomain];

			const data = await StudioService.updateStudio(body);

			dispatch(setStudio(data));

			showSuccessToastify({ title: 'Studio Updated' });
			hideUpdateStudioModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const { studioName, networkDomain } = errors;

				setErrors({
					studioName: studioName[0] ?? '',
					networkDomain: networkDomain[0] ?? '',
				});
			}
		}
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={updateStudioValidationSchema}
		>
			<UpdateStudioForm hideUpdateStudioModal={hideUpdateStudioModal} />
		</Formik>
	);
};
