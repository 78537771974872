import { string } from 'yup';

import {
	emailRegEx,
	maxEmailLength,
	invalidEmailMessage,
	maxEmailLengthMessage,
} from '../email';

export const emailSchema = string()
	.matches(emailRegEx, invalidEmailMessage)
	.max(maxEmailLength, maxEmailLengthMessage);
