import { Formik } from 'formik';
import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useModalControls } from 'hooks/useModalControls';
import { useLogout } from 'hooks/auth/useLogout';

import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { setStudios, studiosSelector, switchStudioAsync } from 'store/studio';
import StudioService from 'api/services/StudioService';

import { closeAccountValidationSchema } from './components/CloseAccount/validation';
import { useAccountingBreadcrumbs } from './hooks/useAccountingBreadcrumbs';
import { BillingInformation } from './components/BillingInformation';
import { BankInformation } from './components/BankInformation';
import { PlanInformation } from './components/PlanInformation';
import { WInformation } from './components/WInformation';
import { CloseAccount } from './components/CloseAccount';

export interface ICloseAccValues {
	confirmationText: string;
	confirmationCheckbox: boolean;
}

const initialFormValues: ICloseAccValues = {
	confirmationText: '',
	confirmationCheckbox: false,
};

export const Accounting: FC = () => {
	const studios = useAppSelector(studiosSelector);

	useAccountingBreadcrumbs();

	const dispatch = useAppDispatch();

	const logout = useLogout();

	const {
		modalRef: closeAccountModalRef,
		showModal: showCloseAccountModal,
		hideModal: hideCloseAccountModal,
	} = useModalControls();

	const onSubmit = async () => {
		try {
			const { studioKey } = await StudioService.closeStudio();

			const updatedStudios = studios.filter(
				(studio) => studio.studioKey !== studioKey
			);

			if (!updatedStudios.length) {
				return void logout();
			}

			dispatch(setStudios(updatedStudios));

			void dispatch(switchStudioAsync(updatedStudios[0].studioKey));

			hideCloseAccountModal();
		} catch (error) {
			console.log(error, 'error');
		}
	};

	return (
		<div className="order-container">
			<PlanInformation />
			<BillingInformation />
			<BankInformation />
			<WInformation />
			<Button
				value="Close Account"
				handleClick={showCloseAccountModal}
				className="btn-secondary accing-footer-btn"
			/>
			<Modal
				title="You are about to close your imago account."
				subtitle="This will delete your images, order history, projects, price lists, marketing, etc. You will no longer have access to this information. This action cannot be undone."
				ref={closeAccountModalRef}
			>
				<Formik
					onSubmit={onSubmit}
					initialValues={initialFormValues}
					validationSchema={closeAccountValidationSchema}
				>
					<CloseAccount closeModal={hideCloseAccountModal} />
				</Formik>
			</Modal>
		</div>
	);
};
