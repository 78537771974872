import { FC } from 'react';

import { studiosSelector, studioSelector } from 'store/studio';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Account } from './Account';

export const Accounts: FC = () => {
	const studios = useAppSelector(studiosSelector);
	const studio = useAppSelector(studioSelector);

	const StudiosList = studios.map(({ studioKey: key, studioName }) => (
		<Account
			key={key}
			studioKey={key}
			studioName={studioName}
			isActive={studio?.studioKey === key}
		/>
	));

	return (
		<div className="aside-section">
			<h3 className="aside-section-title">ACCOUNTS</h3>
			<div className="aside-content">
				<ul className="aside-menu">{StudiosList}</ul>
			</div>
		</div>
	);
};
