import { useEffect, useState } from 'react';

import { priceListsSortOptions } from 'constants/priceLists/priceListsSortOptions';
import { PriceListsSortLabels } from 'constants/priceLists/priceListsSortLabels';
import { TAKE_PRICE_LISTS } from 'constants/priceLists/pagination';
import { SKIP } from 'constants/general/pagination/generalGetDataParams';

import { ISearchPriceLists } from 'api/models/requests/priceLists/searchPriceLists';
import { IPriceList } from 'api/models/responses/priceLists/priceList';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useSortParams } from 'hooks/useSortParams';

import { priceListsSelector, searchPriceListsAsync } from 'store/priceLists';
import { ISearchResult } from 'types/searchParams';

export const useSearchPriceLists = (
	isActive: boolean
): ISearchResult<PriceListsSortLabels, IPriceList[]> => {
	const [searchValue, setSearchValue] = useState('');

	const priceLists = useAppSelector(priceListsSelector);

	const {
		orderBy,
		setOrderBy,
		setSortParams,
		orderDirection,
		setOrderDirection,
	} = useSortParams<PriceListsSortLabels>({
		sortOptions: priceListsSortOptions,
		defaultOrderBy: PriceListsSortLabels.Name,
	});

	const dispatch = useAppDispatch();

	const handleSearch = (projectName: string) => {
		setSearchValue(projectName);
	};

	const setPaginatedPriceLists = () => {
		const { results, totalCount } = priceLists;

		const isPaginationLimit =
			(totalCount === results.length && totalCount) ||
			totalCount < TAKE_PRICE_LISTS;

		if (isPaginationLimit) return;

		const searchParams: ISearchPriceLists = {
			orderBy,
			isActive,
			orderDirection,
			take: TAKE_PRICE_LISTS,
			skip: priceLists.results.length,
			searchValue: searchValue || null,
		};

		void dispatch(searchPriceListsAsync(searchParams));
	};

	useEffect(() => {
		const searchParams: ISearchPriceLists = {
			orderBy,
			isActive,
			skip: SKIP,
			orderDirection,
			take: TAKE_PRICE_LISTS,
			searchValue: searchValue || null,
		};

		void dispatch(searchPriceListsAsync(searchParams));
	}, [orderBy, isActive, searchValue, orderDirection]);

	return {
		orderBy,
		setOrderBy,
		handleSearch,
		setSortParams,
		orderDirection,
		setOrderDirection,
		results: priceLists.results,
		setPaginatedEntity: setPaginatedPriceLists,
	};
};
