import { useCallback, useEffect, useState } from 'react';

import { IBaseSortReqParams } from 'api/models/requests/general/baseSortReqParams';
import { IBroadcastItem } from 'api/models/responses/campaigns/campaignDetails';
import { OrderDirection } from 'api/models/requests/general/orderDirection';

import { BroadcastItemsSortLabels } from 'constants/broadcasts/broadcastItemsSortLabels';
import { useSortParams } from 'hooks/useSortParams';

interface ISortBroadcastsResult
	extends IBaseSortReqParams<BroadcastItemsSortLabels> {
	sortedBroadcasts: IBroadcastItem[];
	setSortParams: (sortLabel: BroadcastItemsSortLabels) => void;
}

export const useSortBroadcasts = (
	broadcasts: IBroadcastItem[]
): ISortBroadcastsResult => {
	const [sortedBroadcasts, setSortedBroadcasts] = useState<IBroadcastItem[]>(
		[]
	);

	const { orderBy, setSortParams, orderDirection } =
		useSortParams<BroadcastItemsSortLabels>({
			defaultOrderBy: BroadcastItemsSortLabels.Name,
		});

	const sortByAlphabet = useCallback(
		(key: keyof IBroadcastItem) => {
			return [...broadcasts].sort((a, b) => {
				const valueA = a[key] as string;
				const valueB = b[key] as string;

				return orderDirection === OrderDirection.ASC
					? valueA.localeCompare(valueB)
					: valueB.localeCompare(valueA);
			});
		},
		[orderDirection]
	);

	useEffect(() => {
		if (!broadcasts.length) return;

		switch (orderBy) {
			case BroadcastItemsSortLabels.Name: {
				const sortedBroadcastsByName = sortByAlphabet('name');

				setSortedBroadcasts(sortedBroadcastsByName);
				break;
			}

			case BroadcastItemsSortLabels.Send: {
				const sortedBroadcastsBySend = sortByAlphabet('send');

				setSortedBroadcasts(sortedBroadcastsBySend);
				break;
			}

			default:
				break;
		}
	}, [orderBy, orderDirection]);

	return {
		orderBy,
		setSortParams,
		orderDirection,
		sortedBroadcasts: !sortedBroadcasts.length ? broadcasts : sortedBroadcasts,
	};
};
