import cn from 'classnames';
import { FC } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { switchStudioAsync } from 'store/studio';

interface IAccountProps {
	isActive: boolean;
	studioKey: number;
	studioName: string;
}

export const Account: FC<IAccountProps> = ({
	isActive,
	studioKey,
	studioName,
}) => {
	const dispatch = useAppDispatch();

	const handleSelectStudio = () => {
		void dispatch(switchStudioAsync(studioKey));
	};

	return (
		<li className="aside-menu-item" onClick={handleSelectStudio}>
			<span
				className={cn('aside-menu-link', {
					bold: isActive,
				})}
			>
				{studioName}
			</span>
		</li>
	);
};
