import { FC, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

import { dateRangeSelectOptions } from 'constants/dashboard/dateRangeSelectOptions';
import { DateRanges } from 'constants/dashboard/dateRange';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { IDateRange } from 'store/dashboard/types';
import {
	setSelectedDateRange,
	getStudioStatisticAsync,
	selectedDateRangeSelector,
} from 'store/dashboard';

import { getIntervalBoundariesByTerm } from 'utils/general/getIntervalBoundariesByTerm';
import { SelectComponent } from 'components/FormControls/Select';
import { ISelectOption } from 'types/ui/select';

export const DateRangeSelect: FC = () => {
	const [selectedTerm, setSelectedTerm] = useState<DateRanges>(
		DateRanges.MONTH
	);

	const selectedDateRange = useAppSelector(selectedDateRangeSelector);

	const dispatch = useAppDispatch();

	const handleChange = (option: SingleValue<ISelectOption<DateRanges>>) => {
		if (!option) return;

		setSelectedTerm(option.value);
	};

	useEffect(() => {
		const intervalBoundaries = getIntervalBoundariesByTerm(selectedTerm);

		const dateParam: IDateRange = {
			...intervalBoundaries,
			term: selectedTerm,
		};

		dispatch(setSelectedDateRange(dateParam));
	}, [selectedTerm]);

	useEffect(() => {
		if (!selectedDateRange) return;

		void dispatch(getStudioStatisticAsync(selectedDateRange));
	}, [selectedDateRange]);

	return (
		<div className="data-control">
			<label htmlFor="dateRange" className="data-control-label">
				Stats overview for
			</label>
			<SelectComponent
				id="dateRange"
				value={selectedTerm}
				onChange={handleChange}
				selectOptions={dateRangeSelectOptions}
			/>
		</div>
	);
};
