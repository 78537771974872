import { Formik } from 'formik';
import { FC } from 'react';

import AccountService from 'api/services/AccountService';

import { SendVerificationCodeForm } from './SendVerificationCodeForm';
import { sendVerificationCodeSchema } from './validation';

export interface ISendVerificationCodeProps {
	showChangeEmailModal: () => void;
	hideSendVerificationCodeModal: () => void;
	setConfirmedEmail: (email: string) => void;
}

export interface ISendVerificationCodeValues {
	email: string;
	confirmEmail: string;
}

export const SendVerificationCode: FC<ISendVerificationCodeProps> = ({
	setConfirmedEmail,
	showChangeEmailModal,
	hideSendVerificationCodeModal,
}) => {
	const initialValues: ISendVerificationCodeValues = {
		email: '',
		confirmEmail: '',
	};

	const onSubmit = async ({ email }: ISendVerificationCodeValues) => {
		try {
			await AccountService.sendVerificationCode(email);

			setConfirmedEmail(email);
			hideSendVerificationCodeModal();
			showChangeEmailModal();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={sendVerificationCodeSchema}
		>
			<SendVerificationCodeForm
				hideSendVerificationCodeModal={hideSendVerificationCodeModal}
			/>
		</Formik>
	);
};
