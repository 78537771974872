import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { FC, useRef, useState } from 'react';
import { Formik } from 'formik';
import cn from 'classnames';

import { htmlSupport } from 'constants/textEditor/htmlSupport';
import { fontFamily } from 'constants/textEditor/fontFamily';
import { fontColor } from 'constants/textEditor/fontColor';
import { fontSize } from 'constants/textEditor/fontSize';
import { toolbar } from 'constants/textEditor/toolbar';
import { image } from 'constants/textEditor/image';

import { Button } from 'components/FormControls/Button';

import { IAddButtonValues, IButtonTemplate, ITextEditorProps } from './types';
import { AddButton } from './AddButton';

import Desktop from 'images/editor-desktop.svg';
import Tablet from 'images/editor-tablet.svg';
import Mobile from 'images/editor-mobile.svg';

import DesktopDisabled from 'images/editor-desktop-disabled.svg';
import TabletDisabled from 'images/editor-tablet-disabled.svg';
import MobileDisabled from 'images/editor-mobile-disabled.svg';

const ckeditorConfig: object = {
	image,
	toolbar,
	fontSize,
	fontColor,
	fontFamily,
	htmlSupport,
	fontBackgroundColor: fontColor,
};

const textEditorViewTabs = ['Desktop', 'Tablet', 'Mobile'];

export const TextEditor: FC<ITextEditorProps> = ({
	disabled,
	editorRef,
	handleSave,
	clearError,
	errorMessage,
	initialContent,
}) => {
	const saveButtonRef = useRef<HTMLButtonElement>(null);

	const initialValues: IAddButtonValues = {
		link: '',
		label: '',
		color: '#E66465',
		bold: false,
		fontSize: '',
		italic: false,
		fontFamily: '',
		underline: false,
		backgroundColor: '#9E9FAF',
	};

	const getButtonTemplate = ({
		link,
		label,
		color,
		fontStyle,
		fontWeight,
		textDecoration,
		fontSize: size,
		backgroundColor,
		fontFamily: family,
	}: IButtonTemplate) => `
		<button 
			style="
				border-style:none;
				background:transparent;
			"
		>
			<a
				href="${link}"
				style="
					cursor: pointer;
					color: ${color};
					min-width: 150px;
					min-height: 20px;
					padding: 10px 20px;
					text-align: center;
					border-radius: 5px;
					font-size: ${size};
					display: inline-block;
					font-family: ${family};
					font-style: ${fontStyle};
					font-weight: ${fontWeight};
					text-decoration: ${textDecoration};
					background-color: ${backgroundColor};
				"
			>
				${label}
			</a>
		</button>
	`;

	const handleCreateButton = (values: IAddButtonValues) => {
		const {
			bold,
			italic,
			underline,
			fontSize: size,
			fontFamily: family,
			...rest
		} = values;

		const editor = editorRef?.current;

		if (!editor) return;

		const htmlTemplateParams: IButtonTemplate = {
			...rest,
			fontSize: size ? size : '16px',
			fontWeight: bold ? 'bold' : 'normal',
			fontStyle: italic ? 'italic' : 'normal',
			textDecoration: underline ? 'underline' : 'none',
			fontFamily: family ? family : 'Arial, Helvetica, sans-serif',
		};

		const htmlTemplate = getButtonTemplate(htmlTemplateParams);
		const currentEditorValue = editor.editor?.data.get();

		editor.editor?.data.set(`${currentEditorValue || ''} ${htmlTemplate}`);
	};
	const [activeIndex, setActiveIndex] = useState(0);

	const handleToggleActive = (index: number) => setActiveIndex(index);

	const handleFocus = () => {
		if (!clearError) return;

		clearError();
	};

	const handleSaveClick = () => {
		if (handleSave) {
			handleSave();
		}

		setTimeout(() => {
			saveButtonRef.current?.blur();
		}, 1000);
	};

	return (
		<section className="org-section-price">
			<div className="org-section-menu">
				<span className="org-section-menu-header">Preview on</span>
				<ul className="org-section-list">
					{textEditorViewTabs.map((device, index) => (
						<li
							key={index}
							className={cn('org-section-item', {
								'is-active': activeIndex === index,
							})}
							onClick={() => handleToggleActive(index)}
						>
							{device}
						</li>
					))}
				</ul>
			</div>
			{errorMessage && <span className="org-editor-error">{errorMessage}</span>}
			<div
				className={`org-editor-container ${
					activeIndex === 1 ? 'tablet' : activeIndex === 2 ? 'mobile' : ''
				}`}
			>
				<CKEditor
					ref={editorRef}
					editor={Editor}
					disabled={disabled}
					onFocus={handleFocus}
					data={initialContent}
					config={ckeditorConfig}
				/>
				<img
					src={
						activeIndex === 0
							? disabled
								? DesktopDisabled
								: Desktop
							: activeIndex === 1
							? disabled
								? TabletDisabled
								: Tablet
							: activeIndex === 2
							? disabled
								? MobileDisabled
								: Mobile
							: ''
					}
					alt="editor"
					className="org-editor"
				/>
				<div className="org-editor-sidebar">
					<Formik initialValues={initialValues} onSubmit={handleCreateButton}>
						{() => <AddButton />}
					</Formik>
					<div className="org-create-btn">
						<span className="org-create-btn-title">
							Get Image
							<Button className="btn-secondary" value="Get Image" />
						</span>
					</div>
				</div>
			</div>
			<div className="org-btn-container">
				<Button
					ref={saveButtonRef}
					value="Save"
					handleClick={handleSaveClick}
					className="btn-primary org-modal-btn"
				/>
			</div>
		</section>
	);
};
