import { Dispatch } from '@reduxjs/toolkit';

import AccountService from 'api/services/AccountService';

import {
	setUsers,
	deleteUser,
	setCurrentUser,
	setIsUsersPending,
} from './accountsSlice';

export const getUsersAsync = () => async (dispatch: Dispatch) => {
	dispatch(setIsUsersPending(true));

	try {
		const data = await AccountService.getUsers();

		dispatch(setUsers(data));
	} catch (error) {
		console.log(error);
	} finally {
		dispatch(setIsUsersPending(false));
	}
};

export const getCurrentUserAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await AccountService.getCurrentUser();

		dispatch(setCurrentUser(data));
	} catch (error) {
		console.log(error);
	}
};

export const deleteUserAsync = (id: string) => async (dispatch: Dispatch) => {
	try {
		dispatch(deleteUser(id));

		await AccountService.deleteUser(id);
	} catch (error) {
		console.log(error);
	}
};
