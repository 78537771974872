import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import Zendesk from 'react-zendesk';
import { FC } from 'react';

import { AxiosInterceptors } from 'AxiosInterceptors';
import { loginRequest } from 'config/authConfig';
import { Router } from 'router';

export const App: FC = () => {
	useMsalAuthentication(InteractionType.Redirect, loginRequest);

	const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;

	return (
		<div className="app">
			<BrowserRouter>
				<AxiosInterceptors>
					<Router />
				</AxiosInterceptors>
			</BrowserRouter>
			{zendeskKey && <Zendesk defer zendeskKey={zendeskKey} />}
		</div>
	);
};
