import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useModalControls } from 'hooks/useModalControls';
import { useToggleSection } from 'hooks/useToggleSection';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { getBankACHInfoAsync } from 'store/studioBilling';

import { BankInformationList } from './components/BankInformationList';
import { UpdateBankAccount } from './components/UpdateBankAccount';

export const BankInformation = () => {
	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const {
		modalRef: updateBankAccModalRef,
		showModal: showUpdateBankAccModal,
		hideModal: hideUpdateBankAccModal,
	} = useModalControls();

	useEffect(() => {
		void dispatch(getBankACHInfoAsync());
	}, []);

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="Bank ACH Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
			>
				<div className="accing-wrapper">
					<span className="accing-title">
						This is the account we will payout your profits to.
					</span>
					<div className="accing-list-wrapper">
						<BankInformationList />
						<Button
							className="btn-secondary accing-btn"
							value="Update Bank Account"
							handleClick={showUpdateBankAccModal}
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				ref={updateBankAccModalRef}
				title="Update Bank Account ACH Details"
				subtitle="This account will be used for all profit payouts for this studio."
			>
				<UpdateBankAccount hideUpdateBankAccModal={hideUpdateBankAccModal} />
			</Modal>
		</div>
	);
};
