import { IChangeEmailBody } from 'api/models/requests/account/changeEmailBody';
import { IInviteUserBody } from 'api/models/requests/account/inviteUserBody';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { IUser } from 'api/models/responses/account/user';

import { accountUrls } from 'constants/apiUrls/accountUrls';
import { api } from 'config/api';

class AccountService {
	async getUsers(): Promise<IUser[]> {
		const { data } = await api.get<IUser[]>(accountUrls.getUsers);

		return data;
	}

	async getCurrentUser(): Promise<IUser> {
		const { data } = await api.get<IUser>(accountUrls.getCurrentUser);

		return data;
	}

	async inviteUser(body: IInviteUserBody): Promise<IUser> {
		const { data } = await api.put<IUser>(accountUrls.inviteUser, body);

		return data;
	}

	async updateUser(userId: string, body: IPatchBody[]): Promise<IUser> {
		const { data } = await api.patch<IUser>(
			accountUrls.accUserUrl(userId),
			body
		);

		return data;
	}

	async uploadUserPhoto(imageFile: File): Promise<string> {
		const formData = new FormData();

		formData.append('imageFile', imageFile);

		const { data } = await api.post<string>(
			accountUrls.uploadUserPhoto,
			formData
		);

		return data;
	}

	async deleteUserPhoto(): Promise<void> {
		await api.delete(accountUrls.deleteUserPhoto);
	}

	async deleteUser(userId: string): Promise<void> {
		await api.delete(accountUrls.accUserUrl(userId));
	}

	async sendVerificationCode(email: string): Promise<void> {
		await api.put(accountUrls.sendVerificationCode, { email });
	}

	async changeEmail(body: IChangeEmailBody): Promise<void> {
		await api.put(accountUrls.changeEmail, body);
	}
}

export default new AccountService();
