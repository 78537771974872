import { FC, useEffect, useState } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useModalControls } from 'hooks/useModalControls';

import { ToggleSection } from 'components/ToggleSection';
import { Modal } from 'components/Modal';

import { getCurrentUserAsync } from 'store/accounts';

import { SendVerificationCode } from './components/SendVerificationCode';
import { ChangeEmail } from './components/ChangeEmail';
import { UserPhoto } from './components/UserPhoto';
import { UserInfo } from './components/UserInfo';

export const UserSettings: FC = () => {
	const [confirmedEmail, setConfirmedEmail] = useState('');

	const { isOpen, handleToggle } = useToggleSection(true);

	const dispatch = useAppDispatch();

	const {
		modalRef: sendVerificationCodeModalRef,
		showModal: showSendVerificationCodeModal,
		hideModal: hideSendVerificationCodeModal,
	} = useModalControls();

	const {
		modalRef: changeEmailModalRef,
		showModal: showChangeEmailModal,
		hideModal: hideChangeEmailModal,
	} = useModalControls();

	useEffect(() => {
		void dispatch(getCurrentUserAsync());
	}, []);

	return (
		<ToggleSection
			isOpen={isOpen}
			title="User Settings"
			handleToggle={handleToggle}
			className="acc-user-header"
		>
			<div className="acc-settings acc-settings-image">
				<UserPhoto />
				<UserInfo
					showSendVerificationCodeModal={showSendVerificationCodeModal}
				/>
			</div>
			<Modal
				title="Update Email Address"
				ref={sendVerificationCodeModalRef}
				subtitle="Will use this email for future logins and communication."
			>
				<SendVerificationCode
					setConfirmedEmail={setConfirmedEmail}
					showChangeEmailModal={showChangeEmailModal}
					hideSendVerificationCodeModal={hideSendVerificationCodeModal}
				/>
			</Modal>
			<Modal ref={changeEmailModalRef} title="Update Email Address">
				<ChangeEmail
					confirmedEmail={confirmedEmail}
					hideChangeEmailModal={hideChangeEmailModal}
				/>
			</Modal>
		</ToggleSection>
	);
};
