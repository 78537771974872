import { projectTypeSubjects } from 'constants/projects/projectTypeSubjects';
import { ProjectTypes } from 'api/models/requests/projects/projectTypes';

interface ProjectTypeSubjectsResult {
	subject: string;
	subjects: string;
}

export const useProjectTypeSubjects = (
	projectType: ProjectTypes | string
): ProjectTypeSubjectsResult => {
	const projectTypeSubject = projectTypeSubjects.find(({ projectTypes }) =>
		projectTypes.some((projectTypeParam) => projectType === projectTypeParam)
	);

	return {
		subject: projectTypeSubject?.value.subject ?? '',
		subjects: projectTypeSubject?.value.subjects ?? '',
	};
};
