import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { IChangeEmailProps, IChangeEmailValues } from '..';

export const ChangeEmailForm: FC<IChangeEmailProps> = ({
	confirmedEmail,
	hideChangeEmailModal,
}) => {
	const { values, errors, touched, handleChange, handleSubmit } =
		useFormikContext<IChangeEmailValues>();

	return (
		<div className="modal-body accing-modal">
			{/* TODO: add styles for modal title */}
			<h2>We sent a verification code to your email.</h2>
			<div className="acc-email-modal">
				<InputGrid
					isLazy
					disabled
					readOnly
					label="Email"
					required={false}
					handleChange={handleChange}
					defaultValue={confirmedEmail}
				/>
				<InputGrid
					required={false}
					placeholder="Code"
					id="verificationCode"
					label="Verification Code"
					handleChange={handleChange}
					error={errors.verificationCode}
					value={values.verificationCode}
					touched={touched.verificationCode}
				/>
			</div>
			<div className="btn-container-decrease">
				<Button
					className="btn-primary"
					handleClick={handleSubmit}
					value="Change Email Address"
				/>
				<Button
					value="Cancel"
					className="btn-secondary"
					handleClick={hideChangeEmailModal}
				/>
			</div>
		</div>
	);
};
