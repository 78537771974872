import { IBasePaginationReqParams } from 'api/models/requests/general/basePaginationReqParams';
import { PaginatedOrdersWaiting } from 'api/models/responses/orders/orderWaiting';
import { PaginatedOrders } from 'api/models/responses/orders/order';

import { ordersUrls } from 'constants/apiUrls/ordersUrls';
import { api } from 'config/api';

class OrdersService {
	async getLatestOrders(): Promise<PaginatedOrders> {
		const { data } = await api.get<PaginatedOrders>(ordersUrls.newOrders);

		return data;
	}

	async getOrdersWaiting(
		reqParams: IBasePaginationReqParams
	): Promise<PaginatedOrdersWaiting> {
		const { data } = await api.get<PaginatedOrdersWaiting>(
			ordersUrls.ordersWaiting,
			{
				params: reqParams,
			}
		);

		return data;
	}
}

export default new OrdersService();
