import { DragEvent, FC, SyntheticEvent } from 'react';
import { SingleValue } from 'react-select';
import cn from 'classnames';

import { disabledDragRowsSequence } from 'constants/projects/subjectFormQuestions/disabledDragRowsSequence';
import { dataFieldTypeSelectOptions } from 'constants/general/selectOptions/dataFieldTypeSelectOptions';

import { PriceDeleteBtn } from 'components/Controls/PriceDeleteBtn';
import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Checkbox } from 'components/FormControls/Checkbox';

import { FormQuestionDataFieldType } from 'api/models/responses/general/generalFormQuestion';
import {
	validateOptions,
	validateFormQuestion,
	validateDataFieldType,
} from 'utils/validations/general/formQuestionsValidation';
import { useDragAndDrop } from 'hooks/useDragAndDrop';
import { ISelectOption } from 'types/ui/select';

import {
	ISubjectFormQuestionUI,
	ISubjectFormQuestionsControls,
} from '../types';

interface ISubjectFormRowProps
	extends ISubjectFormQuestionUI,
		ISubjectFormQuestionsControls {
	disabled?: boolean;
	hideBurger?: boolean;
	disabledFieldId?: string;
}

export const SubjectFormRow: FC<ISubjectFormRowProps> = ({
	errors,
	options,
	sequence,
	localKey,
	disabled,
	hideBurger,
	clearError,
	formQuestion,
	dataFieldType,
	deleteFormRow,
	handleUpdate,
	changeSequence,
	disabledFieldId,
	isAnswerRequired,
	subjectFormQuestionKey,
}) => {
	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleDragStart,
		handleMouseDown,
	} = useDragAndDrop(disabledDragRowsSequence, changeSequence);

	const onDragStart = (e: DragEvent<HTMLDivElement>) => {
		handleDragStart(e, localKey);
	};

	const handleDelete = () => {
		deleteFormRow(localKey, subjectFormQuestionKey);
	};

	const handleQuestionChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const validationMessage = validateFormQuestion(value);

		handleUpdate({
			value,
			localKey,
			validationMessage,
			subjectFormQuestionKey,
			fieldKey: 'formQuestion',
		});
	};

	const handleDataFieldChange = (
		option: SingleValue<ISelectOption<FormQuestionDataFieldType>>
	) => {
		if (!option) return;

		const value = option.value;

		const validationMessage = validateDataFieldType(value);

		handleUpdate({
			value,
			localKey,
			validationMessage,
			subjectFormQuestionKey,
			fieldKey: 'dataFieldType',
		});
	};

	const handleOptionsChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const validationMessage = validateOptions(value);

		handleUpdate({
			value,
			localKey,
			validationMessage,
			fieldKey: 'options',
			subjectFormQuestionKey,
		});
	};

	const handleAnswerRequiredChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;

		handleUpdate({
			localKey,
			value: checked,
			subjectFormQuestionKey,
			fieldKey: 'isAnswerRequired',
		});
	};

	const clearQuestionError = () => {
		if (!errors.formQuestion) return;

		clearError({
			localKey,
			fieldKey: 'formQuestion',
		});
	};

	const clearOptionsError = () => {
		if (!errors.options) return;

		clearError({
			localKey,
			fieldKey: 'options',
		});
	};

	const disabledRow = !sequence || sequence === 1 || disabled;

	const isAnswerRequiredFieldId = `${localKey}-isAnswerRequired`;
	const dataFieldTypeFieldId = `${localKey}-dataFieldType`;
	const formQuestionFieldId = `${localKey}-question`;
	const optionsFieldId = `${localKey}-options`;

	return (
		<div
			id={localKey}
			onDrop={handleDrop}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			className="project-form-container"
			draggable={draggable && !disabledRow}
		>
			<button className="project-form-burger" onMouseDown={handleMouseDown}>
				<i
					className={cn('price-icon-table icon-hamburger', {
						'is-disabled': disabledRow || hideBurger,
					})}
				/>
			</button>
			<InputGrid
				isLazy
				touched
				required={false}
				disabled={disabledRow}
				id={formQuestionFieldId}
				placeholder="Question*"
				error={errors.formQuestion}
				defaultValue={formQuestion}
				clearError={clearQuestionError}
				handleLazyChange={handleQuestionChange}
			/>
			<div>
				<SelectComponent
					touched
					value={dataFieldType}
					id={dataFieldTypeFieldId}
					error={errors.dataFieldType}
					selectPlaceholder="Data Field*"
					onChange={handleDataFieldChange}
					selectOptions={dataFieldTypeSelectOptions}
					disabled={disabledRow || disabledFieldId === dataFieldTypeFieldId}
				/>
			</div>
			<InputGrid
				isLazy
				touched
				required={false}
				id={optionsFieldId}
				disabled={disabledRow}
				error={errors.options}
				defaultValue={options || ''}
				clearError={clearOptionsError}
				handleLazyChange={handleOptionsChange}
				placeholder={
					disabledRow
						? ''
						: 'Options (Separated by comma) or leave blank for typed response'
				}
			/>
			<Checkbox
				label="Response Required"
				checked={isAnswerRequired}
				id={isAnswerRequiredFieldId}
				handleChange={handleAnswerRequiredChange}
				disabled={disabledRow || disabledFieldId === isAnswerRequiredFieldId}
			/>
			{!disabledRow && <PriceDeleteBtn handleClick={handleDelete} />}
		</div>
	);
};
