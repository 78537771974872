import { FC, useCallback, useEffect } from 'react';
import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { utc } from 'moment';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { Table } from 'components/Table';
import { Card } from 'components/Card';

import { IOrder } from 'api/models/responses/orders/order';
import OrdersService from 'api/services/OrdersService';

import { setLatestOrders, latestOrdersSelector } from 'store/dashboard';
import { normalizeDigit } from 'utils/ui/normalizeDigit';
import { salesUI } from 'constants/projects/salesUI';

export const NewOrders: FC = () => {
	const latestOrders = useAppSelector(latestOrdersSelector);

	const dispatch = useAppDispatch();

	const getLatestOrders = useCallback(async () => {
		try {
			const data = await OrdersService.getLatestOrders();

			dispatch(setLatestOrders(data));
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		void getLatestOrders();
	}, [getLatestOrders]);

	const columns: Column<IOrder>[] = [
		{
			Header: 'Date',
			accessor: 'orderDate',
			Cell: ({ value }: CellProps<IOrder, IOrder['orderDate']>) => (
				<>{utc(value).format('DD/MM')}</>
			),
		},
		{
			Header: 'Project',
			accessor: 'projectName',
		},
		{
			Header: 'Sale',
			accessor: 'saleType',
			Cell: ({ value }: CellProps<IOrder, IOrder['saleType']>) => {
				// TODO: remove after check for saleUI exist after back-end fix
				const { title, shortTitle } = salesUI[value] || {};

				return <>{shortTitle ?? title}</>;
			},
		},
		{
			Header: 'Customer',
			accessor: 'customerName',
		},
		{
			Header: 'Order #',
			accessor: 'orderKey',
			Cell: ({ value }: CellProps<IOrder, IOrder['orderKey']>) => (
				// TODO: add correct route to order
				<Link
					to={`/${value}`}
					title={value.toString()}
					className="card-table-link"
				>
					#{value}
				</Link>
			),
		},
		{
			Header: 'Revenue',
			accessor: 'wholesaleTotal',
			Cell: ({ value }: CellProps<IOrder, IOrder['wholesaleTotal']>) => (
				<>
					{normalizeDigit({ value, isPrice: true, minimumFractionDigits: 2 })}
				</>
			),
		},
	];

	return (
		<Card
			columnFit="col-2"
			title="New Orders"
			bodyClassName="card-table"
			subtitle="(10 most recent orders) "
		>
			<Table columns={columns} data={latestOrders.results} />
		</Card>
	);
};
