import { FC } from 'react';

interface IPricePriceDeleteBtnProps {
	handleClick?: () => void;
}

export const PriceDeleteBtn: FC<IPricePriceDeleteBtnProps> = ({
	handleClick,
}) => (
	<button className="price-btn-delete" onClick={handleClick}>
		<i className="price-icon-table icon-delete" />
	</button>
);
