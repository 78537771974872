import { FormikHandlers } from 'formik';
import cn from 'classnames';
import { FC } from 'react';

interface TableInputProps {
	id: string;
	type?: string;
	error?: string;
	value: string;
	label?: string;
	touched?: boolean;
	required?: boolean;
	disabled?: boolean;
	className?: string;
	handleChange: FormikHandlers['handleChange'];
}

export const TableInput: FC<TableInputProps> = ({
	id,
	type,
	label,
	error,
	value,
	touched,
	required,
	disabled,
	className,
	handleChange,
}) => {
	const showError = error && touched;

	return (
		<div
			className={cn(className, {
				'acc-error': showError,
			})}
		>
			<label htmlFor={id} className="acc-label">
				{label}
				<input
					id={id}
					type={type}
					value={value}
					disabled={disabled}
					className="acc-input"
					onChange={handleChange}
				/>
			</label>
			{required && <span className="acc-form-required">*</span>}
			{showError && <span className="acc-error-message">{error}</span>}
		</div>
	);
};

TableInput.defaultProps = {
	type: 'text',
	disabled: false,
	className: 'acc-form-container',
};
