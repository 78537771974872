import { FC } from 'react';

import { PriceDeleteBtn } from 'components/Controls/PriceDeleteBtn';

interface IControlsColumnProps {
	isDefault?: boolean;
	showPriceDeleteBtn?: boolean;
	handleDelete?: () => void;
}

export const DeleteControlColumn: FC<IControlsColumnProps> = ({
	isDefault,
	handleDelete,
	showPriceDeleteBtn,
}) => {
	if (isDefault) {
		return (
			<td className="price-option-td">
				<span className="price-btn-default">Default</span>
			</td>
		);
	}

	return (
		<td className="price-option-td">
			{showPriceDeleteBtn && <PriceDeleteBtn handleClick={handleDelete} />}
		</td>
	);
};

DeleteControlColumn.defaultProps = {
	showPriceDeleteBtn: true,
};
