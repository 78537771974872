import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	priceListNameRegEx,
	priceListNameMaxLength,
	priceListNameMinLength,
	priceListNameErrorMessage,
	priceListNameMinLengthError,
	priceListNameMaxLengthError,
} from 'constants/priceLists/validation/priceListNameRegEx';

export const validateOptionName = (name: string): string => {
	if (!name) {
		return requiredFieldMessage;
	}

	if (!priceListNameRegEx.test(name)) {
		return priceListNameErrorMessage;
	}

	if (name.length < priceListNameMinLength) {
		return priceListNameMinLengthError;
	}

	if (name.length > priceListNameMaxLength) {
		return priceListNameMaxLengthError;
	}

	return '';
};
